import { RowData, RowParserFn } from '../../../../model/list/cache/RowParser';

const SPECIAL_COLS = [ 'inner_id', 'row_style', 'owner_id', 'show_as' ]

export function adjustableColumnsRowParser(columns: string[], baseColumns: string[]): RowParserFn {
    const idColumn = baseColumns.findIndex(c => c === 'inner_id');
    const showAsColumn = baseColumns.findIndex(c => c === 'show_as');
    const rowStyle = baseColumns.findIndex(c => c === 'row_style');
    const ownerIdColumn = baseColumns.findIndex(c => c === 'owner_id');
    const colCount = baseColumns.filter(c => !SPECIAL_COLS.includes(c)).length;
    const backupShowAs = baseColumns.reduce((id, col, i) => {
        if(id >= 0 || SPECIAL_COLS.includes(col))
            return id;
        return i;
    }, -1);
    let showAs = -1;
    if(showAsColumn > -1)
        showAs = showAsColumn;
    else if(backupShowAs > -1)
        showAs = backupShowAs;

    const columnIndexes = columns.map(c => baseColumns.indexOf(c));

    return (row: string[]): RowData => {
        return ({
            columns: row.filter((r, i) => columnIndexes.indexOf(i) > -1),
            id: idColumn > -1 ? Number(row[idColumn]) : undefined,
            showAs: showAs > -1 ? row[showAs] : undefined,
            rowStyle: rowStyle > -1 ? row[rowStyle] : '',
            ownerId: ownerIdColumn > -1 ? Number(row[ownerIdColumn]) : undefined,
            subCount: row.length > columns.length ? Number(row[row.length - 1]) : undefined
        });
    }
}
