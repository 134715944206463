import React, { PropsWithChildren, ReactNode, useState } from 'react';
import PopupBox from './PopupBox';

type Props = {
    title: String;
    onClose?: (answer?: boolean) => void;
}

export default function QuestionBox({ title, onClose, children }: PropsWithChildren<Props>) {
    const emitClose = (answer?: boolean) => {
        if(onClose)
            onClose(answer);
    }

    return (
    <PopupBox
            title={<span>{title}</span>}
            buttons={[
                (onClickCreator) => (<div key={1} className="button" onClick={onClickCreator('Yes')}>Yes</div>),
                (onClickCreator) => (<div key={2} className="button" onClick={onClickCreator('No')}>No</div>)
            ]}
            onClose={(s?: string) => {
                if(s === 'Yes')
                    emitClose(true);
                else if(s === 'No')
                    emitClose(false);
                else
                    emitClose();
            }} >
        {children}
    </PopupBox>);
}

export type Question = {
    content: ReactNode,
    onYes: () => void,
    onNo?: () => void
}
export function useQuestionBox(title: string) {
    const [question, setQuestion] = useState<Question | null>(null);

    const QuestionControl = question &&
        <QuestionBox title={title} onClose={answer => {
            if(answer)
                question.onYes();
            else if(question.onNo)
                question.onNo();
            setQuestion(null);
        }}>{question.content}</QuestionBox>;

    return {
        askQuestion: (question: Question) => setQuestion(question),
        QuestionControl
    }
}
