import React, { ChangeEvent, useEffect, useState } from 'react';
import { tactin } from '../../../utils/TactinGlobals';

import './ChooseValuesPopup.css';

type ChooseValuesPopupProps = {
    idPrefix?: string;
    baseValues: string[];
    selectedValues: string[];
    defaultListSize?: number;
    description?: string;
    onValuesChanged: (columnNames: string[]) => void;
    cancel: () => void;
};

export default function ChooseValuesPopup(props: ChooseValuesPopupProps) {
    const [currentValues, setCurrentValues] = useState<string[]>([]);

    const updateSelectedValues = (selectElement: HTMLSelectElement) => {
        const options = selectElement.options;

        let valuesToSelect: string[] = [];

        for (const option of options) {
            if (option.selected) {
                valuesToSelect.push(option.value);
            }
        }

        setCurrentValues(valuesToSelect);
    }

    useEffect(() => {
        return setCurrentValues(props.selectedValues)
    }, [props.selectedValues]);


    return <div className='cngr-column-choose-popup'>
        <label htmlFor={`choose${props.idPrefix}Box`}>{props.description}</label>
        <select id={`choose${props.idPrefix}Box`} name={`choose${props.idPrefix}Box[]`} size={props.defaultListSize ?? 15} value={currentValues}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => updateSelectedValues(event.target)} multiple>
            {props.baseValues.map(name =>
                <option key={name} value={name}>{name.replaceAll("\"", "")}</option>)}
        </select>
        <div className='popup-buttons'>
            <button className='button-confirm' onClick={_ => props.onValuesChanged(currentValues)}>{tactin().configuration.translate("Save")}</button>
            <button className='button-cancel' onClick={_ => props.cancel()}>{tactin().configuration.translate("Cancel")}</button>
        </div>
    </div>
};