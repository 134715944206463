import React, { useEffect, useRef, useState } from 'react';
import { useSortingHandler } from '../../../components/list/ListTable';
import { getRowCount, ListProperties } from '../../../model/list/HelperTypes';
import { api } from '../../../utils/api/ApiProvider';
import { ColumnFilter, ComboColumnFilter, FilterManager } from '../../../model/list/ColumnFilter';
import { BasicDataTable, InstrumentSelection } from '../ui/BasicDataTable';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { RowData } from '../../../model/list/cache/RowParser';
import { BasicDataCache } from '../ui/cache/BasicDataCache';
import { tactin } from '../../../utils/TactinGlobals';
import ChooseValuesPopup from '../ui/ChooseValuesPopup';
import { adjustableColumnsRowParser } from '../ui/cache/AdjustableColumnsRowParser';

type InstrumentsListProps = {
    filters: ColumnFilter[] | FilterManager;
    dataProvider: string;
    wildcards: string[];
    onSelect?: (id: number, showAs: string) => void;
    allowCheckRow: boolean;
    currentSelections: InstrumentSelection[];
    setItems: (selection: InstrumentSelection[]) => void;
    updateRowCount: (count: number) => void;
    allowRefreshFilters: boolean;
    getRowCheckedState: (id: number | undefined) => boolean;
    setRowCheckedState: (id: number, isChecked: boolean) => void;
    userSelectedColumnNames: string[];
    analysisType: number;
    onSelectedColumnsChanged: (columns: string[]) => void;
    onSortingChanged: (sorting: { name: string, direction: 'ASC' | 'DESC' }) => void;
    showArticle?: (name: string) => void;
}

export function InstrumentsList(props: InstrumentsListProps) {
    const [listProperties, setListProperties] = useState<ListProperties | null>(null);
    const [baseListProperties, setBaseListProperties] = useState<ListProperties | null>(null);
    const [dataProvider, setDataProvider] = useState<string>(props.dataProvider);
    const { sorting, sortHandler } = useSortingHandler();

    const segmentSize = useListSegmentSize();

    const [filters, _setFilters] = useState<ColumnFilter[]>([]);

    const [selectedColumns, setSelectedColumns] = useState<string[]>(props.userSelectedColumnNames);
    const [baseColumns, setBaseColumns] = useState<string[]>([]);
    const [baseColumnWidths, setBaseColumnWidths] = useState<string[]>([]);
    const [columnChoosePopupVisible, setColumnChoosePopupVisible] = useState<boolean>(false);
    const [displayColumnsChooser, setDisplayColumnsChooser] = useState<boolean>(props.allowCheckRow);

    let cache: BasicDataCache;

    const setFilters = (fs: ColumnFilter[]) => {
        _setFilters(fs);
    }

    useEffect(() => {
        if (props.filters instanceof Array) {
            setFilters([...props.filters]);
            return () => { };
        } else {
            const filterManager = props.filters;
            // FIXME: Handle this differently...
            if (!filters.length && props.dataProvider === "31")
                getAvailableFilters(filterManager);
            return filterManager.addFilterHandler(setFilters);
        }
    }, [props.dataProvider, props.filters]);

    const getAvailableFilters = (fm: FilterManager) => {
        api().List.getFiltersForDataProvider(props.dataProvider, false).then(v => {
            if (props.analysisType === 30) {
                const interestTypeFilter = (v.find(f => f.columnAlias === 'Rodzaj oprocentowania') as ComboColumnFilter);

                if (interestTypeFilter) {
                    interestTypeFilter.selected = 2;
                }
            }

            fm.update([...v])
        }).catch(HandleErrors());
    }

    useEffect(() => {
        if (props.allowRefreshFilters && filters && filters.length > 0) {
            getListProperties();
        }
    }, [props.dataProvider, props.wildcards, filters, props.allowRefreshFilters]);

    useEffect(() => {
        getListProperties();
    }, [sorting.name, sorting.direction]);

    useEffect(() => {
        setDisplayColumnsChooser(props.allowCheckRow);
    }, [props.allowCheckRow]);

    const getListProperties = () => {
        // FIXME: Handle this differently...
        const filtersToApply = props.dataProvider === "40" ? [] : filters;

        api().List.getProperties({
            dataProviderId: props.dataProvider,
            wildcards: props.wildcards,
            filters: filtersToApply,
            grouping: [],
            skipColumns: false
        })
            .then(r => {
                setBaseListProperties(r);
                setBaseColumns([...r.columnNames]);
                setBaseColumnWidths([...r.columnWidths]);

                if (r && r.columnAggregation && r.columnAggregation.length > 0) {
                    props.updateRowCount(parseInt(r.columnAggregation[r.columnAggregation.length - 1]));
                }
            })
            .catch(HandleErrors());
    }

    useEffect(() => {
        if (baseColumns.length && baseColumnWidths.length && baseListProperties) {
            if (props.userSelectedColumnNames && props.userSelectedColumnNames.length > 0) {
                const oldListProperties = JSON.parse(JSON.stringify(baseListProperties));

                if (oldListProperties) {
                    oldListProperties.columnNames = modifyColumns(oldListProperties.columnNames);
                    oldListProperties.columnWidths = adjustColumnWidths(oldListProperties.columnNames, oldListProperties.columnWidths);

                    setListProperties(oldListProperties);
                    setSelectedColumns(oldListProperties.columnNames);
                }
            }
        }
    }, [baseColumns, baseColumnWidths, baseListProperties])

    const modifyColumns = (columnNames: string[]) => {
        columnNames = columnNames.filter(c => props.userSelectedColumnNames.includes(c));
        columnNames.push("inner_id");

        return columnNames;
    }

    const adjustColumnWidths = (columnNames: string[], defaultWidths: string[]) => {
        if (!baseColumns.length)
            return defaultWidths;

        let newColumnWidths = [];

        for (let i = 0; i < baseColumns.length; i++) {
            if (baseColumns.includes(columnNames[i])) {
                const oldColumnIndex = baseColumns.indexOf(columnNames[i]);
                const newWidth = baseColumnWidths[oldColumnIndex];

                newColumnWidths.push(newWidth ? newWidth : '0px');
            }
        }

        return newColumnWidths;
    }

    const refreshData = (data?: RowData[] | null) => {
        if (data && data.length > 0 && data[0].columns.length > 0) {
            const ids = data.map(r => r.id);
            const selectionIds = props.currentSelections.map(s => s.id);

            const areSelectionsEqual = selectionIds.length == ids.length &&
                selectionIds.slice().sort().every(function (value, index) {
                    return value === ids.slice().sort()[index];
                });

            if (!areSelectionsEqual) {
                const actualItems = data.filter(d => d.id);

                if (actualItems && actualItems.length > 0) {
                    const selections: InstrumentSelection[] = [];

                    for (const item of actualItems) {
                        if (item.id) {
                            let checked: boolean;

                            if (item.isRowChecked == null) {
                                checked = true;
                            } else {
                                checked = item.isRowChecked;
                            }

                            selections.push({ id: item.id, isChecked: checked })
                        }
                    }

                    props.setItems(selections);
                }
            }
        } else if (data === null) {
            props.setItems([]);
        }
    }

    const cacheCreate = (listProperties: ListProperties) => {
        // FIXME: Handle this differently...
        const filtersToApply = props.dataProvider === "40" ? [] : filters;

        cache = new BasicDataCache(0, filtersToApply, segmentSize, adjustableColumnsRowParser(
            selectedColumns ? selectedColumns : listProperties.columnNames, baseColumns));
        cache.dataProvider = props.dataProvider;
        cache.wildcards = props.wildcards;
        cache.setSort(sorting.name, sorting.direction);
        cache.setRowCount(getRowCount(listProperties));

        return cache;
    }

    const toggleColumnChoosePopup = () => {
        setColumnChoosePopupVisible(!columnChoosePopupVisible);
    }

    const changeColumns = (columnNames: string[]) => {
        if (columnNames && columnNames.length > 0) {
            setSelectedColumns(columnNames);

            props.onSelectedColumnsChanged(columnNames);

            const propertiesColumnNames = listProperties?.columnNames;
            if (listProperties && propertiesColumnNames && propertiesColumnNames.length > 0) {
                const newColumnNames = [];
                const newColumnWidths = [];

                for (let i = 0; i < baseColumns.length; i++) {
                    if (baseColumns.includes(columnNames[i])) {
                        const oldColumnIndex = baseColumns.indexOf(columnNames[i]);

                        newColumnNames.push(columnNames[i]);
                        newColumnWidths.push(baseColumnWidths[oldColumnIndex]);
                    }
                }

                listProperties.columnNames = newColumnNames;
                listProperties.columnNames.push("inner_id");
                listProperties.columnWidths = newColumnWidths;

                setListProperties({ ...listProperties });

                toggleColumnChoosePopup();
            }
        }
    }

    if (listProperties) {
        return <div className='cngr-max-height'>
            <section className='cngr-datatable-toolbar'>
                {displayColumnsChooser && <div className='toolbar-button' onClick={toggleColumnChoosePopup}>
                    <span className="label">{tactin().configuration.translate('Show/hide columns chooser')}</span>
                </div>}
            </section>
            <div className='cngr-max-height'>
                <section className='cngr-datatable'>
                    <BasicDataTable listProperties={listProperties}
                        cacheCreator={cacheCreate}
                        sortable={sortHandler}
                        onSelect={props.onSelect}
                        allowCheckRow={props.allowCheckRow}
                        refreshData={refreshData}
                        getRowCheckedState={props.getRowCheckedState}
                        onInstrumentCheckedChanged={props.setRowCheckedState}
                        showArticle={props.showArticle} />
                </section>
            </div>
            {columnChoosePopupVisible &&
                <ChooseValuesPopup
                    idPrefix='Columns'
                    baseValues={baseColumns.filter((c: string) => !c.includes("inner_id") && !c.includes("show_as") && !c.includes("row_style"))}
                    selectedValues={props.userSelectedColumnNames}
                    defaultListSize={15}
                    description={tactin().configuration.translate("Choose columns (multiple choice allowed by clicking with CTRL pressed)")}
                    onValuesChanged={changeColumns}
                    cancel={toggleColumnChoosePopup} />}

        </div>
    } else {
        return <div className='empty-item'></div>
    }
}

export function useListSegmentSize() {
    /* const str = tactin().configuration.systemInfo('singlerequestrowcount') || '1';
    return (Number(str) || 1); */

    // Enforce downloading all data
    return 0;
}
