import React, { useEffect, useState } from 'react';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { tactin } from '../../../utils/TactinGlobals';

import './AnalysisDetailsToolbar.css';
import { CloseMessageEvent } from '../../../model/events/TactinEvents';

type Props = {
    analysisClosed: boolean;
    analysisSavingDisabled: boolean;
    analysisClosingDisabled: boolean;
    availableCreditsCount: number;
    canDownload: boolean;
    canCopyAnalysis: boolean;
    filePreparedToDownload: boolean;
    analysisCopyResultReceived: boolean;
    onClick: (action: AnalysisDetailsToolbarOption) => Promise<void>;
}

export type AnalysisDetailsToolbarOption = 'save' | 'saveAndClose' | 'reopen' | 'backToList' | 'resetFilters' | 'download' | 'copy';

export default function AnalysisDetailsToolbar(props: Props) {
    const [enabled, setEnabled] = useState(true);
    const [copyAnalysisEnabled, setCopyAnalysisEnabled] = useState(true);
    const [downloadButtonLabel, setDownloadButtonLabel] = useState('Download analysis');
    const [copyAnalysisButtonLabel, setCopyAnalysisButtonLabel] = useState('Copy analysis');
    const [availableCreditsCount, setAvailableCreditsCount] = useState<number>(props.availableCreditsCount);

    useEffect(() => {
        setAvailableCreditsCount(props.availableCreditsCount);
    }, [props.availableCreditsCount])


    useEffect(() => {
        setEnabled(props.filePreparedToDownload);
        setDownloadButtonLabel(props.filePreparedToDownload ? 'Download analysis' : 'Please wait...');
    }, [props.filePreparedToDownload]);

    useEffect(() => {
        setCopyAnalysisEnabled(props.analysisCopyResultReceived);
        setCopyAnalysisButtonLabel(props.analysisCopyResultReceived ? 'Copy analysis' : 'Please wait...');
    }, [props.analysisCopyResultReceived]);

    useEffect(() => {
        return tactin().eventBus.register((event) => {
            if (event instanceof CloseMessageEvent) {
                setEnabled(true);
            }
        })
    }, []);

    const click = (action: AnalysisDetailsToolbarOption) => {
        if (enabled || copyAnalysisEnabled) {
            setEnabled(false);
            setCopyAnalysisEnabled(false);

            props.onClick(action)
                .catch(HandleErrors());
        }
    }

    return <div className='details-toolbar'>
        {props.analysisClosed
            ? <>
                {!props.canDownload && <div className='available-credits-count'>{tactin().configuration.translate('InsufficientCreditsCount')}</div>}
                <Button label={tactin().configuration.translate('Back to analysis')} action='reopen' onClick={click} />
                <Button label={tactin().configuration.translate(downloadButtonLabel)} action='download' onClick={click} disabled={!enabled || !props.canDownload} />
            </>
            : <>
                <Button label={tactin().configuration.translate('Save analysis')} action='save' onClick={click} disabled={props.analysisSavingDisabled} />
                <Button label={tactin().configuration.translate('Close analysis')} action='saveAndClose' onClick={click} disabled={props.analysisClosingDisabled} />
                <Button label={tactin().configuration.translate('Reset filters')} action='resetFilters' onClick={click} />
            </>
        }
        {props.canCopyAnalysis &&
            <Button label={copyAnalysisButtonLabel} action='copy' onClick={click} disabled={!copyAnalysisEnabled} />
        }
    </div>
}

type ButtonProps = {
    label: string;
    action: AnalysisDetailsToolbarOption;
    disabled?: boolean;
    onClick: (action: AnalysisDetailsToolbarOption) => void;
}

function Button(props: ButtonProps) {
    const getClasses = () => {
        return 'details-toolbar-button' + (props.disabled ? ' disabled' : '');
    }

    return <div className={getClasses()}
        onClick={_ => !props.disabled && props.onClick(props.action)}>
        <span className='label'>{tactin().configuration.translate(props.label)}</span>
    </div>
}
