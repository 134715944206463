import { DateUtils } from '../../utils/DateUtils';

export type FilterType = 'textFilter' | 'dateFilter' | 'decimalFilter' | 'comboFilter' | 'multiselectComboFilter' | 'bitFilter' | 'oneToManyRelationFilter' | 'manyToManyRelationFilter' | 'integerFilter' | 'rangeFilter';

export type ColumnFilter = {
  columnAlias: string;
  showAs: string;
  format: string;
  filterType: FilterType;
  valueColumn: string;
  valueShowAs?: string;
};

export function columnFilter(filterType: FilterType, columnAlias: string, valueColumn: string = columnAlias, showAs: string = columnAlias, format: string = '') {
  return {
    columnAlias,
    showAs,
    format,
    filterType,
    valueColumn,
  };
}

export type BitColumnFilter = ColumnFilter & {
  booleanValue: boolean | null;
};
export function bitColumnFilter(columnAlias: string, valueColumn: string) {
  return {
    booleanValue: null,
    ...columnFilter('bitFilter', columnAlias, valueColumn),
  };
}

export type ComboColumnFilter = ColumnFilter & {
  values: string[];
  selected: number | null;
  includeInResults: boolean | null;
};
export function comboColumnFilter(columnAlias: string, valueColumn: string, values: string[]) {
  return {
    values,
    selected: null,
    ...columnFilter('comboFilter', columnAlias, valueColumn),
  };
}

export type MultiselectComboColumnFilter = ColumnFilter & {
  values: string[];
  selected: number[] | null;
  includeInResults: boolean | null;
};
export function multiselectComboColumnFilter(columnAlias: string, valueColumn: string, values: string[]) {
  return {
    values,
    selected: null,
    ...columnFilter('multiselectComboFilter', columnAlias, valueColumn),
  };
}

type DateColumnFilterModifier = 'before' | 'notBefore' | 'after' | 'notAfter' | 'equals' | 'notEqual';
export type DateColumnFilter = ColumnFilter & {
  dateValue: Date | null;
  dateColumnFilterModifier: DateColumnFilterModifier;
};
export function DateColumnFilter(columnAlias: string, valueColumn: string, showAs: string, format: string, modifier: DateColumnFilterModifier) {
  return {
    dateColumnFilterModifier: modifier,
    ...columnFilter('dateFilter', columnAlias, valueColumn, showAs, format),
  };
}

type NumberColumnFilterModifier = 'greaterThen' | 'lessThan' | 'equalsTo';
export type NumberColumnFilter = ColumnFilter & {
  number: number | null;
  numberColumnFilterModifier: NumberColumnFilterModifier;
};
export function decimalColumnFilter(columnAlias: string, valueColumn: string, format: string, modifier: NumberColumnFilterModifier) {
  return {
    numberColumnFilterModifier: modifier,
    number: null,
    ...columnFilter('decimalFilter', columnAlias, valueColumn, columnAlias, format),
  };
}
export function integerColumnFilter(columnAlias: string, modifier: NumberColumnFilterModifier) {
  return {
    numberColumnFilterModifier: modifier,
    number: null,
    ...columnFilter('integerFilter', columnAlias),
  };
}

export type ManyToManyRelationColumnFilterModifier = 'equals' | 'notEquals' | 'containsAll' | 'containsAny' | 'containsNon';
export type ManyToManyRelationColumnFilter = ColumnFilter & {
  selected: number[];
  config: object;
  manyToManyRelationColumnFilterModifier: ManyToManyRelationColumnFilterModifier;
};
export function manyToManyRelationFilter(columnAlias: string, valueColumn: string, modifier: ManyToManyRelationColumnFilterModifier, configuration: object): ManyToManyRelationColumnFilter {
  return {
    selected: [],
    config: configuration,
    manyToManyRelationColumnFilterModifier: modifier,
    ...columnFilter('manyToManyRelationFilter', columnAlias, valueColumn),
  };
}

export type OneToManyRelationColumnFilterModifier = 'equals' | 'notEquals' | 'in' | 'notIn' | 'isInHierarchy';
export type OneToManyRelationColumnFilter = ColumnFilter & {
  selected: number[];
  config: object;
  oneToManyRelationColumnFilterModifier: OneToManyRelationColumnFilterModifier;
};
export function oneToManyRelationColumnFilter(columnAlias: string, valueColumn: string, modifier: OneToManyRelationColumnFilterModifier, configuration: object): OneToManyRelationColumnFilter {
  return {
    selected: [],
    config: configuration,
    oneToManyRelationColumnFilterModifier: modifier,
    ...columnFilter('oneToManyRelationFilter', columnAlias, valueColumn),
  };
}

type TextColumnFilterModifier = 'startsWith' | 'endsWith' | 'contains';
export type TextColumnFilter = ColumnFilter & {
  text: string;
  textColumnFilterModifier: TextColumnFilterModifier;
  caseSensitive: boolean;
};
export function textColumnFilter(columnAlias: string, modifier: TextColumnFilterModifier = 'contains', caseSensitive: boolean = false): TextColumnFilter {
  return {
    textColumnFilterModifier: modifier,
    text: '',
    caseSensitive,
    ...columnFilter('textFilter', columnAlias),
  };
}

export type FilterRange = {
  min: string;
  max: string;
};
export type RangeColumnFilter = ColumnFilter & {
  columnAlias: string;
  range: FilterRange | null;
  useColumnName: boolean,
};
export function rangeColumnFilter(columnAlias: string): RangeColumnFilter {
  return {
    range: { min: '0.00', max: '0.00' },
    useColumnName: false,
    ...columnFilter('rangeFilter', columnAlias),
  };
}
const isRange = (x: any): x is FilterRange => typeof (x as FilterRange).min === 'string' && typeof (x as FilterRange).min === 'string';

export function setFilterValue(filter: ColumnFilter, value: any, showAs?: string): ColumnFilter {
  switch (filter.filterType) {
    case 'bitFilter': {
      let v: boolean | null = null;
      if (value === null || typeof value === 'boolean') v = value;
      else if (typeof value === 'number') {
        switch (value) {
          case 1:
            v = true;
            break;
          case 2:
            v = false;
            break;
          case 0:
          default:
            v = null;
            break;
        }
      } else v = !!value;
      (filter as BitColumnFilter).booleanValue = v;
      break;
    }
    case 'comboFilter':
      if (value === null || typeof value === 'number') (filter as ComboColumnFilter).selected = value;
      break;
    case 'multiselectComboFilter':
      if (value === null || Array.isArray(value)) (filter as MultiselectComboColumnFilter).selected = value;
      break;
    case 'dateFilter':
      if (value === null || value instanceof Date) (filter as DateColumnFilter).dateValue = value;
      break;
    case 'decimalFilter':
    case 'integerFilter':
      {
        let v: number | null = null;
        if (value === null || typeof value === 'number') v = value;
        else v = Number(value) || null;
        (filter as NumberColumnFilter).number = v;
      }
      break;
    case 'manyToManyRelationFilter':
      if (value === null || (value instanceof Array && value.reduce((p, n) => p && typeof n === 'number', true))) (filter as ManyToManyRelationColumnFilter).selected = value;
      break;
    case 'oneToManyRelationFilter':
      if (value === null || (value instanceof Array && value.reduce((p, n) => p && typeof n === 'number', true))) (filter as OneToManyRelationColumnFilter).selected = value;
      else if (typeof value === 'number') (filter as OneToManyRelationColumnFilter).selected = [value];
      break;
    case 'textFilter':
      if (value === null || typeof value === 'string') (filter as TextColumnFilter).text = value || '';
      break;
    case 'rangeFilter':
      let range: FilterRange;

      range = value === null || !isRange(value) ? { min: '0.00', max: '0.00' } : { min: (value as FilterRange).min, max: (value as FilterRange).max };

      const rangeFilter = filter as RangeColumnFilter;
      rangeFilter.range = range;

      break;
  }
  filter.valueShowAs = showAs;
  return filter;
}

export function getFilterValue(filter: ColumnFilter): any {
  let result;
  switch (filter.filterType) {
    case 'bitFilter':
      result = (filter as BitColumnFilter).booleanValue;
      break;
    case 'comboFilter':
      return (filter as ComboColumnFilter).selected || 0;
    case 'multiselectComboFilter':
      return (filter as MultiselectComboColumnFilter).selected || [];
    case 'dateFilter':
      return (filter as DateColumnFilter).dateValue || null;
    case 'decimalFilter':
    case 'integerFilter':
      result = (filter as NumberColumnFilter).number;
      break;
    case 'manyToManyRelationFilter':
      return (filter as ManyToManyRelationColumnFilter).selected || [];
    case 'oneToManyRelationFilter':
      return (filter as OneToManyRelationColumnFilter).selected || [];
    case 'textFilter':
      return (filter as TextColumnFilter).text || '';
    case 'rangeFilter':
      return (filter as RangeColumnFilter).range || null;
    default:
      return null;
  }
  if (result === undefined) return null;
  return result;
}

function convert(filter: ColumnFilter): any {
  const result: any = { ...filter };
  if (filter.filterType === 'dateFilter') {
    const dateFilter = filter as DateColumnFilter;
    if (dateFilter.dateValue) result.dateValue = DateUtils.toJSONDate(dateFilter.dateValue);
  } else if (filter.filterType === 'decimalFilter') {
    const decimalFilter = filter as NumberColumnFilter;
    if (decimalFilter.number !== null) result.number = decimalFilter.number.toFixed(4);
  }
  return result;
}
export function toApi(filters: ColumnFilter[]) {
  return filters.map((f) => convert(f));
}

function parse(obj: any): ColumnFilter {
  const result = obj as ColumnFilter;
  if (result.filterType === 'dateFilter' && obj.dateValue) (result as DateColumnFilter).dateValue = DateUtils.fromJSONDate(String(obj.dateValue));
  else if (result.filterType === 'decimalFilter' && obj.number) (result as NumberColumnFilter).number = Number(obj.number);
  return result;
}
export function fromApi(filters: any): ColumnFilter[] {
  if (filters instanceof Array) return filters.map((f) => parse(f));
  else return [];
}

export type FilterHandler = (filters: ColumnFilter[]) => void;
export type FilterManager = {
  addFilterHandler: (handler: FilterHandler) => () => void;
  update: (filters: ColumnFilter[]) => void;
  active: (status: boolean) => void;
};

export function newFilterManager(): FilterManager {
  let handlers: FilterHandler[] = [];
  let managerStatus: boolean = true;

  const addFilterHandler = (handler: FilterHandler) => {
    if (!handlers.find((h) => h === handler)) handlers.push(handler);
    return () => (handlers = handlers.filter((f) => f !== handler));
  };

  const update = (filters: ColumnFilter[]) => {
    handlers.forEach((h) => managerStatus && h(filters));
  };

  const active = (status: boolean) => {
    managerStatus = status;
  }

  return {
    addFilterHandler,
    update,
    active,
  };
}
