import React, { useEffect, useState } from 'react';
import { Notification } from '../../../components/systemactions/SystemAction';
import { CardSubPanelWrapper } from '../../../components/cards/CardSubPanelWrapper';
import { BitControl } from '../../../components/propertycontrols/BitControl';
import { CategoryControl } from '../../../components/propertycontrols/CategoryControl';
import { DateControl } from '../../../components/propertycontrols/DateControl';
import { DecimalControl } from '../../../components/propertycontrols/DecimalControl';
import { ImageControl } from '../../../components/propertycontrols/ImageControl';
import { IntegerControl } from '../../../components/propertycontrols/IntegerControl';
import { ItemToItemControl } from '../../../components/propertycontrols/ItemToItemControl';
import { ItemToManyItemControl } from '../../../components/propertycontrols/ItemToManyItemControl';
import { LinkControl } from '../../../components/propertycontrols/LinkControl';
import { PropertyControlProps } from '../../../components/propertycontrols/PropertyControl';
import { RichTextControl } from '../../../components/propertycontrols/RichTextControl/RichTextControl';
import { TextControl } from '../../../components/propertycontrols/TextControl';
import { UserControl } from '../../../components/propertycontrols/UserControl';
import { ValueListControl } from '../../../components/propertycontrols/ValueListControl';
import { ItemBuiltInFields } from '../../../model/item/Item';
import { ClientItem, Item, ItemCategoryProperty } from '../../../model/item/ItemTypes';
import { PropertyType } from '../../../model/Property';
import { ActionContextProvider } from '../../../model/systemactions/ActionContext';

import './FinancialInstrumentView.css';
import { api } from '../../../utils/api/ApiProvider';
import { tactin } from '../../../utils/TactinGlobals';
import { OpenItemEvent } from '../../../model/events/TactinEvents';

export type FinancialInstrumentProps = {
    itemData: ClientItem;
    columnConfig: [ColumnConfig, ColumnConfig];
    configuration: any;
    onChange: (item: Item) => void;
    contextProvider?: ActionContextProvider;
    notifyHandler?: (note: Notification) => void;
}

export default function FinancialInstrumentView(props: FinancialInstrumentProps) {
    const config = mergeConfig(props.columnConfig);

    const change = (name: string, value: any, showAs?: string) => {
        if (ItemBuiltInFields.has(name)) {
            let field = name;
            if (name === 'category')
                field = 'categoryID';
            props.onChange({ ...props.itemData.item, [field]: value });
        } else {
            const prpId = +(/^pv_(\d+)$/.exec(name)?.[1] || 0);
            if (prpId > 0)
                props.onChange({
                    ...props.itemData.item,
                    properties: {
                        ...props.itemData.item.properties,
                        [prpId]: {
                            ...(props.itemData.item.properties?.[prpId] || {
                                type: props.itemData.item.category?.properties.find(p => p.id === prpId)?.dataType || 'TEXT'
                            }),
                            value,
                            showAs
                        }
                    }
                });
        }
    }

    return <CardSubPanelWrapper
        configuration={props.configuration}
        toolbarDefaults={standardToolbar(props.itemData.item)}
        contextProvider={props.contextProvider}
        notificationHandler={props.notifyHandler} >
        <div className='instrument-details-panel'>
            <InstrumentDetailsPanel config={config}
                forPreview={props.itemData.permission.toLowerCase() !== 'edit' && props.itemData.permission.toLowerCase() !== 'delete'}
                itemData={props.itemData.item} onChange={change} />
        </div>
    </CardSubPanelWrapper>
}

type InstrumentDetailsPanelProps = {
    config: ColumnConfig,
    itemData: Item;
    forPreview?: boolean;
    elements?: (Field | List)[];
    onChange: (name: string, value: any, showAs?: string) => void;
}

function InstrumentDetailsPanel(props: InstrumentDetailsPanelProps) {
    const [subjectItem, setSubjectItem] = useState<Item>();

    useEffect(() => {
        getSubject();
    }, []);

    const getSubject = (value?: number) => {
        api().Item.getItem(value || props.itemData.properties[77].value)
            .then(r => setSubjectItem(r.item));
    }

    const onMainInstrumentDataChange = (name: string, value: any, showAs?: string) => {
        props.onChange(name, value, showAs);

        if (name === "pv_77") {
            getSubject(value);
        }
    }

    return <div className='details'>
        <div className='details-main'>
            <h3>{tactin().configuration.translate('InstrumentData')}</h3>
            <Column key="col-details-main" {...props.config[0]}
                itemData={props.itemData}
                forPreview={props.forPreview}
                onChange={props.onChange} />
        </div>
        <div className='details-other'>
            <div className='details-subject'>
                <h3>{tactin().configuration.translate('SubjectData')}</h3>
                <Column key="col-details-subject" {...props.config[1]}
                    itemData={subjectItem || props.itemData}
                    readonly={true}
                    forPreview={props.forPreview}
                    onChange={() => { }} />
                {subjectItem && !props.forPreview && <div className='section-footer'>
                    <button type='button' onClick={() => tactin().eventBus
                        .notify(new OpenItemEvent().byItemId(subjectItem?.itemID))}>
                        {tactin().configuration.translate('ShowSubjectItem')}
                    </button>
                </div>}
            </div>
            <div className='details-financial-results'>
                <h3>{tactin().configuration.translate('Indicators')}</h3>
                <Column key="col-financial-results" {...props.config[2]}
                    itemData={props.itemData}
                    forPreview={props.forPreview}
                    onChange={props.onChange} />
            </div>
        </div>
    </div>
}

const standardToolbar = (item: Item) => {
    const result = []
    if (!item.individualized)
        result.push('individualizeItem');
    if (item.templateId)
        result.push('restoreSharedItem');
    if (item.itemID > 0 && item.template) {
        result.push('createInstanceDeep');
        result.push('createInstanceShallow');
        if (item.addOns?.series)
            result.push('restoreSeries');
    }
    return result;
}

function mergeConfig([inheritedRaw, primaryRaw]: [ColumnConfig, ColumnConfig]): ColumnConfig {
    const inherited = inheritedRaw as ColumnConfig;
    const primary = primaryRaw as ColumnConfig;
    const colCount = Math.max(inherited.length, primary.length);

    let result = [] as ColumnConfig;

    for (let i = 0; i < colCount; i++) {
        if (primary.length > i) {
            if (primary[i].override) {
                if (primary[i].elements)
                    result.push({ ...primary[i] });
            }
            else
                result.push({
                    elements: [
                        ...(inherited[i]?.elements || []),
                        ...(primary[i].elements || [])
                    ]
                });
        } else {
            result.push({ ...inherited[i] });
        }
    }
    return result;
}

type ColumnConfig = Column[];

type Column = {
    override?: boolean;
    elements?: (Field | List)[];
}

type Field = {
    type: 'field';
    name: string;
    alias?: string;
} & ColumnElement;

type List = {
    type: 'list';
    displayLevel: number;
    aliases?: Field[];
} & ColumnElement;

type ColumnElement = {
    readonly?: boolean;
}

type ColumnProps = {
    itemData: Item;
    elements?: (Field | List)[];
    readonly?: boolean;
    forPreview?: boolean;
    onChange: (name: string, value: any, showAs?: string) => void;
}

function Column({ itemData, elements, forPreview, readonly, onChange }: ColumnProps) {
    const fields = elements?.flatMap(e => {
        if (e.type === 'field')
            return [e];
        else
            return parameterList(itemData, e);
    });
    return <div className='column'>
        {fields && fields.map((e, i) =>
            <Field key={e.name}
                {...e}
                readonly={readonly}
                itemData={itemData}
                forPreview={forPreview}
                onChange={(v, s) => onChange(e.name, v, s)} />)}
    </div>
}

type FieldProps = {
    itemData: Item;
    name: string;
    alias?: string;
    readonly?: boolean;
    forPreview?: boolean;
    onChange: (v: any, showAs?: string) => void;
}

function Field({ itemData, name, alias, forPreview, readonly, onChange }: FieldProps) {
    const builtIn = ItemBuiltInFields.get(name);
    const prpId = +(/^pv_(\d+)$/.exec(name)?.[1] || 0);
    let LocalControl;
    let value;
    let showAs;
    let config;
    let setter = onChange;
    let noEdit = false;
    if (builtIn) {
        if (builtIn.type === 'CATEGORY')
            return <CategoryControl
                label={alias || name || ''}
                value={itemData.category.id}
                showAs={itemData.category.name}
                onChange={onChange}
                type={itemData.type} />;
        LocalControl = getPropertyControl(builtIn.type);
        value = itemData[name as keyof Item] || '';
        //FIXME this should take into account builtin property type
        showAs = String(itemData[name as keyof Item] || '');
    } else if (prpId > 0) {
        const property = itemData.category.properties.find(prp => prp.id === prpId);
        if (property) {
            LocalControl = getPropertyControl(property.dataType);
            value = itemData.properties[prpId]?.value;
            showAs = itemData.properties[prpId]?.showAs;
            config = property.configuration;
            noEdit = property.configuration?.noEdit ?? false;
        }
    } else {
        LocalControl = getPropertyControl();
        value = '';
        showAs = '';
        setter = () => { };
    }
    if (!LocalControl)
        return null;
    return <LocalControl label={alias || name || ''}
        value={value}
        showAs={showAs}
        onChange={setter}
        configuration={config}
        readOnly={noEdit || readonly}
        forPreview={forPreview} />
}

function parameterList(itemData: Item, list: List): Field[] {
    const properties: ItemCategoryProperty[] = (itemData.category?.properties || [])
        .filter(p => p.displayLevel === list.displayLevel)
        .sort((a, b) => a.displayLocation - b.displayLocation)
        .map(p => ({ ...p }));
    if (list.aliases)
        for (const alias of list.aliases) {
            const prp = properties.find(p => `pv_${p.id}` === alias.name);
            if (prp && alias.alias)
                prp.name = alias.alias;
        }
    return properties.map(prp => ({
        type: 'field',
        name: `pv_${prp.id}`,
        alias: prp.name || '',
        readonly: list.readonly
    }));
}

function getPropertyControl(type: PropertyType = 'TEXT') {
    switch (type) {
        case 'BIT': return BitControl;
        case 'DATE': return DateControl;
        case 'DECIMAL': return DecimalControl;
        case 'FILELINK': return LinkControl;
        case 'IMAGE': return ImageControl;
        case 'INTEGER': return IntegerControl;
        case 'ITEM2ITEM': return ItemToItemControl;
        case 'ITEM2MANYITEMS': return ItemToManyItemControl;
        case 'RICHTEXT': return RichTextControl;
        case 'TEXT': return TextControl;
        case 'USER': return UserControl;
        case 'VALUELIST': return ValueListControl;
    }
    return PrpDiv;
}

function PrpDiv(props: PropertyControlProps) {
    return <div>{props.label}: "{JSON.stringify(props.value) || ''}"</div>;
}
