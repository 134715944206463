import React from "react";

import './ArticleLinkButton.css';

export type ArticleLinkButtonProps = {
    showArticle: () => void;
}

export default function ArticleLinkButton(props: ArticleLinkButtonProps) {
    const btnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        props.showArticle();
    }

    return <button type='button' className='article-link' onClick={btnClick}>?</button>
}