import React, { ChangeEvent, ChangeEventHandler, PropsWithChildren, ReactElement, ReactHTMLElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TLocalEventBus } from '../../../model/events/LocalEventBus';
import { ROW_KEY_DELIMITER } from '../../../model/list/cache/DataCache';
import { RowData, FoldableRow } from '../../../model/list/cache/RowParser';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { api } from '../../../utils/api/ApiProvider';
import { tactin } from '../../../utils/TactinGlobals';
import ArticleLinkButton from './ArticleLinkButton';
import { InstrumentSelection } from './BasicDataTable';

import './ListTable.css';

export type GroupingHandler = { updateGroup: (name: string) => void, defaultGroups: string[], groups: string[] }
export type SortingHandler = (name: string) => { updateSort: (name: string) => void, sortColumn: string, sortDirection: 'ASC' | 'DESC' }

const GROUPING_ITEM_ID_SEPARATOR = '↔';

export function useSortingHandler() {
    const [sorting, setSorting] = useState<{ name: string, direction: 'ASC' | 'DESC' }>({ name: '', direction: 'ASC' });

    const updateSorting = (name: string) => {
        if (name !== sorting.name)
            setSorting({ name, direction: 'ASC' })
        else if (sorting.direction === 'ASC')
            setSorting({ name: sorting.name, direction: 'DESC' });
        else
            setSorting({ name: '', direction: 'ASC' });
    }

    const sortHandler: SortingHandler = (name: string) => ({
        updateSort: updateSorting,
        sortColumn: sorting.name,
        sortDirection: sorting.direction
    });

    return { sorting, sortHandler };
}

type ListTableProps = {
    rowCount: number;
    rowHeight: number;

    columns: string[];
    widths: string[];
    aggregations: string[];
    showDescription: boolean;

    getData: (start: number, length: number) => RowData[];

    onFoldToggle?: (meta: string) => void;

    eventBus?: TLocalEventBus;
    onHighLight: (row: RowData) => void;
    onClick: (row: RowData) => void;
    onDblClick: (row: RowData) => void;

    groupable?: GroupingHandler;
    sortable?: SortingHandler;

    decorate: boolean;
    footerFields?: (rowCount: number, range: { start: number, length: number }) => JSX.Element[];

    allowCheckRow: boolean;
    finishedLoading: () => void;
    getRowCheckedState: (id: number | undefined) => boolean;
    showArticle?: (name: string) => void;
    onInstrumentCheckedChanged: (id: number, checked: boolean) => void;
}

export function ListTable(props: ListTableProps) {
    const tbodyRef = useRef<HTMLDivElement>(null);
    const showAsRef = useRef<HTMLSpanElement>(null);
    const descriptBlock = useRef<HTMLDivElement>(null);
    const descriptContent = useRef<HTMLDivElement>(null);

    const [start, setStart] = useState(0);
    const [visibleRows, setVisibleRows] = useState(1);
    const [bottomPadding, setBottomPadding] = useState(0);

    const [selectedRow, selectRow] = useState(0);
    const [selectedRowId, selectRowId] = useState(0);
    const [itemDescription, setItemDescription] = useState('');
    const [highlighted, setHighlighted] = useState(-1);
    const [ifKeyDown, setKeyDown] = useState(false);
    // const maxStart = Math.max(0, props.rowCount - visibleRows);

    useLayoutEffect(() => {
        tableResized(Math.max(0, (tbodyRef.current?.offsetHeight || 0)));
    });

    useLayoutEffect(() => {
        selectRow(-1);
        setHighlighted(-1);
    }, [props.onFoldToggle]);

    useLayoutEffect(() => {
        focusOnRow(highlighted);
    }, [highlighted]);

    useEffect(() => {
        if (props.eventBus)
            return props.eventBus.register(localEventHandler);
    }, [props.eventBus]);

    const onEnterClick = () => {
        const rowToSelect = highlighted < 0 ? 0 : highlighted;
        const [dataRow] = props.getData(rowToSelect, 1);
        if (rowToSelect < start || rowToSelect > start + visibleRows - 1)
            focusOnRow(rowToSelect);
        onClick(dataRow, rowToSelect)();
    }
    const onMoveUp = () => (highlighted > 0) && setHighlighted(highlighted - 1);
    const onMoveDown = () => (highlighted < props.rowCount - 1) && setHighlighted(highlighted + 1);

    const localEventHandler = (type: string, data: any) => {
        switch (type) {
            case 'onEnterClick': onEnterClick(); break;
            case 'onMoveUp': onMoveUp(); break;
            case 'onMoveDown': onMoveDown(); break;
            case 'focus': focus(data || false); break;
        }
    }

    const focus = (resetHighlight: boolean) => {
        if (resetHighlight)
            setHighlighted(0);
        tbodyRef.current?.focus();
    }
    const focusOnRow = (rowNo: number) => {
        if (rowNo > -1 && (rowNo < start || rowNo > start + visibleRows - 1))
            tbodyRef.current?.scrollTo(0, Math.max(0, rowNo - Math.floor(visibleRows / 2)) * props.rowHeight);
    }

    useEffect(() => {
        const resizeListener = () => {
            tableResized(Math.max(0, (tbodyRef.current?.offsetHeight || 0)));
        };
        const clickListener = (e: MouseEvent) => {
            if (!tbodyRef.current?.contains(e.target as HTMLDivElement))
                setHighlighted(-1)
        }
        addEventListener('resize', resizeListener);
        addEventListener('click', clickListener);
        return () => {
            removeEventListener('resize', resizeListener);
            removeEventListener('click', clickListener);
        }
    });

    useEffect(() => {
        if (start > props.rowCount - visibleRows)
            setStart(Math.max(0, props.rowCount - visibleRows));
    }, [start, visibleRows, props.rowCount]);

    useEffect(() => {
        if (highlighted >= 0) {
            const rowData = props.getData(highlighted, 1)[0];
            if (rowData)
                props.onHighLight(rowData);
            else
                props.onHighLight({ columns: [], id: -1 });
        }
    }, [highlighted]);

    const tableResized = (innerHeight: number) => {
        const newRowCount = Math.max(1, props.rowHeight && Math.floor(innerHeight / props.rowHeight));
        setBottomPadding(innerHeight - newRowCount * props.rowHeight);
        if (newRowCount !== visibleRows) {
            setVisibleRows(newRowCount);
            if ((newRowCount + start > props.rowCount) && start > 0)
                setStart(Math.max(0, props.rowCount - newRowCount));
        }
    }

    const onClick = (r: RowData, globalIndex: number) =>
        () => {
            const id = r.id as number;
            selectRow(globalIndex);
            if (id !== selectedRowId) {
                selectRowId(id);
                setItemDescription('<p>...Loading</p');
            }
            setHighlighted(globalIndex);
            props.onClick(r);
        }

    const keyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!ifKeyDown) {
            setKeyDown(true);
            if (e.key === 'Enter')
                onEnterClick();
            else if (e.key === 'ArrowUp')
                onMoveUp();
            else if (e.key === 'ArrowDown')
                onMoveDown();
        }
    }

    const mouseButtonRef = useRef(false);
    useEffect(() => {
        const handler = (event: any) => {
            if (event.button === 0) {
                mouseButtonRef.current = event.type === 'mousedown';
                if (!mouseButtonRef.current && tbodyRef.current) {
                    if (Math.abs((tbodyRef.current.scrollTop / props.rowHeight) - start) > 0.1)
                        snapToRow();
                }
            }
        };
        window.addEventListener('mousedown', handler);
        window.addEventListener('mouseup', handler);
        return () => {
            window.removeEventListener('mousedown', handler);
            window.removeEventListener('mouseup', handler);
        }
    }, [props.rowHeight, start]);

    const snapToRow = () => {
        let startPos = (tbodyRef.current?.scrollTop ?? 0) / props.rowHeight;
        startPos = Math.round(startPos);
        tbodyRef.current?.scrollTo(0, startPos * props.rowHeight);
    }

    const timerRef = useRef(0);
    const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (timerRef.current)
            window.clearTimeout(timerRef.current);
        timerRef.current = window.setTimeout(() => {
            if (!mouseButtonRef.current) {
                snapToRow();
            }
            timerRef.current = 0;
        }, 200);

        setStart(Math.round(event.currentTarget.scrollTop / props.rowHeight));
    }

    useEffect(() => {
        if (selectedRowId)
            api().Item.getItemDescription(selectedRowId)
                .then(r => setItemDescription(r))
                .catch(HandleErrors())
    }, [selectedRowId])

    useEffect(() => {
        setItemDescription('');
        selectRowId(0);
    }, [props])
    // for executing after 30 sec if data was updated

    useLayoutEffect(() => {
        if (!window.sessionStorage.descriptContentHeight && tbodyRef.current)
            window.sessionStorage.descriptContentHeight = tbodyRef.current.offsetHeight / 3;
        if (descriptContent.current)
            descriptContent.current.style.height = window.sessionStorage.descriptContentHeight + 'px';
    }, [])

    useEffect(() => {
        if (descriptBlock.current && descriptContent.current) {
            const mousedown = (e: MouseEvent) => {
                const element = descriptContent.current as HTMLDivElement;
                const prevHeight = element.offsetHeight;
                let prevY = e.clientY;

                const mousemove = (e: MouseEvent) => {
                    const tbodyHeight = tbodyRef.current?.offsetHeight as number;
                    if (tbodyHeight < 40 && (prevY - e.clientY) > 0) return;
                    element.style.height = prevHeight + (prevY - e.clientY) + 'px';
                    window.sessionStorage.descriptContentHeight = prevHeight + (prevY - e.clientY);
                }
                const mouseup = (e: MouseEvent) => {
                    window.removeEventListener('mousemove', mousemove, false);
                    window.removeEventListener('mouseup', mouseup, false);
                }
                window.addEventListener('mousemove', mousemove, false);
                window.addEventListener('mouseup', mouseup, false);
            }

            descriptBlock.current.addEventListener('mousedown', mousedown);
            return () => descriptBlock.current?.removeEventListener('mousedown', mousedown);
        }
    })

    useEffect(() => {
        //props.finishedLoading();
    }, []);

    const setRowCheckedChanged = (checked: boolean, id?: number) => {
        if (id) {
            props.onInstrumentCheckedChanged(id, checked);
        }
    }

    const areTableRowsCheckable = (): boolean => {
        const table = document.getElementById('instrumentsSelectTable');

        if (table) {
            return table.querySelectorAll('input[type="checkbox"]').length > 0;
        }

        return false;
    }

    return (<div id='instrumentsSelectTable' className='table' >
        <TableHeader columns={props.columns} widths={props.widths} groupable={props.groupable} 
            sortable={props.sortable} applyPadding={areTableRowsCheckable} showArticle={props.showArticle} />
        <div className='tbody' ref={tbodyRef}
            onScroll={onScroll}
            tabIndex={1} onKeyDown={keyDown} onKeyUp={() => setKeyDown(false)}>
            <TablePadding height={props.rowHeight * start} />
            {props.getData(start, visibleRows)
                .map((r, i) => {
                    const globalInd = start + i;
                    return <ListRow
                        key={globalInd}
                        data={r}
                        columns={props.columns}
                        widths={props.widths}
                        groups={props.groupable?.groups}
                        onFoldToggle={props.onFoldToggle}
                        isSelectedRow={selectedRow === globalInd}
                        isHighlighted={highlighted === globalInd}
                        onMouseOver={text => showAsRef.current && (showAsRef.current.textContent = text)}
                        onMouseOut={text => showAsRef.current && (showAsRef.current.textContent === text) && (showAsRef.current.textContent = '')}
                        onClick={onClick(r, globalInd)}
                        onDblClick={() => props.onDblClick(r)}
                        allowCheckRow={props.allowCheckRow}
                        instrumentChecked={r.isRowChecked}
                        onInstrumentCheckedChanged={(event: ChangeEvent<HTMLInputElement>) => setRowCheckedChanged(event.target.checked, r.id)} />
                })}
            <TablePadding height={props.rowHeight * Math.max(0, props.rowCount - start - visibleRows) + bottomPadding} />
        </div>
        {(props.decorate && props.aggregations.reduce((a, b) => a || !!b, false)) ?
            <TableFooter columns={props.columns} widths={props.widths} aggregations={props.aggregations} />
            : null}
        {props.decorate && props.showDescription &&
            <div ref={descriptBlock} className='description-block'>
                <div className='resizer'></div>
                <p className='label' >Opis</p>
                <div ref={descriptContent} className='content' dangerouslySetInnerHTML={{ __html: itemDescription }} />
            </div>
        }
        {(props.decorate && props.footerFields) ?
            (<div className='tr footer'>
                {props.footerFields(props.rowCount, { start, length: Math.min(props.rowCount, visibleRows) })}
                <span ref={showAsRef} key='showAs'></span>
            </div>)
            : null}
    </div>);
}

function TablePadding({ height }: { height: number }) {
    if (height > 0)
        return <div style={{ display: 'inline-block', height: `${height}px`, color: 'transparent' }}>.</div>
    else
        return null;
}

type TableRowProps<T> = {
    columns: string[];
    widths: string[];
    groups?: string[];
    className?: string;
    id?: number;
    data?: RowData;
    isSelectedRow?: boolean;
    isHighlighted?: boolean;
    instrumentChecked?: boolean;
    allowCheckRow?: boolean;
    onInstrumentCheckedChanged?: (event: ChangeEvent<HTMLInputElement>) => void;
    showArticle?: (name: string) => void;
} & T;

type RowProps = {
    children: (props: TableCellProps<{ i: number }>) => ReactElement;
    onClick?: () => void;
    onDblClick?: () => void;
}

function TableRow(props: TableRowProps<RowProps>) {
    const groupRow = (props.data && (props.data.subCount ?? 0) > 0 && !props.data?.id) ?? false;
    const classes = ['tr'];
    if (props.className) classes.push(props.className);
    if (groupRow) classes.push('group-row');
    if (props.isSelectedRow) classes.push('selected');
    if (props.isHighlighted) classes.push('highlighted');

    const getChecked = (): boolean | undefined => {
        return props.instrumentChecked;
    }

    return (
        <div className={classes.join(' ')}
            onClick={props.onClick}
            onDoubleClick={props.onDblClick}>
            {props.className !== "header" && props.className !== "footer" && props.allowCheckRow &&
                <input key={Math.random()} type='checkbox' checked={getChecked()} onChange={props.onInstrumentCheckedChanged}></input>}
            {props.columns.map((c, i) =>
                props.children({
                    i, width: props.widths[i] || `${Math.floor(100 / props.columns.length)}%`,
                    canGrow: !props.widths[i]
                }))}
        </div>
    );
}

type TableCellProps<T> = {
    width: string;
    canGrow?: boolean;
    className?: string;
    showArticle?: () => void;
} & T;

type CellEventHandler = {
    onClick?: (event: any) => void;
    onMouseOver?: (event: any) => void;
    onMouseOut?: (event: any) => void;
}
function TableCell(props: PropsWithChildren<TableCellProps<CellEventHandler>>) {
    const classes = ['td'];
    if (props.className)
        classes.push(props.className);
    return (<div className={classes.join(' ')} style={{ width: `${props.width}` }}
        onClick={props.onClick}
        onMouseOver={props.onMouseOver}
        onMouseOut={props.onMouseOut}>
        {props.children}
    </div>);
}

type TableHeaderProps = {
    groupable?: GroupingHandler;
    sortable?: SortingHandler;
    applyPadding?: () => boolean;

}

function TableHeader(props: TableRowProps<TableHeaderProps>) {
    const [headerRowClass, setHeaderRowClass] = useState<string>('header');

    useEffect(() => {
        const tableWidth = document.getElementById("instrumentsSelectTable")?.clientWidth || 0;
        const totalColumnsWidth = props.widths && props.widths.length && parseInt(props.widths.reduce((a, b) => (parseInt(a) + parseInt(b)).toFixed()));

        if (totalColumnsWidth < tableWidth) {
            if (props.applyPadding && props.applyPadding()) {
                setHeaderRowClass('header padded full-width');
            } else {
                setHeaderRowClass('header full-width');
            }
        } else {
            if (props.applyPadding && props.applyPadding()) {
                setHeaderRowClass('header padded');
            } else {
                setHeaderRowClass('header');
            }
        }
    });

    return <TableRow className={headerRowClass} columns={props.columns} widths={props.widths}>
        {({ i, width, canGrow }) =>
            <ColumnHeader key={`col${i}`} width={width} canGrow={canGrow} name={props.columns[i]}
                group={props.groupable} sort={props.sortable} 
                showArticle={() => props.showArticle && props.showArticle(props.columns[i])} />}
    </TableRow>
}

type ColumnHeaderProps = {
    name: string;
    group?: GroupingHandler;
    sort?: SortingHandler;
}
function ColumnHeader({ name, width, canGrow, group, sort, showArticle }: TableCellProps<ColumnHeaderProps>) {
    const s = sort && sort(name);
    let rowClass = '';
    if (s && s.sortColumn === name)
        rowClass = s.sortDirection === 'ASC' ? ' sort-asc' : ' sort-desc';
    if (group && group.groups.includes(name))
        rowClass += ' grouped-by';

    const groupingButton = () => {
        if (group)
            if (group.defaultGroups.includes(name))
                return null;
            else
                return <button
                    className='group-toggle'
                    onClick={e => {
                        e.stopPropagation();
                        group.updateGroup(name);
                    }}
                >{group.groups.includes(name) ? '>' : '<'}</button>
    }

    const articleLinkButton = () => {
        if (showArticle) {
            return <ArticleLinkButton showArticle={showArticle} />
        }
    }

    return (<TableCell className={rowClass} width={width} canGrow={canGrow} onClick={() => s && s.updateSort(name)}>
        <span>{name.startsWith('"') ? name.slice(1, name.length - 1) : name}</span>
        {groupingButton()}
        {articleLinkButton()}
    </TableCell>);
}

type TableFooterProps = {
    aggregations: string[];
}

function TableFooter(props: TableRowProps<TableFooterProps>) {
    return <TableRow className='footer' columns={props.columns} widths={props.widths}>
        {({ i, width, canGrow }) =>
            <TableCell key={`col${i}`} width={width} canGrow={canGrow}>{props.aggregations[i] || ''}</TableCell>}
    </TableRow>
}

type ListCellProps = {
    data: string | FoldableRow;
    subCount?: number;
    groupColumn?: boolean;
    onFold?: (meta: string) => void;
    onMouseOver?: (fullText: string) => void;
    onMouseOut?: (fullText: string) => void;
}

function ListCell(props: TableCellProps<ListCellProps>) {
    let content: JSX.Element | null = null;
    let text: string = '';
    if (typeof props.data === 'string') {
        text = props.data
        content = <>{text}</>;
    } else {
        const index = props.data.showAs.indexOf(GROUPING_ITEM_ID_SEPARATOR);
        text = index < 0 ? props.data.showAs : props.data.showAs.slice(index + 1);
        if (!text)
            text = tactin().configuration.translate('<empty>');
        const spanCount = props.data.meta.split(ROW_KEY_DELIMITER, -1).length - 1;
        let padding: any = null;
        if (spanCount > 0) {
            padding = [];
            for (let i = 0; i < spanCount; i++) {
                padding.push(<span key={i} className='list-level-padding' />)
            }
        }
        content = (<>
            {padding}
            {props.subCount ? <button
                className={`group-expand${props.data.opened ? ' expanded' : ''}`}
                onClick={
                    () => typeof props.data !== 'string' && props.onFold && props.onFold(props.data.meta)}>
                {props.data.opened ? '-' : '+'}
            </button> : null}
            <span>{text}</span>
        </>);
    }
    return (<TableCell className={props.groupColumn ? 'grouped' : ''} width={props.width} canGrow={props.canGrow}
        onMouseOver={e => props.onMouseOver && props.onMouseOver(text)}
        onMouseOut={e => props.onMouseOut && props.onMouseOut(text)}>
        {content}
    </TableCell>);
}

type ListRowProps = {
    data: RowData;
    isSelectedRow?: boolean;
    isHighlighted?: boolean;

    onFoldToggle?: (meta: string) => void;
    onMouseOver?: (fullText: string) => void;
    onMouseOut?: (fullText: string) => void;

    onClick: () => void;
    onDblClick: () => void;
}
function ListRow(props: TableRowProps<ListRowProps>) {
    return <TableRow columns={props.columns} widths={props.widths} onClick={props.onClick} onDblClick={props.onDblClick}
        isSelectedRow={props.isSelectedRow} isHighlighted={props.isHighlighted} data={props.data} instrumentChecked={props.instrumentChecked}
        allowCheckRow={props.allowCheckRow} onInstrumentCheckedChanged={props.onInstrumentCheckedChanged}>
        {({ i, width, canGrow }) =>
            <ListCell key={`col${i}`} width={width} canGrow={canGrow}
                data={props.data.columns[i] || ''}
                subCount={props.data.subCount}
                groupColumn={i < (props.groups?.length ?? 0)}
                onFold={props.onFoldToggle}
                onMouseOver={props.onMouseOver}
                onMouseOut={props.onMouseOut} />}
    </TableRow>
}

type MockRowProps = {
    onSizeSet: (size: number) => void;
}

export function MockRow(props: MockRowProps) {
    const ref = useRef<HTMLTableRowElement>(null);

    useLayoutEffect(() => {
        if (ref.current)
            props.onSizeSet(ref.current.offsetHeight || 1);
    });

    return (<div className='table mock-row'><div className='tbody'>
        <div className='tr' ref={ref}><div className='td'>.</div></div>
    </div></div>);
}
