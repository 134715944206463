import React from 'react';

type ImageProps = {
    name: string;
    hint?: string;
    className?: string;
}
export default function Image({name, hint, className}: ImageProps) {
    const src = name ? `img/${name}.png` : undefined;
    return <img className={className} src={src} title={hint} draggable="false"/>
}
