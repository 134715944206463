import { CopyAnalysisParams, DownloadAnalysisParams, ExportCalculatedIndicatorsParams, GetIndicatorsCalculationParams, GetMinMaxRangeParams, GetStatisticsParams, ProcessingProgressParams, TCngrApi } from './ApiTypes';
import { toArticleLinksConfigurationResult, toCopyAnalysisResult, toFileTokenResult, toGetAvailableCreditsCountResult, toGetItemResult, toGetItemsResult, toImportResult, toIndicatorsCalculationResult, toMinMaxRangeResult, toProcessingProgressResult, toRecalculateStatistics, toStringResult } from './Results';
import * as Api from "./TactinApi";

const MODULE = "CngrModule";

export const CngrApi = (token: string): TCngrApi => ({
    uploadCsvForImport: (fileHandlerClass: string, data: File, showProgress?: (progress: number) => void, showProcessingProgress?: (progress: number) => void, getAbort?: (fn: () => void) => void) =>
        Api.uploadFile(MODULE, "uploadCsvForImport", { fileHandlerClass }, data, token, showProgress, showProcessingProgress, getAbort).then(r => toImportResult(r)),
    importPkdCodes: (fileHandlerClass: string, data: File, showProgress?: (progress: number) => void, showProcessingProgress?: (progress: number) => void, getAbort?: (fn: () => void) => void) =>
        Api.uploadFile(MODULE, "importPkdCodes", { fileHandlerClass }, data, token, showProgress, showProcessingProgress, getAbort).then(r => toImportResult(r)),
    importInterestRates: (fileHandlerClass: string, data: File, showProgress?: (progress: number) => void, showProcessingProgress?: (progress: number) => void, getAbort?: (fn: () => void) => void) =>
        Api.uploadFile(MODULE, "importInterestRates", { fileHandlerClass }, data, token, showProgress, showProcessingProgress, getAbort).then(r => toImportResult(r)),
    importCurrencies: (fileHandlerClass: string, data: File, showProgress?: (progress: number) => void, showProcessingProgress?: (progress: number) => void, getAbort?: (fn: () => void) => void) =>
        Api.uploadFile(MODULE, "importCurrencies", { fileHandlerClass }, data, token, showProgress, showProcessingProgress, getAbort).then(r => toImportResult(r)),
    importDocuments: (fileHandlerClass: string, data: File, showProgress?: (progress: number) => void, showProcessingProgress?: (progress: number) => void, getAbort?: (fn: () => void) => void) =>
        Api.uploadFile(MODULE, "importDocuments", { fileHandlerClass }, data, token, showProgress, showProcessingProgress, getAbort).then(r => toImportResult(r)),
    calculateIndicators: (data: GetIndicatorsCalculationParams) => 
        Api.sendRequest(MODULE, "calculateIndicators", data, token).then(r => toIndicatorsCalculationResult(r)),
    recalculateStatistics: (data: GetStatisticsParams) =>
        Api.sendRequest(MODULE, "recalculateStatistics", data, token).then(r => toRecalculateStatistics(r)),
    downloadAnalysis: (data: DownloadAnalysisParams) =>
        Api.sendRequest(MODULE, "downloadAnalysis", data, token).then(r => toFileTokenResult(r)),
    decreaseSecurityCredits: (data: DownloadAnalysisParams) =>
        Api.sendRequest(MODULE, "decreaseSecurityCredits", data, token).then(r => toStringResult(r)),
    exportCalculatedIndicators: (data: ExportCalculatedIndicatorsParams) =>
        Api.sendRequest(MODULE, "exportCalculatedIndicators", data, token).then(r => toFileTokenResult(r)),
    getArticleLinksConfiguration: () =>
        Api.sendRequest(MODULE, "getArticleLinksConfiguration", {}, token).then(r => toArticleLinksConfigurationResult(r)),
    getAvailableCreditsCount: () =>
        Api.sendRequest(MODULE, "getAvailableCreditsCount", {}, token).then(r => toGetAvailableCreditsCountResult(r)),
    getMinMaxPropertyValueRange: (data: GetMinMaxRangeParams) =>
        Api.sendRequest(MODULE, "getMinMaxPropertyValueRange", data, token).then(r => toMinMaxRangeResult(r)),
    getActiclesForMainPage: () =>
        Api.sendRequest(MODULE, "getArticlesForMainPage", {}, token).then(r => toGetItemsResult(r)),
    copyAnalysis: (data: CopyAnalysisParams) =>
        Api.sendRequest(MODULE, "copyAnalysis", data, token).then(r => toCopyAnalysisResult(r)),
    getProcessingProgress: (data: ProcessingProgressParams) =>
        Api.sendRequest(MODULE, "getProcessingProgress", data, token).then(r => toProcessingProgressResult(r)),
    });
 