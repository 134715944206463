import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FilterRange } from '../../../../model/list/ColumnFilter';
import { tactin } from '../../../../utils/TactinGlobals';

import './NumberRangeControl.css';

type NumberRangeControlProps = {
    label: string;
    unit: string;
    className: string;
    onChange: (range: FilterRange, showAs: string) => void;
    configuration?: any,
    min?: number | null;
    max?: number | null;
    range?: FilterRange;
    startMin: number;
    startMax: number;
    step?: number;
    precision?: number;
    applyThousendSeparator?: boolean;
    updatesDisabledOnInit: boolean;
}

export function NumberRangeControl(props: NumberRangeControlProps) {
    const translate = tactin().configuration.translate;
    const precision = props.precision ?? 2;


    const addSeparators = (num: number) => props.applyThousendSeparator
        ? parseFloat(num.toFixed(precision)).toLocaleString('pl', { minimumFractionDigits: precision, maximumFractionDigits: precision })
        : num.toFixed(precision);

    const removeSeparators = (num: string) => props.applyThousendSeparator
        ? parseFloat(num.replace(/\s/g, '').replace(',', '.'))
        : parseFloat(num);

    const [allPositions, setAllPositions] = useState<number[]>([]);

    const [minValue, setMinValue] = useState<string>(addSeparators(props.min ? props.min : props.startMin));
    const [maxValue, setMaxValue] = useState<string>(addSeparators(props.max ? props.max : props.startMax));

    const [startMin, setStartMin] = useState<number>(props.startMin ? props.startMin : 0);
    const [startMax, setStartMax] = useState<number>(props.startMax ? props.startMax : 100);
    const [valueStep, setValueStep] = useState<number>(1);
    const currentLeftStep = useRef<number>(props.step ?? 0);
    const currentRightStep = useRef<number>(props.step ?? 0);
    const [sliderPositionStep, setSliderPositionStep] = useState<number>();

    const [minCursorPosition, setMinCursorPosition] = useState<number | undefined | null>();
    const [maxCursorPosition, setMaxCursorPosition] = useState<number | undefined | null>();

    const [updatesDisabled, setUpdatesDisabled] = useState<boolean>(props.updatesDisabledOnInit);

    const leftSliderRef = useRef<HTMLSpanElement>(null);
    const rightSliderRef = useRef<HTMLSpanElement>(null);
    const rangeSlider = useRef<HTMLDivElement>(null);
    const minInputRef = useRef(null);
    const maxInputRef = useRef(null);

    const leftSliderClicked = () => {
        if (leftSliderRef.current) {
            document.removeEventListener("mousemove", updateMin);
        }

        if (leftSliderRef.current) {
            document.addEventListener('mousemove', updateMin);
            document.addEventListener('mouseup', removeLeftMoveListener);
        }
    }

    const rightSliderClicked = () => {
        if (rightSliderRef.current) {
            document.removeEventListener("mousemove", updateMin);
        }

        if (rightSliderRef.current) {
            document.addEventListener('mousemove', updateMax);
            document.addEventListener('mouseup', removeRightMoveListener);
        }
    }

    useLayoutEffect(() => {
        const input = minInputRef.current;

        if (input && minCursorPosition) {
            (input as HTMLInputElement).setSelectionRange(minCursorPosition, minCursorPosition);
        }

    }, [minValue, minInputRef, minCursorPosition]);

    useLayoutEffect(() => {
        const input = maxInputRef.current;

        if (input && maxCursorPosition) {
            (input as HTMLInputElement).setSelectionRange(maxCursorPosition, maxCursorPosition);
        }

    }, [maxValue, maxInputRef, maxCursorPosition]);

    useEffect(() => {
        if (rangeSlider.current) {
            if ((props.startMax - props.startMin) > rangeSlider.current.clientWidth) {
                setStartMax(props.startMax);
            } else if (props.step) {
                const sliderStep = Math.floor(rangeSlider.current.clientWidth / (props.startMax - props.startMin));
                setSliderPositionStep(sliderStep);

                let tempStep = 0;
                let tempPositions: number[] = [];

                for (let i = 0; i <= props.startMax - props.startMin; i++) {
                    tempPositions.push(tempStep);
                    tempStep += sliderStep;
                }

                setAllPositions(tempPositions);
            }

            setValueStep(props.step ? props.step : (startMax - startMin) / rangeSlider.current.clientWidth);
        }
    }, []);

    useEffect(() => {
        if (props.range) {
            setMinValue(() => addSeparators(props.range ? parseFloat(props.range.min.replace(" ", "")) : props.startMin));
            setMaxValue(() => addSeparators(props.range ? parseFloat(props.range.max) : props.startMax));
        }

    }, [props.range]);

    function updateMin(event: MouseEvent) {
        if (leftSliderRef.current && rangeSlider.current
            && sliderPositionIsBeyondStart(leftSliderRef.current, 1)
            && leftSliderIsBeforeRightSlider(event)) {
            let position = sliderPositionStep
                ? allPositions[currentLeftStep.current]
                : event.clientX - rangeSlider.current.getBoundingClientRect().left;

            if (position < 0) {
                position = 0;
            }

            if (sliderPositionStep && (event.movementX !== 0 && event.movementX > 0
                ? event.clientX - rangeSlider.current.getBoundingClientRect().left > position
                : event.clientX < leftSliderRef.current.getBoundingClientRect().right)) {
                leftSliderRef.current.style.left = position.toFixed() + 'px';
                const value = (startMin + (props.step ? currentLeftStep.current : (Math.abs(parseFloat(leftSliderRef.current.style.left))) * valueStep));
                let newStep = event.movementX > 0 ? currentLeftStep.current + (props.step ? props.step : 1) : currentLeftStep.current - (props.step ? props.step : 1);

                if (newStep < 0) {
                    newStep = 0;
                }

                currentLeftStep.current = newStep;
                setMinValue(addSeparators(value));
                setUpdatesDisabled(false);
            } else if (!sliderPositionStep) {
                leftSliderRef.current.style.left = position.toFixed() + 'px';
                const value = (props.startMin + (props.step ? valueStep : (Math.abs(parseFloat(leftSliderRef.current.style.left))) * valueStep));
                setMinValue(addSeparators(value));
                setUpdatesDisabled(false);
            }
        }
    }

    function updateMax(event: MouseEvent) {
        if (rightSliderRef.current && rangeSlider.current
            && sliderPositionIsBeyondStart(rightSliderRef.current, -1)
            && rightSliderIsAfterLeftSlider(event)) {
            let position = sliderPositionStep
                ? allPositions[currentRightStep.current]
                : rangeSlider.current.offsetWidth - (event.clientX - rangeSlider.current.getBoundingClientRect().left) - rightSliderRef.current.offsetWidth;

            if (position < 0) {
                position = 0;
            }

            if (sliderPositionStep && (event.movementX !== 0 && event.movementX < 0
                ? event.clientX < rightSliderRef.current.getBoundingClientRect().right
                : event.clientX > rightSliderRef.current.getBoundingClientRect().right)) {
                rightSliderRef.current.style.left = -position.toFixed() + 'px';
                const value = (startMax - (props.step ? currentRightStep.current : (Math.abs(parseFloat(rightSliderRef.current.style.left)) * valueStep)));
                let newStep = event.movementX < 0 ? currentRightStep.current + (props.step ? props.step : 1) : currentRightStep.current - (props.step ? props.step : 1);

                if (newStep < 0) {
                    newStep = 0;
                }

                currentRightStep.current = newStep;
                setMaxValue(addSeparators(value));
                setUpdatesDisabled(false);
            } else if (!sliderPositionStep) {
                rightSliderRef.current.style.left = -position.toFixed() + 'px';
                const value = (startMax - (props.step ? valueStep : (Math.abs(parseFloat(rightSliderRef.current.style.left)) * valueStep)));
                setMaxValue(addSeparators(value));
                setUpdatesDisabled(false);
            }
        }
    }

    function removeLeftMoveListener() {
        if (leftSliderRef.current) {
            document.removeEventListener("mousemove", updateMin);
            document.removeEventListener("mouseup", updateMin);
        }
    }

    function removeRightMoveListener() {
        if (rightSliderRef.current) {
            document.removeEventListener("mousemove", updateMax);
            document.removeEventListener("mouseup", updateMax);
        }
    }

    const getMaxRangeSliderPosition = () =>
        rightSliderRef.current && rangeSlider.current ? rightSliderRef.current.getBoundingClientRect().left -
            rangeSlider.current.getBoundingClientRect().left : 0;

    const getMinRangeSliderPosition = () =>
        leftSliderRef.current && rangeSlider.current ? leftSliderRef.current.getBoundingClientRect().right -
            rangeSlider.current.getBoundingClientRect().left : 0;

    const sliderPositionIsBeyondStart = (slider: HTMLSpanElement, direction: number) =>
        isSliderPositionStyleDefaultSet(slider, "left") || (direction >= 0 ? parseInt(slider.style.left, 10) >= 0 : parseInt(slider.style.left, 10) < 0);

    const leftSliderIsBeforeRightSlider = (event: MouseEvent) =>
        leftSliderRef.current && rightSliderRef.current &&
        (isSliderPositionStyleDefaultSet(leftSliderRef.current, "left")
            || (event.movementX < 0 && event.clientX + leftSliderRef.current.offsetWidth < rightSliderRef.current.getBoundingClientRect().left)
            || (event.movementX > 0 && leftSliderRef.current.getBoundingClientRect().right + (sliderPositionStep || 0) < rightSliderRef.current.getBoundingClientRect().left
                && parseInt(leftSliderRef.current.style.left) + leftSliderRef.current.offsetWidth < getMaxRangeSliderPosition()));

    const rightSliderIsAfterLeftSlider = (event: MouseEvent) =>
        rightSliderRef.current && leftSliderRef.current && rangeSlider.current &&
        (isSliderPositionStyleDefaultSet(rightSliderRef.current, "left")
            || (event.movementX > 0 && (event.clientX - rangeSlider.current.getBoundingClientRect().left) >
                (leftSliderRef.current.getBoundingClientRect().right - rangeSlider.current.getBoundingClientRect().left))
            || (event.movementX < 0 && rightSliderRef.current.getBoundingClientRect().left + 1 > leftSliderRef.current.getBoundingClientRect().right
                + (sliderPositionStep || 0)
                && rightSliderRef.current.getBoundingClientRect().left > getMinRangeSliderPosition()));

    const isSliderPositionStyleDefaultSet = (slider: HTMLSpanElement, positionProperty: any) =>
        isNaN(parseInt(slider.style[positionProperty], 10)) || parseInt(slider.style[positionProperty], 10) === 0;

    const minValueChanged = (e: React.ChangeEvent) => {
        let val = (e.target as HTMLInputElement).value;

        if (!val || val.length === 0)
            val = "0";

        setMinValue(addSeparators(removeSeparators(val)));
        setUpdatesDisabled(false);

        setMinCursorPosition((e.target as HTMLInputElement).selectionStart);
    };

    const maxValueChanged = (e: React.ChangeEvent) => {
        let val = (e.target as HTMLInputElement).value;

        if (!val || val.length === 0)
            val = "0";

        setMaxValue(addSeparators(removeSeparators(val)));
        setUpdatesDisabled(false);

        setMaxCursorPosition((e.target as HTMLInputElement).selectionStart);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!updatesDisabled) {
                sendOnChange();
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [updatesDisabled, minValue, maxValue])

    useEffect(() => {
        if (leftSliderRef.current && removeSeparators(minValue) === startMin) {
            leftSliderRef.current.style.left = '0';
        }

        if (rightSliderRef.current && removeSeparators(maxValue) === startMax) {
            rightSliderRef.current.style.left = '0';
        }
    }, [minValue, maxValue]);

    const sendOnChange = () => {
        props.onChange({ min: removeSeparators(minValue).toString(), max: removeSeparators(maxValue).toString() }, props.label);
    }

    return <div className={props.className}>
        <div className='label'>
            {props.label}
        </div>
        <div className='slider' ref={rangeSlider}>
            <span className='slider-anchor left' onMouseDown={leftSliderClicked} ref={leftSliderRef}></span>
            <span className='slider-anchor right' onMouseDown={rightSliderClicked} ref={rightSliderRef}></span>
        </div>
        <div className='values'>
            <div className='min'>
                <span className='label'>{translate('RangeFrom')}:</span>
                <span className='value'>
                    <input ref={minInputRef} value={minValue} onChange={minValueChanged} />
                    <span className='unit'>{props.unit}</span>
                </span>
            </div>
            <div className='max'>
                <span className='label'>{translate('RangeTo')}:</span>
                <span className='value'>
                    <input ref={maxInputRef} value={maxValue} onChange={maxValueChanged} />
                    <span className='unit'>{props.unit}</span>
                </span>
            </div>
        </div>

    </div>
}

