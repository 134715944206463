import { Failed } from "../../utils/api/TactinApi";
import { DateUtils } from "../../utils/DateUtils";
import { toPropertyType } from "../Property";
import { AddOns, ElementList, Item, ItemCategory, ItemCategoryProperty, PropertyValue, SeriesAddOn } from "./ItemTypes";

export const ItemConverter = {
    toApi: (item: Partial<Item>): {} => {
        const result = {} as any;
        result.itemID = item.itemID || 0;
        if (item.name !== undefined) result.name = item.name;
        if (item.number !== undefined) result.number = item.number;
        if (item.description !== undefined) result.description = item.description;
        if (item.categoryID !== undefined) result.categoryID = item.categoryID;
        if (item.type !== undefined) result.type = item.type;
        if (item.createdBy !== undefined) result.createdBy = item.createdBy;
        if (item.createdAt !== undefined) result.createdAt = item.createdAt ? DateUtils.toJSONDate(item.createdAt) : null;
        if (item.modifiedBy !== undefined) result.modifiedBy = item.modifiedBy;
        if (item.modifiedAt !== undefined) result.modifiedAt = item.modifiedAt ? DateUtils.toJSONDate(item.modifiedAt) : null;
        if (item.template !== undefined) result.template = item.template;
        if (item.templateId !== undefined) result.templateId = item.templateId;
        if (item.deleted !== undefined) result.deleted = item.deleted;
        if (item.elementOwnerId !== undefined) result.elementOwnerId = item.elementOwnerId;
        if (item.version !== undefined) result.version = item.version;
        if (item.individualized !== undefined) result.individualized = item.individualized;

        if (item.properties !== undefined) {
            result.properties = {}
            for (const key in item.properties)
                if (Object.prototype.hasOwnProperty.call(item.properties, key) && !isNaN(+key))
                    result.properties[key] = PropertyValueConverter.toApi(item.properties[key]);
        }
        if (item.category)
            result.category = ItemCategoryConverter.toApi(item.category);
        if (item.elements)
            result.elements = item.elements.map(e => ElementListConverter.toApi(e));
        if (item.addOns)
            result.addOns = AddOnsConverter.toApi(item.addOns);

        return result;
    },
    fromApi: (item: any): Item => {
        const itemID = item.itemID || 0;
        const name = item.name || null;
        const number = item.number || null;
        const description = item.description || null;
        const showAs = item.showAs || null;
        const categoryID = item.categoryID || 0;
        const type = item.type || 0;
        const createdBy = item.createdBy || 0;
        const createdAt = item.createdAt ? DateUtils.fromJSONDate(String(item.createdAt)) : null;
        const modifiedBy = item.modifiedBy || 0;
        const modifiedAt = item.modifiedAt ? DateUtils.fromJSONDate(String(item.modifiedAt)) : null;
        const template = item.template || false;
        const templateId = item.templateId || null;
        const deleted = item.deleted || false;
        const elementOwnerId = item.elementOwnerId || null;
        const version = item.version || undefined;
        const individualized = item.individualized ?? true;

        const properties: { [propertyId: number]: PropertyValue } = {}
        if (item.properties) {
            for (const key in item.properties)
                if (Object.prototype.hasOwnProperty.call(item.properties, key) && !isNaN(+key))
                    properties[+key] = PropertyValueConverter.fromApi(item.properties[key]);
        }
        const category = ItemCategoryConverter.fromApi(item.category || {});
        const elements: ElementList[] = ((item.elements && item.elements instanceof Array) ? item.elements : [])
            .map((v: any) => ElementListConverter.fromApi(v));

        let addOns = undefined;
        if (item.addOns)
            addOns = AddOnsConverter.fromApi(item.addOns);

        return {
            itemID,
            name,
            number,
            description,
            showAs,
            categoryID,
            type,
            createdBy,
            createdAt,
            modifiedBy,
            modifiedAt,
            template,
            templateId,
            deleted,
            elementOwnerId,
            version,
            individualized,
            properties,
            category,
            elements,
            addOns
        }
    }
}

export const ItemCategoryConverter = {
    toApi: (category: ItemCategory): {} => {
        return {
            id: category.id,
            typeId: category.typeId,
            name: category.name,
            description: category.description,
            icon: category.icon,
            properties: category.properties.map(p => ItemCategoryPropertyConverter.toApi(p)),
            elementCategories: category.elementCategories
        } as any;
    },
    fromApi: (category: any): ItemCategory => {
        const id = category.id || 0;
        const typeId = category.typeId || 0;
        const name = category.name || null;
        const description = category.description || null;
        const icon = category.icon || null;
        const elementCategories = ((category.elementCategories && category.elementCategories instanceof Array) ? category.elementCategories : [])
            .filter((v: any) => !isNaN(+String(v))) as number[];
        const properties = ((category.properties && category.properties instanceof Array) ? category.properties : [])
            .map((v: any) => ItemCategoryPropertyConverter.fromApi(v)) as ItemCategoryProperty[];

        return {
            id,
            typeId,
            name,
            description,
            icon,
            properties,
            elementCategories
        }
    }
}

const ItemCategoryPropertyConverter = {
    toApi: (property: ItemCategoryProperty): {} => {
        return {
            id: property.id,
            name: property.name,
            description: property.description,
            dataType: property.dataType,
            configuration: property.configuration,
            defaultValue: property.defaultValue,
            displayLevel: property.displayLevel,
            displayLocation: property.displayLocation,
        } as any;
    },
    fromApi: (property: any): ItemCategoryProperty => {
        const id = property.id || 0;
        const name = property.name || null;
        const description = property.description || null;
        const dataType = toPropertyType(property.dataType) || 'TEXT';
        const configuration = property.configuration;
        const defaultValue = property.defaultValue || null;
        const displayLevel = property.displayLevel || 0;
        const displayLocation = property.displayLocation || 0;

        return {
            id,
            name,
            description,
            dataType,
            configuration,
            defaultValue,
            displayLevel,
            displayLocation
        }
    }
}

const PropertyValueConverter = {
    toApi: (propertyValue: PropertyValue): {} => {
        let value: any;
        if (propertyValue.type === 'DATE')
            value = DateUtils.toJSONDate(new Date(propertyValue.value));
        else if (propertyValue.type === 'DECIMAL')
            value = String(propertyValue.value);
        else
            value = propertyValue.value;

        return {
            type: propertyValue.type,
            value,
            showAs: propertyValue.showAs
        } as any;
    },
    fromApi: (propertyValue: any): PropertyValue => {
        const type = toPropertyType(propertyValue.type) || 'TEXT';
        let value: any;
        if (type === 'DATE')
            value = DateUtils.fromJSONDate(String(propertyValue.value));
        else if (type === 'DECIMAL')
            value = Number(propertyValue.value);
        else
            value = propertyValue.value;
        const showAs = propertyValue.showAs || '';

        return {
            type,
            value,
            showAs
        }
    }
}

const ElementListConverter = {
    toApi: (elementList: ElementList): {} => {
        return {
            isIndividualized: elementList.isIndividualized,
            categoryId: elementList.categoryId,
            elements: elementList.elements.map(e => ItemConverter.toApi(e))
        } as any;
    },
    fromApi: (elementList: any): ElementList => {
        const isIndividualized = elementList.isIndividualized ?? true;
        const categoryId = elementList.categoryId;
        const elements = ((elementList.elements && elementList.elements instanceof Array) ? elementList.elements : [])
            .map((v: any) => ItemConverter.fromApi(v)) as Item[];

        return {
            isIndividualized,
            categoryId,
            elements
        }
    }
}

const AddOnsConverter = {
    toApi: (addOns: AddOns): {} => {
        const result: any = {};
        if (addOns.user) result.user = { ...addOns.user }
        if (addOns.report) result.report = { ...addOns.report };
        if (addOns.series) result.series = SeriesAddOnConverter.toApi(addOns.series);
        if (addOns.series_instance) result.series_instance = { ...addOns.series_instance };

        return result;
    },
    fromApi: (addOns: any): AddOns => {
        const result: AddOns = {};
        if (addOns.user) {
            const userName: string = addOns.user.userName || '';
            const enabled: boolean = addOns.user.enabled ?? false;
            const languageId: number = addOns.user.languageId || 0;
            const userWildcards: string[] = toArray(addOns.user.userWildcards, v => String(v));
            const userAdmin = addOns.user.userAdmin ?? false;

            const permissions = toArray(addOns.user.permissions, v => Number(v)).sort((a, b) => a - b);

            result.user = { userName, enabled, languageId, userWildcards, userAdmin, permissions }
        }
        if (addOns.report) {
            const reportDefinitionId: number = addOns.report.reportDefinitionId || 0;
            const documentPath: string = addOns.report.documentPath || '';
            const servletUrl: string = addOns.report.servletUrl || '';
            const dataProvider: number = addOns.report.dataProvider || 0;
            const start: number = addOns.report.start || 0;
            const length: number = addOns.report.length || 0;
            const sort: string = addOns.report.sort || '';

            result.report = { reportDefinitionId, documentPath, servletUrl, dataProvider, start, length, sort };
        }
        if (addOns.series)
            result.series = SeriesAddOnConverter.fromApi(addOns.series);
        if (addOns.series_instance) {
            const seriesId: number = addOns.series_instance.seriesId || 0;
            const number: number = addOns.series_instance.number || 0;
            const realized: boolean = addOns.series_instance.realized || false;

            result.series_instance = { seriesId, number, realized };
        }


        return result;
    }
}

function toArray<T>(src: any, fn: (v: any) => T): T[] {
    if (src && src instanceof Array)
        return src.map((v: any) => fn(v));
    return [];
}

const SeriesAddOnConverter = {
    toApi: (series: SeriesAddOn): {} => {
        return {
            showAs: series.showAs,
            modifyConfig: JSON.stringify(SeriesConfigValidator.Modify(series.modifyConfig)),
            generationConfig: JSON.stringify(SeriesConfigValidator.Generation(series.generationConfig)),
            realizationConfig: JSON.stringify(SeriesConfigValidator.Realization(series.realizationConfig))
        };
    },
    fromApi: (series: any): SeriesAddOn => {
        const showAs: string = series.showAs || '';
        const modifyConfig: string = series.modifyConfig || '';
        const generationConfig: string = series.generationConfig || '';
        const realizationConfig: string = series.realizationConfig || '';

        const modify = modifyConfig ? JSON.parse(modifyConfig) : {};
        for (const fieldName of Object.getOwnPropertyNames(modify)) {
            let type: string = '';
            if (modify[fieldName].calculated)
                type = 'calculated';
            else if (modify[fieldName].interval)
                type = 'interval';
            if (type)
                modify[fieldName].type = type;
        }

        return {
            showAs,
            modifyConfig: modify,
            generationConfig: generationConfig ? JSON.parse(generationConfig) : {},
            realizationConfig: realizationConfig ? JSON.parse(realizationConfig) : {}
        };
    }
}

const SeriesConfigValidator = {
    Modify: (config: any): any => {
        const error = new Failed('Niepoprawna konfiguracja serii.');
        if (typeof config !== 'object') throw error;
        if (Object.getOwnPropertyNames(config).length === 0) throw error;

        for (const fieldName of Object.getOwnPropertyNames(config)) {
            try {
                config[fieldName] = validateModify(config[fieldName]);
            } catch (e) {
                throw error;
            }
        }
        return config;
    },
    Generation: (config: any): any => {
        try {
            const result: any = {};
            result.field = notEmpty(config.field);
            result.fieldConfig = { dateMode: notEmpty(config.fieldConfig.dateMode) };
            if (config.fieldConfig.dateMode === 'static')
                result.fieldConfig.staticDate = notEmpty(config.fieldConfig.staticDate);
            else if (config.fieldConfig.dateMode === 'dynamic') {
                result.fieldConfig.intervalType = notEmpty(config.fieldConfig.intervalType);
                result.fieldConfig.dateInterval = notEmpty(config.fieldConfig.dateInterval);
            }
            else
                throw 'Wrong date mode.'
            return result;
        } catch (e) {
            throw new Failed('Niepoprawna konfiguracja serii.');
        }
    },
    Realization: (config: any): any => {
        return config;
    }
}

function notEmpty(v: any) {
    if (v) return v;
    throw 'Value cannot be empty.';
}
function isArray(v: any) {
    if (v instanceof Array && v.length > 0) return v;
    throw 'Value must be a non-empty array.'
}
function validateModify(config: any) {
    const result: any = {};
    if (config.type === 'interval') {
        if (!config.baseValue) throw 'Missing baseValue.';
        result.baseValue = config.baseValue;

        const intervalCfg = notEmpty(config.interval);
        const type = notEmpty(intervalCfg.type);
        const subtype = notEmpty(config.interval?.subtype);
        if (subtype === 'after')
            result.interval = {
                interval: notEmpty(intervalCfg.interval),
                sourceField: intervalCfg.sourceField,
                defaultToday: intervalCfg.defaultToday
            }
        else if (type === 'day' && subtype === 'every')
            result.interval = {
                interval: notEmpty(intervalCfg.interval)
            }
        else if (type === 'day' && subtype === 'selected')
            result.interval = {
                weekDays: isArray(intervalCfg.weekDays)
            }
        else if (type === 'week' && subtype === 'every')
            result.interval = {
                interval: notEmpty(intervalCfg.interval),
                weekDay: notEmpty(intervalCfg.weekDay)
            }
        else if (type === 'week' && subtype === 'selected')
            result.interval = {
                weekNumbers: isArray(intervalCfg.weekNumbers),
                weekDay: notEmpty(intervalCfg.weekDay)
            }
        else if (type === 'month' && subtype === 'every')
            result.interval = {
                interval: notEmpty(intervalCfg.interval),
                dayOfMonth: notEmpty(intervalCfg.dayOfMonth)
            }
        else if (type === 'month' && subtype === 'selected')
            result.interval = {
                interval: notEmpty(intervalCfg.interval),
                weekDay: notEmpty(intervalCfg.weekDay),
                weekNumber: notEmpty(intervalCfg.weekNumber)
            }
        else if (type === 'year' && subtype === 'every')
            result.interval = {
                interval: notEmpty(intervalCfg.interval),
                dayOfMonth: notEmpty(intervalCfg.dayOfMonth),
                month: notEmpty(intervalCfg.month)
            }
        else if (type === 'year' && subtype === 'selected')
            result.interval = {
                interval: notEmpty(intervalCfg.interval),
                weekDay: notEmpty(intervalCfg.weekDay),
                weekNumber: notEmpty(intervalCfg.weekNumber),
                month: notEmpty(intervalCfg.month)
            }
        else throw 'Wrong type or subtype.';
    } else if (config.type === 'calculated') {
        if (!config.calculated?.expression) throw 'Missing expression in calculated.';
        if (config.calculated?.extended !== undefined && typeof config.calculated.extended !== 'boolean') throw 'Extended should be a boolean.';
        result.calculated = {
            expression: config.calculated.expression,
            extended: config.calculated?.extended
        }
    } else
        throw 'error';
    return result;
}
