import useRootStore from '../../stores/RootStore';
import { AdminApi } from './AdminApi';
import { TApiProvider } from './ApiTypes';
import { CategoryApi, DataProviderApi, ItemTypeApi, PermissionGroupApi, PropertyApi, ReportApi, TextTemplateApi } from './ConfigurationApi';
import { CngrApi } from './CngrApi';
import { FileRepositoryApi } from './FileRepositoryApi';
import { ItemApi } from './ItemApi';
import { LanguageApi } from './LanguageApi';
import { ListApi } from './ListApi';
import { SystemCommandApi } from './SystemCommandApi';
import { UserApi } from './UserApi';
import { UserInterfaceApi } from './UserInterfaceApi';

declare global {
    interface Window {
        tactinApiProvider: TApiProvider;
    }
}

export function api() {
    return window.tactinApiProvider;
}

export function initApiProvider(token: string) {
    window.tactinApiProvider = {
        UserInterface: UserInterfaceApi(token),
        List: ListApi(token),
        Item: ItemApi(token),
        FileRepository: FileRepositoryApi(token),
        User: UserApi(token),
        Admin: AdminApi(token),
        Language: LanguageApi(token),
        SystemCommand: SystemCommandApi(token),
        Cngr: CngrApi(token),
        Config: {
            PermissionGroup: PermissionGroupApi(token),
            Category: CategoryApi(token),
            DataProvider: DataProviderApi(token),
            ItemType: ItemTypeApi(token),
            TextTemplate: TextTemplateApi(token),
            Report: ReportApi(token),
            Property: PropertyApi(token)
        },
        useRootStore: () => useRootStore(token)
    }
}
