import React from "react";
import { ClientItem } from "../../model/item/ItemTypes";

type Props = {
    itemData: ClientItem;
    configuration?: any;
    notifyHandler?: (note: Notification) => void;
}

export default function PreviewInfo(props: Props) {
    return <div className="basic-info-preview">
                <header><h2>{props.itemData.item.name}</h2></header>
                <div className="preview-body" dangerouslySetInnerHTML={ { __html: props.itemData.item.description || ''}}></div>
            </div>
}