import api from "!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoLinkTag.js";
            import content from "!!../../../../node_modules/file-loader/dist/cjs.js??ref--5-1!./ListTable.css";

var options = {"injectType":"linkTag"};

options.insert = "head";

var update = api(content, options);



export default {}