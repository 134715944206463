import React, { useEffect, useState } from 'react';
import { DateControl } from '../../../../components/propertycontrols/DateControl';
import { ColumnFilter, DateColumnFilter, FilterRange, FilterType, getFilterValue, MultiselectComboColumnFilter, NumberColumnFilter, RangeColumnFilter } from '../../../../model/list/ColumnFilter';
import { MinMaxRange } from '../../../model/MinMaxResults';
import { MultiSelectValueListControl } from '../controls/MultiselectValueListControl';
import { NumberRangeControl } from '../controls/NumberRangeControl';
import { CustomFilterPropertyControlProps } from './CustomFilterPropertyControlProps';

export default function InterestDetailsFilters(props: CustomFilterPropertyControlProps) {
    let contractOrEmitingDateFilters = props.filters.filter(f => f.columnAlias.includes('Data zawarcia umowy lub emisji'));
    let yearFilter = props.filters.find(f => f.columnAlias === 'Rok') as RangeColumnFilter;
    let paymentDueToDateFilters = props.filters.filter(f => f.columnAlias.includes('Termin spłaty'));
    let securitiesAppliedFilter = props.filters.find(f => f.valueColumn === 'pv_103') as MultiselectComboColumnFilter;
    let financingPeriodFilter = props.filters.find(f => f.columnAlias === 'Okres finansowania') as RangeColumnFilter;
    let asociatedSubjectGuaranteeSecurity = props.filters.find(f => f.valueColumn === 'pv_78') as MultiselectComboColumnFilter;
    let guaranteeSecurityFilter = props.filters.find(f => f.valueColumn === 'pv_79') as MultiselectComboColumnFilter;
    let inBlancoBillSecurityFilter = props.filters.find(f => f.valueColumn === 'pv_80') as MultiselectComboColumnFilter;
    let executionStatementSecurityFilter = props.filters.find(f => f.valueColumn === 'pv_81') as MultiselectComboColumnFilter;
    let mortgageSecurityFilter = props.filters.find(f => f.valueColumn === 'pv_82') as MultiselectComboColumnFilter;
    let debtsTransferSecurityFilter = props.filters.find(f => f.valueColumn === 'pv_83') as MultiselectComboColumnFilter;
    let registeredPledgeSecurityFilter = props.filters.find(f => f.valueColumn === 'pv_84') as MultiselectComboColumnFilter;
    let fundsLockSecurity = props.filters.find(f => f.valueColumn === 'pv_85') as MultiselectComboColumnFilter;
    let balancePowerOfAttorneySecurityFilter = props.filters.find(f => f.valueColumn === 'pv_86') as MultiselectComboColumnFilter;
    let otherSecurityFilter = props.filters.find(f => f.valueColumn === 'pv_87') as MultiselectComboColumnFilter;
    let instrumentTypeFilter = props.filters.find(f => f.valueColumn === 'pv_29') as MultiselectComboColumnFilter;

    const [yearRanges, setYearRanges] = useState<MinMaxRange | undefined>();
    const [financingPeriodRanges, setFinancingPeriodRanges] = useState<MinMaxRange | undefined>();

    const [selectedSecuritiesApplied, setSelectedSecuritiesApplied] = useState<number[] | null>(null);

    const [dateFromValue, setDateFromValue] = useState<any>();
    const [dateToValue, setDateToValue] = useState<any>();

    const [yearValue, setYearValue] = useState<any>();

    const [paymentDateFromValue, setPaymentDateFromValue] = useState<any>();
    const [paymentDateToValue, setPaymentDateToValue] = useState<any>();
    const [financingPeriodValue, setFinancingPeriodValue] = useState<any>();
    const [securitiesAppliedValue, setSecuritiesAppliedValue] = useState<any>([]);
    const [asociatedSubjectGuaranteeSecurityValue, setAsociatedSubjectGuaranteeSecurityValue] = useState<any>([]);
    const [guaranteeSecurityValue, setGuaranteeSecurityValue] = useState<any>([]);
    const [inBlancoBillSecurityValue, setInBlancoBillSecurityValue] = useState<any>([]);
    const [executionStatementSecurityValue, setExecutionStatementSecurityFilterValue] = useState<any>([]);
    const [mortgageSecurityValue, setMortgageSecurityValue] = useState<any>([]);
    const [debtsTransferSecurityValue, setDebtsTransferSecurityValue] = useState<any>([]);
    const [registeredPledgeSecurityValue, setRegisteredPledgeSecurityValue] = useState<any>([]);
    const [fundsLockSecurityValue, setFundsLockSecurityValue] = useState<any>([]);
    const [balancePowerOfAttorneySecurityValue, setBalancePowerOfAttorneySecurityValue] = useState<any>([]);
    const [otherSecurityValue, setOtherSecurityValue] = useState<any>([]);
    const [instrumentTypeValue, setInstrumentTypeValue] = useState<any>([]);

    useEffect(() => {
        if (props.ranges && props.ranges.length > 0) {
            setYearRanges(props.ranges.find(r => r.propertyId === 91)?.range);
            setFinancingPeriodRanges(props.ranges.find(r => r.propertyId === 92)?.range);
        }
    }, [props.ranges]);

    useEffect(() => {
        if (yearRanges) {
            yearFilter.range = yearRanges as FilterRange;
            
            if (!yearValue) {
                setYearValue(yearRanges);
            }
        }

        if (financingPeriodRanges) {
            financingPeriodFilter.range = financingPeriodRanges as FilterRange;

            if (!financingPeriodValue) {
                setFinancingPeriodValue(financingPeriodRanges);
            }
        }
    }, [yearRanges, financingPeriodRanges]);


    const applyInitialFilters = (filters: ColumnFilter[]) => {
        if (filters) {
            const dateFilters = filters.filter(f => f.columnAlias.includes('Data zawarcia umowy lub emisji'));

            if (dateFilters && dateFilters.length > 0) {
                const dateFrom = dateFilters[0] as DateColumnFilter;
                const dateTo = dateFilters[1] as DateColumnFilter;
                (contractOrEmitingDateFilters[0] as DateColumnFilter).dateValue = dateFrom && dateFrom.dateValue ? new Date(dateFrom.dateValue) : null;
                (contractOrEmitingDateFilters[1] as DateColumnFilter).dateValue = dateTo && dateTo.dateValue ? new Date(dateTo.dateValue) : null;
                setDateFromValue(getFilterValue(contractOrEmitingDateFilters[0]));
                setDateToValue(getFilterValue(contractOrEmitingDateFilters[1]));
            }

            const paymentDateFilters = filters.filter(f => f.columnAlias.includes('Termin spłaty'));

            if (paymentDateFilters && paymentDateFilters.length > 0) {
                const paymentDateFrom = paymentDateFilters[0] as DateColumnFilter;
                const paymentDateTo = paymentDateFilters[1] as DateColumnFilter;
                (paymentDueToDateFilters[0] as DateColumnFilter).dateValue = paymentDateFrom && paymentDateFrom.dateValue ? new Date(paymentDateFrom.dateValue) : null;
                (paymentDueToDateFilters[1] as DateColumnFilter).dateValue = paymentDateTo && paymentDateTo.dateValue ? new Date(paymentDateTo.dateValue) : null;
                setPaymentDateFromValue(getFilterValue(paymentDueToDateFilters[0]));
                setPaymentDateToValue(getFilterValue(paymentDueToDateFilters[1]));
            }

            if (yearFilter) {
                const year = (filters.find(f => f.columnAlias === 'Rok') as RangeColumnFilter).range;
                yearFilter.range = year ? year : yearRanges as FilterRange;
                setYearValue(getFilterValue(yearFilter));
            }

            if (financingPeriodFilter) {
                const financingPeriod = (filters.find(f => f.columnAlias === 'Okres finansowania') as RangeColumnFilter).range;
                financingPeriodFilter.range = financingPeriod ? financingPeriod : financingPeriodRanges as FilterRange;
                setFinancingPeriodValue(getFilterValue(financingPeriodFilter));
            }

            const securitiesApplied = (filters.find(f => f.valueColumn === 'pv_103') as MultiselectComboColumnFilter).selected;

            if (securitiesApplied !== null && securitiesApplied !== undefined) {
                securitiesAppliedFilter.selected = securitiesApplied ? securitiesApplied : [1];
                setSecuritiesAppliedValue(getFilterValue(securitiesAppliedFilter));
                setSelectedSecuritiesApplied(securitiesApplied);
            } else {
                setSelectedSecuritiesApplied([]);
            }

            const asociatedSubjectGuaranteeSecurityVal = (filters.find(f => f.valueColumn === 'pv_78') as MultiselectComboColumnFilter).selected;

            if (asociatedSubjectGuaranteeSecurityVal !== null && asociatedSubjectGuaranteeSecurityVal !== undefined) {
                asociatedSubjectGuaranteeSecurity.selected = asociatedSubjectGuaranteeSecurityVal ? asociatedSubjectGuaranteeSecurityVal : [1];
                setAsociatedSubjectGuaranteeSecurityValue(getFilterValue(asociatedSubjectGuaranteeSecurity));
            } else {
                setAsociatedSubjectGuaranteeSecurityValue([]);
            }

            const guaranteeSecurity = (filters.find(f => f.valueColumn === 'pv_79') as MultiselectComboColumnFilter).selected;

            if (guaranteeSecurity !== null && guaranteeSecurity !== undefined) {
                guaranteeSecurityFilter.selected = guaranteeSecurity ? guaranteeSecurity : [1];
                setGuaranteeSecurityValue([getFilterValue(guaranteeSecurityFilter)]);
            } else {
                setGuaranteeSecurityValue([]);
            }

            const inBlancoBillSecurity = (filters.find(f => f.valueColumn === 'pv_80') as MultiselectComboColumnFilter).selected;

            if (inBlancoBillSecurity !== null && inBlancoBillSecurity !== undefined) {
                inBlancoBillSecurityFilter.selected = inBlancoBillSecurity ? inBlancoBillSecurity : [1];
                setInBlancoBillSecurityValue(getFilterValue(inBlancoBillSecurityFilter));
            } else {
                setInBlancoBillSecurityValue([]);
            }

            const executionStatementSecurity = (filters.find(f => f.valueColumn === 'pv_81') as MultiselectComboColumnFilter).selected;

            if (executionStatementSecurity !== null && executionStatementSecurity !== undefined) {
                executionStatementSecurityFilter.selected = executionStatementSecurity ? executionStatementSecurity : [1];
                setExecutionStatementSecurityFilterValue(getFilterValue(executionStatementSecurityFilter));
            } else {
                setExecutionStatementSecurityFilterValue([]);
            }

            const mortgageSecurity = (filters.find(f => f.valueColumn === 'pv_82') as MultiselectComboColumnFilter).selected;

            if (mortgageSecurity !== null && mortgageSecurity !== undefined) {
                mortgageSecurityFilter.selected = mortgageSecurity ? mortgageSecurity : [1];
                setMortgageSecurityValue(getFilterValue(mortgageSecurityFilter));
            } else {
                setMortgageSecurityValue([]);
            }

            const debtsTransferSecurity = (filters.find(f => f.valueColumn === 'pv_83') as MultiselectComboColumnFilter).selected;

            if (debtsTransferSecurity !== null && debtsTransferSecurity !== undefined) {
                debtsTransferSecurityFilter.selected = debtsTransferSecurity ? debtsTransferSecurity : [1];
                setDebtsTransferSecurityValue(getFilterValue(debtsTransferSecurityFilter));
            } else {
                setDebtsTransferSecurityValue([]);
            }

            const registeredPledgeSecurity = (filters.find(f => f.valueColumn === 'pv_84') as MultiselectComboColumnFilter).selected;

            if (registeredPledgeSecurity !== null && registeredPledgeSecurity !== undefined) {
                registeredPledgeSecurityFilter.selected = registeredPledgeSecurity ? registeredPledgeSecurity : [1];
                setRegisteredPledgeSecurityValue(getFilterValue(registeredPledgeSecurityFilter));
            } else {
                setRegisteredPledgeSecurityValue([]);
            }

            const fundsLockSecurityVal = (filters.find(f => f.valueColumn === 'pv_85') as MultiselectComboColumnFilter).selected;

            if (fundsLockSecurityVal !== null && fundsLockSecurityVal !== undefined) {
                fundsLockSecurity.selected = fundsLockSecurityVal ? fundsLockSecurityVal : [1];
                setFundsLockSecurityValue(getFilterValue(fundsLockSecurity));
            } else {
                setFundsLockSecurityValue([]);
            }

            const balancePowerOfAttorneySecurity = (filters.find(f => f.valueColumn === 'pv_86') as MultiselectComboColumnFilter).selected;

            if (balancePowerOfAttorneySecurity !== null && balancePowerOfAttorneySecurity !== undefined) {
                balancePowerOfAttorneySecurityFilter.selected = balancePowerOfAttorneySecurity ? balancePowerOfAttorneySecurity : [1];
                setBalancePowerOfAttorneySecurityValue(getFilterValue(balancePowerOfAttorneySecurityFilter));
            } else {
                setBalancePowerOfAttorneySecurityValue([]);
            }

            const otherSecurity = (filters.find(f => f.valueColumn === 'pv_87') as MultiselectComboColumnFilter).selected;

            if (otherSecurity !== null && otherSecurity !== undefined) {
                otherSecurityFilter.selected = otherSecurity ? otherSecurity : [1];
                setOtherSecurityValue(getFilterValue(otherSecurityFilter));
            } else {
                setOtherSecurityValue([]);
            }

            const instrumentType = (filters.find(f => f.valueColumn === 'pv_29') as MultiselectComboColumnFilter).selected;
            
            if (instrumentType !== null && instrumentType !== undefined) {
                instrumentTypeFilter.selected = instrumentType ? instrumentType : [1];
                setInstrumentTypeValue(getFilterValue(instrumentTypeFilter));
            } else {
                setInstrumentTypeValue([]);
            }
        }
    };

    useEffect(() => {
        if (props.savedFilters && props.applySavedFilters) {
            applyInitialFilters(props.savedFilters);
        } else if (props.filters && props.resetFilters) {
            applyInitialFilters(props.filters);
        }
    }, [props.filters]);

    const securitiesAppliedSelected = (value: number[]) => {
        setSelectedSecuritiesApplied(value);
        setSecuritiesAppliedValue(value)

        if (!value || !value.includes(2)) {
            if (asociatedSubjectGuaranteeSecurity) {
                asociatedSubjectGuaranteeSecurity.selected = null;
                setAsociatedSubjectGuaranteeSecurityValue(null);
            }

            if (guaranteeSecurityFilter) {
                guaranteeSecurityFilter.selected = null;
                setGuaranteeSecurityValue(null);
            }

            if (inBlancoBillSecurityFilter) {
                inBlancoBillSecurityFilter.selected = null;
                setInBlancoBillSecurityValue(null);
            }

            if (executionStatementSecurityFilter) {
                executionStatementSecurityFilter.selected = null;
                setExecutionStatementSecurityFilterValue(null);
            }

            if (mortgageSecurityFilter) {
                mortgageSecurityFilter.selected = null;
                setMortgageSecurityValue(null);
            }

            if (debtsTransferSecurityFilter) {
                debtsTransferSecurityFilter.selected = null;
                setDebtsTransferSecurityValue(null);
            }

            if (registeredPledgeSecurityFilter) {
                registeredPledgeSecurityFilter.selected = null;
                setRegisteredPledgeSecurityValue(null);
            }

            if (fundsLockSecurity) {
                fundsLockSecurity.selected = null;
                setFundsLockSecurityValue(null);
            }

            if (balancePowerOfAttorneySecurityFilter) {
                balancePowerOfAttorneySecurityFilter.selected = null;
                setBalancePowerOfAttorneySecurityValue(null);
            }

            if (otherSecurityFilter) {
                otherSecurityFilter.selected = null;
                setOtherSecurityValue(null);
            }
        }

        props.filterUpdated(securitiesAppliedFilter, value);
    }

    const dateFromUpdated = (value: any, showAs?: string) => {
        setDateFromValue(value);
        props.filterUpdated(contractOrEmitingDateFilters[0], value, showAs)
    }

    const dateToUpdated = (value: any, showAs?: string) => {
        setDateToValue(value);
        props.filterUpdated(contractOrEmitingDateFilters[1], value, showAs)
    }

    const paymentDateFromUpdated = (value: any, showAs?: string) => {
        setPaymentDateFromValue(value);
        props.filterUpdated(paymentDueToDateFilters[0], value, showAs)
    }

    const paymentToUpdated = (value: any, showAs?: string) => {
        setPaymentDateToValue(value);
        props.filterUpdated(paymentDueToDateFilters[1], value, showAs)
    }

    const financingPeriodUpdated = (value: FilterRange, showAs?: string) => {
        setFinancingPeriodValue(value);
        props.filterUpdated(financingPeriodFilter, value, showAs)
    }

    const yearUpdated = (value: FilterRange, showAs?: string) => {
        setYearValue(value);
        props.filterUpdated(yearFilter, value, showAs)
    }

    const guaranteeSecurityChanged = (value: number[]) => {
        setGuaranteeSecurityValue(value);
        props.filterUpdated(guaranteeSecurityFilter, value)
    }

    const asociatedSubjectGuaranteeSecurityChanged = (value: number[]) => {
        setAsociatedSubjectGuaranteeSecurityValue(value);
        props.filterUpdated(asociatedSubjectGuaranteeSecurity, value)
    }

    const inBlancoBillSecurityChanged = (value: number[]) => {
        setInBlancoBillSecurityValue(value);
        props.filterUpdated(inBlancoBillSecurityFilter, value)
    }

    const executionStatementSecurityChanged = (value: number[]) => {
        setExecutionStatementSecurityFilterValue(value);
        props.filterUpdated(executionStatementSecurityFilter, value)
    }

    const mortgageSecurityChanged = (value: number[]) => {
        setMortgageSecurityValue(value);
        props.filterUpdated(mortgageSecurityFilter, value)
    }

    const debtsTransferSecurityChanged = (value: number[]) => {
        setDebtsTransferSecurityValue(value);
        props.filterUpdated(debtsTransferSecurityFilter, value)
    }

    const registeredPledgeSecurityChanged = (value: number[]) => {
        setRegisteredPledgeSecurityValue(value);
        props.filterUpdated(registeredPledgeSecurityFilter, value)
    }

    const fundsLockSecurityChanged = (value: number[]) => {
        setFundsLockSecurityValue(value);
        props.filterUpdated(fundsLockSecurity, value)
    }

    const balancePowerOfAttorneySecurityChanged = (value: number[]) => {
        setBalancePowerOfAttorneySecurityValue(value);
        props.filterUpdated(balancePowerOfAttorneySecurityFilter, value)
    }

    const otherSecurityChanged = (value: number[]) => {
        setOtherSecurityValue(value);
        props.filterUpdated(otherSecurityFilter, value)
    }

    const instrumentTypeChanged = (value: number[]) => {
        setInstrumentTypeValue(value);
        props.filterUpdated(instrumentTypeFilter, value)
    }

    if (financingPeriodFilter) {
        financingPeriodFilter.filterType = 'rangeFilter';
        financingPeriodFilter.useColumnName = false;
    }

    if (yearFilter) {
        yearFilter.filterType = 'rangeFilter';
        yearFilter.useColumnName = false;
    }

    const updateFilterTypeToFilterGroup = (filterType: FilterType, filters: ColumnFilter[]) => {
        filters.forEach(filter => {
            filter.filterType = filterType;
        });
    }

    updateFilterTypeToFilterGroup('multiselectComboFilter', 
        [securitiesAppliedFilter, guaranteeSecurityFilter, asociatedSubjectGuaranteeSecurity, inBlancoBillSecurityFilter,
        executionStatementSecurityFilter, mortgageSecurityFilter, debtsTransferSecurityFilter, registeredPledgeSecurityFilter,
        fundsLockSecurity, balancePowerOfAttorneySecurityFilter, otherSecurityFilter, instrumentTypeFilter]);

    return <div className='filter-interest-details'>
        <>
            {contractOrEmitingDateFilters && contractOrEmitingDateFilters[0] && <DateControl
                value={dateFromValue}
                showAs={contractOrEmitingDateFilters[0].valueShowAs}
                label={contractOrEmitingDateFilters[0].showAs}
                className='filter'
                configuration={{ format: contractOrEmitingDateFilters[0].format }}
                onChange={dateFromUpdated} />}

            {contractOrEmitingDateFilters && contractOrEmitingDateFilters[1] && <DateControl
                value={dateToValue}
                showAs={contractOrEmitingDateFilters[1].valueShowAs}
                label={contractOrEmitingDateFilters[1].showAs}
                className='filter'
                configuration={{ format: contractOrEmitingDateFilters[1].format }}
                onChange={dateToUpdated} />}

            {yearFilter && yearValue && yearRanges && <NumberRangeControl
                label={yearFilter.showAs}
                unit=""
                className="filter range"
                onChange={yearUpdated}
                range={yearValue}
                startMin={parseInt(yearRanges.min)}
                startMax={parseInt(yearRanges.max)}
                step={1}
                precision={0}
                updatesDisabledOnInit={true} />}

            {paymentDueToDateFilters && paymentDueToDateFilters[0] && <DateControl
                value={paymentDateFromValue}
                showAs={paymentDueToDateFilters[0].valueShowAs}
                label={paymentDueToDateFilters[0].showAs}
                className='filter'
                configuration={{ format: paymentDueToDateFilters[0].format }}
                onChange={paymentDateFromUpdated} />}

            {paymentDueToDateFilters && paymentDueToDateFilters[1] && <DateControl
                value={paymentDateToValue}
                showAs={paymentDueToDateFilters[1].valueShowAs}
                label={paymentDueToDateFilters[1].showAs}
                className='filter'
                configuration={{ format: paymentDueToDateFilters[1].format }}
                onChange={paymentToUpdated} />}

            {financingPeriodFilter && financingPeriodRanges && financingPeriodValue && <NumberRangeControl
                label={financingPeriodFilter.showAs}
                unit=""
                className="filter range"
                onChange={financingPeriodUpdated}
                range={financingPeriodValue}
                startMin={parseInt(financingPeriodRanges.min)}
                startMax={parseInt(financingPeriodRanges.max) }
                updatesDisabledOnInit={true} />}
        </>
        {securitiesAppliedFilter && <>
            <MultiSelectValueListControl
                value={securitiesAppliedValue}
                showAs={securitiesAppliedFilter.valueShowAs}
                label={securitiesAppliedFilter.showAs}
                className='filter'
                configuration={{ values: securitiesAppliedFilter.values }}
                onChange={(value: any[]) => securitiesAppliedSelected(value)} />

            {selectedSecuritiesApplied && selectedSecuritiesApplied.indexOf(1) > -1 &&
                <>
                    <MultiSelectValueListControl
                        value={guaranteeSecurityValue || []}
                        showAs={guaranteeSecurityFilter.valueShowAs}
                        label={guaranteeSecurityFilter.showAs}
                        className='filter multiselect'
                        configuration={{ values: guaranteeSecurityFilter.values }}
                        onChange={guaranteeSecurityChanged} />

                    <MultiSelectValueListControl
                        value={asociatedSubjectGuaranteeSecurityValue}
                        showAs={asociatedSubjectGuaranteeSecurity.valueShowAs}
                        label={asociatedSubjectGuaranteeSecurity.showAs}
                        className='filter'
                        configuration={{ values: asociatedSubjectGuaranteeSecurity.values }}
                        onChange={asociatedSubjectGuaranteeSecurityChanged} />

                    <MultiSelectValueListControl
                        value={inBlancoBillSecurityValue}
                        showAs={inBlancoBillSecurityFilter.valueShowAs}
                        label={inBlancoBillSecurityFilter.showAs}
                        className='filter'
                        configuration={{ values: inBlancoBillSecurityFilter.values }}
                        onChange={inBlancoBillSecurityChanged} />

                    <MultiSelectValueListControl
                        value={executionStatementSecurityValue}
                        showAs={executionStatementSecurityFilter.valueShowAs}
                        label={executionStatementSecurityFilter.showAs}
                        className='filter'
                        configuration={{ values: executionStatementSecurityFilter.values }}
                        onChange={executionStatementSecurityChanged} />

                    <MultiSelectValueListControl
                        value={mortgageSecurityValue}
                        showAs={mortgageSecurityFilter.valueShowAs}
                        label={mortgageSecurityFilter.showAs}
                        className='filter'
                        configuration={{ values: mortgageSecurityFilter.values }}
                        onChange={mortgageSecurityChanged} />

                    <MultiSelectValueListControl
                        value={debtsTransferSecurityValue}
                        showAs={debtsTransferSecurityFilter.valueShowAs}
                        label={debtsTransferSecurityFilter.showAs}
                        className='filter'
                        configuration={{ values: debtsTransferSecurityFilter.values }}
                        onChange={debtsTransferSecurityChanged} />

                    <MultiSelectValueListControl
                        value={registeredPledgeSecurityValue}
                        showAs={registeredPledgeSecurityFilter.valueShowAs}
                        label={registeredPledgeSecurityFilter.showAs}
                        className='filter'
                        configuration={{ values: registeredPledgeSecurityFilter.values }}
                        onChange={registeredPledgeSecurityChanged} />

                    <MultiSelectValueListControl
                        value={fundsLockSecurityValue}
                        showAs={fundsLockSecurity.valueShowAs}
                        label={fundsLockSecurity.showAs}
                        className='filter'
                        configuration={{ values: fundsLockSecurity.values }}
                        onChange={fundsLockSecurityChanged} />

                    <MultiSelectValueListControl
                        value={balancePowerOfAttorneySecurityValue}
                        showAs={balancePowerOfAttorneySecurityFilter.valueShowAs}
                        label={balancePowerOfAttorneySecurityFilter.showAs}
                        className='filter'
                        configuration={{ values: balancePowerOfAttorneySecurityFilter.values }}
                        onChange={balancePowerOfAttorneySecurityChanged} />

                    <MultiSelectValueListControl
                        value={otherSecurityValue}
                        showAs={otherSecurityFilter.valueShowAs}
                        label={otherSecurityFilter.showAs}
                        className='filter'
                        configuration={{ values: otherSecurityFilter.values }}
                        onChange={otherSecurityChanged} />

                </>
            }
        </>
        }
        <MultiSelectValueListControl
            value={instrumentTypeValue}
            showAs={instrumentTypeFilter.valueShowAs}
            label={instrumentTypeFilter.showAs}
            className="filter"
            configuration={{ values: instrumentTypeFilter.values }}
            onChange={instrumentTypeChanged} />
    </div>
}
