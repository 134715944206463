import React from 'react';
import { ShowMessageEvent } from '../../../model/events/TactinEvents';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { api } from '../../../utils/api/ApiProvider';
import { tactin } from '../../../utils/TactinGlobals';
import { PopupUI } from '../../core/UIFactory';
import './ChangePasswordPopup.css';

export function ChangePasswordUI(itemId?: number): PopupUI {
    const tr = tactin().configuration.translate;
    const userId = itemId ?? tactin().login.loggedUser().itemID;

    const valid = (state: any) => (state.password ?? '').length > 0 && state.password === state.repeat;

    return {
        title: tr('Zmień hasło'),
        buttons: [[tr('Anuluj'), ''], [tr('Zmień'), 'ok']],
        control: props => {
            return <div className='password-change-form'>
                <PwdChangeRow label={tr('Hasło')} disabled={props.processing}
                    value={props.state.password || ''}
                    onChange={v => props.setState({ ...props.state, password: v })} />
                <PwdChangeRow label={tr('Powtórz hasło')} disabled={props.processing}
                    value={props.state.repeat || ''}
                    onChange={v => props.setState({ ...props.state, repeat: v })}
                    valid={() => valid(props.state)} />
            </div>
        },
        onClose: async (signal, state) => {
            if (signal === 'ok') {
                if (valid(state)) {
                    return api().User.changePassword(userId, state.password).then(s => {
                        if (s)
                            return true;
                        else
                            tactin()?.eventBus.notify(new ShowMessageEvent('Nieudana próba zmiany hasła.'));
                        return false;
                    }).catch(HandleErrors());
                } else
                    return false;
            } else
                return true;
        }
    }
}

type PwdChangeRowProps = {
    label: string;
    value: string;
    onChange: (v: string) => void;
    valid?: () => boolean;
    disabled?: boolean;
}
function PwdChangeRow(props: PwdChangeRowProps) {
    const validClass = props.valid ? (props.valid() ? 'valid' : 'invalid') : '';
    return <div className='password-change-row'>
        <span>{props.label}</span>
        <input type="password" disabled={props.disabled} value={props.value} onChange={e => props.onChange(e.currentTarget.value)} />
        <div className={`validation ${validClass}`}></div>
    </div>
}
