import React, { useEffect, useRef, useState } from 'react';
import { createNewItem } from '../../model/item/Item';
import { ClientItem, Item, ItemCategory, ItemCategoryProperty, PropertyValue } from '../../model/item/ItemTypes';
import { ActionContextProvider } from '../../model/systemactions/ActionContext';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { DateUtils } from '../../utils/DateUtils';
import { tactin } from '../../utils/TactinGlobals';
import { BareBitControl } from '../propertycontrols/BitControl';
import { BareDateControl } from '../propertycontrols/DateControl';
import { BareDecimalControl } from '../propertycontrols/DecimalControl';
import { BareIntegerControl } from '../propertycontrols/IntegerControl';
import { BareItemToItemControl } from '../propertycontrols/ItemToItemControl';
import { BareLinkControl } from '../propertycontrols/LinkControl';
import { BarePropertyControlProps } from '../propertycontrols/PropertyControl';
import { BareTextControl } from '../propertycontrols/TextControl';
import { BareValueListControl } from '../propertycontrols/ValueListControl';
import { Notification } from '../systemactions/SystemAction';
import { CardSubPanelWrapper } from './CardSubPanelWrapper';
import './ElementList.css';

type Props = {
    itemData: ClientItem;
    configuration: any;
    onChange: (item: Item) => void;
    contextProvider?: ActionContextProvider;
    notifyHandler?: (note: Notification) => void;
    readonly?: boolean;
}

export default function ElementList(props: Props) {
    const categoryId: number = props.configuration.elementCategoryId ?? 0;
    const elementList = props.itemData.item.elements.find(el => el.categoryId === categoryId);

    const displayLevel: number = props.configuration.displayLevel ?? 0;

    const change = (elements: Item[]) =>
        props.onChange({
            ...props.itemData.item,
            elements: props.itemData.item.elements.map(el =>
                el.categoryId !== categoryId ? el : {
                    categoryId,
                    elements,
                    isIndividualized: true
                }
            )
        });

    const toolbarDefaults: [string, any][] = (props.itemData.item.individualized || !elementList)
        ? []
        : (elementList.isIndividualized
            ? [['restoreSharedItem', { categoryId: elementList.categoryId }]]
            : [['individualizeItem', { categoryId: elementList.categoryId }]]);

    return <CardSubPanelWrapper
        toolbarDefaults={toolbarDefaults}
        configuration={props.configuration}
        contextProvider={props.contextProvider}
        notificationHandler={props.notifyHandler} >
        {elementList
            ? <ElementListTable
                ownerId={props.itemData.item.itemID}
                categoryId={categoryId}
                displayLevel={displayLevel}
                elements={elementList.elements}
                onChange={change}
                readonly={props.readonly || !elementList.isIndividualized} />
            : null}
    </CardSubPanelWrapper>
}

type ElementListTableProps = {
    ownerId: number;
    categoryId: number;
    displayLevel: number;
    elements: Item[];
    onChange: (v: Item[]) => void;
    readonly: boolean;
}
export function ElementListTable(props: ElementListTableProps) {
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [sortFn, setSortFn] = useState<SortFn>(() => idSortFn);

    const getCategory = () => {
        if (props.elements.length > 0)
            return props.elements[0].category;
        else if (props.categoryId > 0)
            api().Item.getCategory(props.categoryId).then(ci =>
                setCategory(ci)).catch(HandleErrors());
        return null;
    }
    const [category, setCategory] = useState<ItemCategory | null>(getCategory)

    if (!props.categoryId)
        return <div>Błąd konfiguracji!</div>;
    else if (!category)
        return <div>Ładowanie danych...</div>;

    const displayLevelFilter = () => {
        if (props.displayLevel)
            return (icp: ItemCategoryProperty) => icp.displayLevel === props.displayLevel;
        else
            return () => true;
    }

    const columns = category.properties.filter(displayLevelFilter())
        .sort((a, b) => a.displayLocation - b.displayLocation);
    const elements = props.elements.filter(i => !i.deleted).sort(sortFn);

    const selectOther = () => {
        const selectedIndex = elements.findIndex(i => i.itemID === selectedRow);
        if (selectedIndex === -1)
            return;
        if (selectedIndex < elements.length - 1)   //there are more elements, select next
            setSelectedRow(elements[selectedIndex + 1].itemID);
        else if (selectedIndex === 0)    //the only row
            setSelectedRow(null);
        else                            //last element, select previous one
            setSelectedRow(elements[selectedIndex - 1].itemID);
    }
    const selectNext = () => {
        const selectedIndex = elements.findIndex(i => i.itemID === selectedRow);
        if (selectedIndex === -1)
            return null;
        if (selectedIndex < elements.length - 1)   //there are more elements, select next
            setSelectedRow(elements[selectedIndex + 1].itemID);
        else                            //last element, create new one
            return createNew();
        return null;
    }

    const createNew = () => {
        if (!category)
            return null;
        const newElement = createNewItem(category, props.ownerId);
        setSelectedRow(newElement.item.itemID);
        return newElement.item;
    }
    const remove = (itemId: number) => {
        if (itemId === selectedRow)
            selectOther();
        if (itemId <= 0)
            props.onChange(props.elements.filter(e => e.itemID !== itemId));
        else
            props.onChange(props.elements.map(e =>
                e.itemID !== itemId ? e : { ...e, deleted: true }));
    }
    const update = (item: Item, moveNext?: boolean) => {
        if (props.readonly)
            return;
        const newElementList = props.elements.map(e => e.itemID !== item.itemID ? e : item);
        if (moveNext) {
            const created = selectNext();
            if (created)
                newElementList.push(created);
        }
        else if (item.itemID === selectedRow)
            setSelectedRow(null);

        props.onChange(newElementList);
    }

    const addNewRowClick = () => {
        const created = createNew();
        if (created)
            props.onChange([...props.elements, created]);
    }

    return <div className="element-list-table">
        <HeaderRow columns={columns} onSort={s => setSortFn(() => s || idSortFn)} readonly={props.readonly} />
        <div className='data'>
            {elements.map(i =>
                <ItemRow key={i.itemID} item={i} columns={columns}
                    selected={i.itemID === selectedRow}
                    onSelect={() => setSelectedRow(i.itemID)}
                    onUpdate={update}
                    onCancel={() => setSelectedRow(null)}
                    onDelete={() => remove(i.itemID)}
                    readonly={props.readonly} />)}
            {props.readonly ? null : <button onClick={addNewRowClick}>{tactin().configuration.translate('Dodaj nowy element')}</button>}
        </div>
    </div>;
}

type HeaderRowProps = {
    columns: ItemCategoryProperty[];
    onSort: (sortFn: SortFn | null) => void;
    readonly: boolean;
}
function HeaderRow({ columns, onSort, readonly }: HeaderRowProps) {
    const [sortOrder, setSortOrder] = useState<[number, boolean] | null>(null); //[prpId, isAscending]
    const [translatedColumns, setTranslations] = useState<string[]>(columns.map(columnName => {
            return tactin().configuration.translate(columnName.name)}));

    const sort = (prp: ItemCategoryProperty) => {
        setSortOrder(p => {
            if (p && p[0] === prp.id)
                return [p[0], !p[1]];
            return [prp.id, true];
        });
    }

    useEffect(() => {
        if (sortOrder === null)
            return onSort(null);
        const prp = columns.find(p => p.id === sortOrder[0]);
        if (!prp) {
            setSortOrder(null);
            return;
        }
        const sortFn = getSortFunction(prp);
        if (sortOrder[1])
            onSort(sortFn);
        else
            onSort(reverse(sortFn));
    }, [sortOrder])

    const sortText = (prpId: number, columnName: string) => {
        if (sortOrder && sortOrder[0] === prpId)
            return `[${sortOrder[1] ? '^' : 'v'}] ${columnName}`;
        return columnName;
    }

    const headerColumnClass =  (prpId: number) => {
        if (sortOrder && sortOrder[0] === prpId)
            return sortOrder[1] ? 'sort-asc' : 'sort-desc';
        else
            return '';
    }

    return (
        <div className='row header'>
            {columns.map((prp, i) =>
                <button key={prp.id}
                    onClick={() => sort(prp)}
                    onDoubleClick={() => setSortOrder(null)} 
                    className = {headerColumnClass(prp.id)}>
                    {translatedColumns[i]/*sortText(prp.id, translatedColumns[i])*/}
                </button>)}
            {!readonly ? <div className='removeColumn'> </div> : null}
        </div>);
}

type SortFn = (a: Item, b: Item) => number;
function getSortFunction(prp: ItemCategoryProperty): SortFn {
    let comparer: (a: PropertyValue, b: PropertyValue) => number;
    switch (prp.dataType) {
        case 'BIT':
            comparer = (a, b) => (a.value ? 0 : 1) - (b.value ? 0 : 1);
            break;
        case 'DATE':
            comparer = (a, b) => (a.value as Date).getTime() - (b.value as Date).getTime();
            break;
        case 'DECIMAL':
        case 'INTEGER':
            comparer = (a, b) => Number(a.value) - Number(b.value);
            break;
        case 'FILELINK':
        case 'IMAGE':
        case 'ITEM2ITEM':
        case 'VALUELIST':
            comparer = (a, b) => {
                const showAsCmp = (a.showAs || '').localeCompare(b.showAs || '');
                if (showAsCmp)
                    return showAsCmp;
                return Number(a.value) - Number(b.value);
            }
            break;
        case 'RICHTEXT':
        case 'TEXT':
        default:
            comparer = (a, b) => String(a.value).localeCompare(String(b.value));
    }
    return (a, b) => {
        const valA = a.properties[prp.id];
        const valB = b.properties[prp.id];
        if (isEmpty(valA) && isEmpty(valB))
            return 0;
        else if (isEmpty(valA))
            return -1;
        else if (isEmpty(valB))
            return 1;
        else
            return comparer(valA, valB);
    }
}
function reverse(fn: SortFn): SortFn {
    return (a, b) => -fn(a, b);
}
function isEmpty(pv?: PropertyValue): boolean {
    return pv === undefined || pv.value === undefined || pv.value === null;
}
function idSortFn(a: Item, b: Item): number {
    if (a.itemID > 0 && b.itemID > 0)
        return a.itemID - b.itemID;
    else if (a.itemID > 0)
        return -1;
    else if (b.itemID > 0)
        return 1;
    else
        return b.itemID - a.itemID;
}

type ItemRowProps = {
    item: Item;
    columns: ItemCategoryProperty[];
    selected: boolean;
    onSelect: () => void;
    onUpdate: (item: Item, moveNext?: boolean) => void;
    onCancel: () => void;
    onDelete: () => void;
    readonly: boolean;
}
function ItemRow(props: ItemRowProps) {
    const [actualItem, setActualItem] = useState<Item>(props.item);
    const rowRef = useRef<HTMLDivElement>(null);

    const change = (pv: PropertyValue, pvId: number) => {
        setActualItem(i => ({ ...i, properties: { ...i.properties, [pvId]: pv } }));
    }
    const onKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.target !== rowRef.current)
            return;
        if (e.key === 'Enter')
            props.onUpdate(actualItem, true);
        else if (e.key === 'Escape')
            cancel();
        else if (e.key === 'Delete')
            props.onDelete();
    }
    const cancel = () => {
        props.onCancel();
        setActualItem(props.item);
    }

    useEffect(() => {
        setActualItem(props.item);
    }, [props.item]);

    useEffect(() => {
        if (!props.selected && actualItem != props.item)
            props.onUpdate(actualItem);
        else if (props.selected) {
            rowRef.current?.focus();
            const handler = (e: FocusEvent) => {
                if (e.target !== rowRef.current
                    && rowRef.current?.contains(e.target as HTMLElement)
                    && (e.relatedTarget === null || !rowRef.current?.contains(e.relatedTarget as HTMLElement)))
                    rowRef.current.focus();
                else if (e.target === rowRef.current && (e.relatedTarget === null || !rowRef.current?.contains(e.relatedTarget as HTMLElement)))
                    props.onUpdate(actualItem);
            }
            document.addEventListener('focusout', handler);
            return () => document.removeEventListener('focusout', handler);
        }
    }, [props.selected])

    return (
        <div className={`row ${props.selected ? 'selected' : ''}`}
            ref={rowRef}
            tabIndex={0}
            onClick={() => props.onSelect()}
            onKeyDown={onKey}>
            { props.columns.map(col =>
                <ItemCell key={col.id}
                    editMode={props.selected}
                    property={actualItem.properties[col.id]}
                    categoryProperty={col}
                    onChange={pv => change(pv, col.id)}
                    readonly={props.readonly} />)}
            {!props.readonly
                ? <div className='removeColumn'> 
                    <button onClick={() => props.onDelete()}>{props.readonly ? ' ' : '×'}</button>
                  </div>
                : null}
        </div>);
}

type ItemCellProps = {
    property?: PropertyValue;
    categoryProperty: ItemCategoryProperty;
    editMode: boolean;
    onChange: (property: PropertyValue) => void;
    readonly: boolean;
}
function ItemCell(props: ItemCellProps) {
    let value;
    if (!props.property || props.property.value === null)
        value = '';
    else {
        switch (props.property.type) {
            case 'FILELINK':
            case 'IMAGE':
            case 'ITEM2ITEM':
            case 'ITEM2MANYITEMS':
            case 'VALUELIST':
                if (props.property.showAs !== undefined)
                    value = props.property.showAs;
                else
                    value = `ID:${props.property.value}`;
                break;
            case 'DATE':
                value = DateUtils.formatDate(props.property.value,
                    props.categoryProperty.configuration.format);
                break;
            case 'BIT':
                const trueStr = props.categoryProperty.configuration?.true || 'tak';
                const falseStr = props.categoryProperty.configuration?.false || 'nie';
                if (props.property.value)
                    value = trueStr;
                else
                    value = falseStr;
                break;
            case 'DECIMAL':
                const format = Number(props.categoryProperty.configuration?.format ?? 4);
                value = Number(props.property.value).toFixed(format);
                break;
            default:
                value = String(props.property.value);
        }
    }

    const editControl = (): JSX.Element | null => {
        const type = props.property?.type || props.categoryProperty.dataType;
        let Control: ((props: BarePropertyControlProps) => JSX.Element) | null = null;
        switch (type) {
            case 'BIT': Control = BareBitControl; break;
            case 'DATE': Control = BareDateControl; break;
            case 'DECIMAL': Control = BareDecimalControl; break;
            case 'FILELINK': Control = BareLinkControl; break;
            case 'IMAGE': Control = BareLinkControl; break;
            case 'INTEGER': Control = BareIntegerControl; break;
            case 'ITEM2ITEM': Control = BareItemToItemControl; break;
            case 'TEXT': Control = BareTextControl; break;
            case 'VALUELIST': Control = BareValueListControl; break;
        }
        if (Control) {
            return <Control
                className='inline-edit'
                value={props.property?.value ?? null}
                showAs={props.property?.showAs || ''}
                configuration={props.categoryProperty.configuration}
                onChange={change}
                readOnly={props.readonly} />
        }
        return null;
    }

    const change = (value: any, showAs?: string) => {
        if (props.property)
            props.onChange({ ...props.property, value, showAs });
        else
            props.onChange({
                type: props.categoryProperty.dataType,
                value,
                showAs
            })
    }

    const noEdit = props.categoryProperty.configuration?.noEdit ?? false;

    const classType = props.property?.type.toLocaleLowerCase() ?? '';

    return <div className={`cell${classType ? ' ' + classType : ''}`}>
        {(props.editMode && !noEdit)
            ? editControl()
            : <span>{value}</span>}
    </div>;
}
