import React, { useEffect, useState } from 'react';
import { ValueListControl } from '../../../../components/propertycontrols/ValueListControl';
import { ColumnFilter, ComboColumnFilter, FilterRange, getFilterValue, MultiselectComboColumnFilter, RangeColumnFilter } from '../../../../model/list/ColumnFilter';
import { MinMaxRange } from '../../../model/MinMaxResults';
import { MultiSelectValueListControl } from '../controls/MultiselectValueListControl';
import { NumberRangeControl } from '../controls/NumberRangeControl';
import { CustomFilterPropertyControlProps } from './CustomFilterPropertyControlProps';

type InterestRateFiltersProps = {
    isForMarginRatesAnalysis: boolean;
} & CustomFilterPropertyControlProps;

export default function InterestRateFilters(props: InterestRateFiltersProps) {
    const interestTypeFilter = props.filters.find(f => f.columnAlias === 'Rodzaj oprocentowania') as ComboColumnFilter;
    const interestRateFilter = props.filters.find(f => f.valueColumn === '"ins_p_31"') as RangeColumnFilter;
    const marginFilter = props.filters.find(f => f.columnAlias === 'Marża') as RangeColumnFilter;
    const variableInterestTypeFilter = props.filters.find(f => f.columnAlias === 'Stopa bazowa') as MultiselectComboColumnFilter;

    const [interestRateRanges, setInterestRateRanges] = useState<MinMaxRange | undefined>();
    const [marginRanges, setMarginRanges] = useState<MinMaxRange | undefined>();

    const [selectedInterestType, setSelectedInterestType] = useState<number | null>(interestTypeFilter?.selected)

    const [interestTypeFilterValue, setInterestTypeFilterValue] = useState<ComboColumnFilter>(getFilterValue(interestTypeFilter));
    const [interestRateValue, setInterestRateValue] = useState<FilterRange>();
    const [marginValue, setMarginValue] = useState<FilterRange>();
    const [variableInterestTypeFilterValue, setVariableInterestTypeFilterValue] = useState<number[]>([]);

    const applyInitialFilters = (filters: ColumnFilter[]) => {
        if (filters) {
            if (!selectedInterestType || props.resetFilters) {
                interestTypeFilter.selected = (filters.find(f => f.columnAlias === 'Rodzaj oprocentowania') as ComboColumnFilter).selected;
            } else {
                interestTypeFilter.selected = selectedInterestType;
            }

            setInterestTypeFilterValue(getFilterValue(interestTypeFilter));

            const interestRateRange = (filters.find(f => f.valueColumn === '"ins_p_31"') as RangeColumnFilter).range;
            
            if (interestRateRange) {
                interestRateFilter.range = interestRateRange;
            }

            setInterestRateValue(interestRateRange || interestRateRanges);

            const marginRange = (filters.find(f => f.columnAlias === 'Marża') as RangeColumnFilter).range;
            
            if (marginRange) {
                marginFilter.range = marginRange;   
            }

            setMarginValue(marginRange || marginRanges);

            variableInterestTypeFilter.selected = (filters.find(f => f.columnAlias === 'Stopa bazowa') as MultiselectComboColumnFilter).selected;
            setVariableInterestTypeFilterValue(getFilterValue(variableInterestTypeFilter));

            setSelectedInterestType(interestTypeFilter.selected);
        }
    };

    useEffect(() => {
        if (props.savedFilters && props.applySavedFilters) {
            applyInitialFilters(props.savedFilters);
        } else if (props.filters && props.resetFilters) {
            applyInitialFilters(props.filters);
        }
    }, [props.filters]);

    useEffect(() => {
        if (props.ranges && props.ranges.length > 0) {
            setInterestRateRanges(props.ranges.find(r => r.propertyId === 31)?.range);
            setMarginRanges(props.ranges.find(r => r.propertyId === 39)?.range);
        }
    }, [props.ranges]);

    useEffect(() => {
        if (selectedInterestType) {
            if (interestRateRanges) {
                if (selectedInterestType === 1 && !interestRateFilter.range) {
                    interestRateFilter.range = interestRateRanges as FilterRange;

                    if (!interestRateValue) {
                        setInterestRateValue(interestRateRanges);
                    }
                }
            }
    
            if (marginRanges) {
                if (selectedInterestType === 2 && !marginFilter.range) {
                    marginFilter.range = marginRanges as FilterRange;

                    if (!marginValue) {
                        setMarginValue(marginRanges);
                    }
                }
            }
        }
    }, [interestRateRanges, marginRanges]);

    if (interestRateFilter) {
        interestRateFilter.filterType = 'rangeFilter';
        interestRateFilter.useColumnName = false;
    }

    if (marginFilter) {
        marginFilter.filterType = 'rangeFilter';
        marginFilter.useColumnName = false;
    }

    if (variableInterestTypeFilter) {
        variableInterestTypeFilter.filterType = 'multiselectComboFilter';
    }

    const interestTypeSelected = (value: any, showAs?: string) => {
        setSelectedInterestType(value);
        setInterestTypeFilterValue(value);

        interestTypeFilter.selected = value;

        if (interestRateFilter) {
            interestRateFilter.filterType = 'rangeFilter';
            interestRateFilter.useColumnName = false;

            if (interestRateFilter.range) {
                interestRateFilter.range = null;
            }
        }

        if (marginFilter) {
            marginFilter.filterType = 'rangeFilter';
            marginFilter.useColumnName = false;

            if (marginFilter.range) {
                marginFilter.range = null;
            }
        }

        if (variableInterestTypeFilter && variableInterestTypeFilter.selected) {
            variableInterestTypeFilter.selected = [];
        }

        props.filterUpdated(interestTypeFilter, value, showAs);
    }

    const interestRateChanged = (value: any, showAs?: string) => {
        setInterestRateValue(value);
        props.filterUpdated(interestRateFilter, value, showAs)
    }

    const marginChanged = (value: any, showAs?: string) => {
        setMarginValue(value);
        props.filterUpdated(marginFilter, value, showAs)
    }

    const variableInterestTypeSelected = (value: any, showAs?: string) => {
        setVariableInterestTypeFilterValue(value);
        props.filterUpdated(variableInterestTypeFilter, value, showAs)
    }

    return <div className='filter-interest'>
        {interestTypeFilter && <>
            {!props.isForMarginRatesAnalysis && <ValueListControl
                value={interestTypeFilterValue}
                showAs={interestTypeFilter.valueShowAs}
                label={interestTypeFilter.showAs}
                className="filter"
                configuration={{ values: interestTypeFilter.values }}
                onChange={interestTypeSelected} />}

            {selectedInterestType && interestRateRanges && selectedInterestType === 1 &&
                <NumberRangeControl
                    label={interestRateFilter.showAs}
                    unit="%"
                    className="filter range"
                    onChange={(range, showAs) => interestRateChanged(range, showAs)}
                    startMin={parseFloat(interestRateRanges.min)}
                    range={interestRateValue}
                    startMax={parseFloat(interestRateRanges.max)}
                    updatesDisabledOnInit={true} />
            }
            {selectedInterestType && marginRanges && selectedInterestType === 2 &&
                <>
                    <NumberRangeControl
                        label={marginFilter.showAs}
                        unit="%"
                        className="filter range"
                        onChange={marginChanged}
                        range={marginValue}
                        startMin={parseFloat(marginRanges.min)}
                        startMax={parseFloat(marginRanges.max)}
                        updatesDisabledOnInit={true} />

                    <MultiSelectValueListControl
                        value={variableInterestTypeFilterValue}
                        showAs={variableInterestTypeFilter.valueShowAs}
                        label={variableInterestTypeFilter.showAs}
                        className="filter"
                        configuration={{ values: variableInterestTypeFilter.values }}
                        onChange={variableInterestTypeSelected} />
                </>
            }
        </>
        }
    </div>
}
