import { WaitEvent } from "../model/events/TactinEvents";
import { tactin } from "./TactinGlobals";

export async function WithWaitNotify<T>(promise: Promise<T>, message: string): Promise<T> {
    const [notify, finish] = createWaitEvents();
    notify(message);
    try {
        return await promise;
    } finally {
        finish();
    }
}

let waitId = 1;
function getNewId() {
    return waitId++;
}
export function createWaitEvents(): [(message: string) => void, () => void] {
    const id = getNewId();
    const notify = (message: string) => tactin()?.eventBus.notify(new WaitEvent(id, message, false));
    const finish = () => tactin()?.eventBus.notify(new WaitEvent(id, '', true));
    return [notify, finish];
}
