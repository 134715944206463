import React from 'react';
import NamedCombo, { Named } from '../../../components/basic/NamedCombo';
import { LabeledControl } from '../../../components/propertycontrols/LabeledControl';

type LabelledNamedComboProps = {
    label: string;
    className?: string;
    selected: number;
    onChange: (v: number) => void;
    children: Named[];
    disabled?: boolean;
}

export function BasicNamedCombo(props: LabelledNamedComboProps) {
    return <LabeledControl label={props.label} className={props.className} >
        <NamedCombo
            children={props.children}
            selected={props.selected}
            onChange={props.onChange}
            disabled={props.disabled} />
    </LabeledControl>
}

export const LabelledCombo = BasicNamedCombo;