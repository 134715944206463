import { Region } from '../../../../model/list/cache/Region';
import { RowData, FoldableRow } from '../../../../model/list/cache/RowParser';
import DataCache, { START_CACHE_INTERVAL } from "./DataCache";

export type MissingDataCallback =
    (parent: CacheNode, start: number, length: number, minStart: number, maxEnd: number) => void;

export abstract class CacheNode {
    parentRegion: Region | null;
    visibleRows: number;
    open: boolean;
    level: number;
    rowData: RowData;
    index: number = -1;
    visiblePosition: number;

    childCount: number;
    children: Region[];

    constructor(parentRegion: Region | null,
        index: number,
        rowData: RowData,
        childCount: number,
        open: boolean,
        source?: DataCache) {

        this.parentRegion = parentRegion;
        this.visibleRows = 0;
        this.open = open;
        if (parentRegion && parentRegion.getParentGroup())
            this.level = parentRegion.getParentGroup().level + 1;
        else
            this.level = 0;
        this.rowData = rowData;
        this.index = -1;
        this.visiblePosition = -1;
        this.childCount = childCount;
        this.children = [];

        this.updateStart(parentRegion, index);
        if (open)
            this.setVisibleRows(childCount);
        else
            this.visibleRows = childCount;

        if (this.rowData.columns.length) {
            const columnValue = rowData.columns[this.togglableColumn()];
            if (typeof columnValue === 'string')
                this.rowData.columns[this.togglableColumn()] = this.getFormattedTogglableValue(columnValue, source);
        }
    }

    updateStart(parentRegion: Region | null, index: number): void {
        this.parentRegion = parentRegion;
        if (this.index != index) {
            this.index = index;
            if (index % START_CACHE_INTERVAL == 0 && parentRegion) {
                let startIndex: number = ((index / START_CACHE_INTERVAL) - 1) * START_CACHE_INTERVAL;
                if (startIndex >= parentRegion.start) {
                    startIndex -= parentRegion.start;
                    this.visiblePosition = parentRegion.getChildren()[startIndex].visiblePosition;
                }
                else {
                    startIndex = 0;
                    this.visiblePosition = parentRegion.visibleStart;
                }

                for (let i = startIndex; i < index - parentRegion.start; i++)
                    this.visiblePosition += parentRegion.getChildren()[i].getVisibleRows() + 1;
            }
            else
                this.visiblePosition = -1;
        }
    }

    getFormattedTogglableValue(columnValue: string, source?: DataCache): FoldableRow {
        return {
            opened: this.open,
            meta: (source ? source.getFoldingKey(this) : ''),
            showAs: columnValue
        };
    }

    toggleFold(): void {
        this.open = !this.open;

        const columnValue = this.rowData.columns.length ? this.rowData.columns[this.togglableColumn()] : '';
        if (typeof columnValue === 'object')
            columnValue.opened = this.open;

        if (this.parentRegion != null) {
            if (this.open)
                this.parentRegion.setVisibleRows(this.visibleRows, this.index);
            else
                this.parentRegion.setVisibleRows(-this.visibleRows, this.index);
        }
    }

    abstract togglableColumn(): number;

    setVisibleRows(mod: number, block?: Region): void {
        if (mod == 0)
            return;

        this.visibleRows += mod;
        if (block)
            for (let i = this.children.indexOf(block) + 1; i < this.children.length; i++)
                this.children[i].modVisibleStart(mod);

        if (this.parentRegion != null)
            this.parentRegion.setVisibleRows(mod, this.index);
    }

    modVisiblePosition(mod: number): void {
        this.visiblePosition += mod;
    }

    getRowPositionInBlock(): number {
        return this.index + (this.parentRegion ? this.parentRegion.start : 0);
    }

    getVisibleRows(): number {
        if (this.open)
            return this.visibleRows;
        else
            return 0;
    }

    getData(start: number, length: number, loader: MissingDataCallback): RowData[] {
        const result: RowData[] = [];
        if (start > this.getVisibleRows() || length == 0 || !open)
            return result;

        let region: Region | undefined;
        let index: number;
        for ([index, region] of this.children.entries()) {
            //region precedes the range - skip
            if (region.visibleStart + region.visibleRows < start)
                continue;

            //start precedes the region - fill with null's
            if (start < region.visibleStart) {
                //actual start index, not the visible row number
                let loadStart = start + region.start - region.visibleStart;
                let loadLength = 0;
                for (; start < region.visibleStart && length > 0; start++) {
                    result.push({ columns: [] });
                    length--;
                    loadLength++;
                }
                if (loadLength > 0) {
                    loader(this, loadStart, loadLength,
                        (index > 0 ? this.children[index - 1].lastRow() : 0),
                        region.start);
                }
            }

            //get rows from region
            const loadedRows = region.getData(start, length, loader);
            result.push(...loadedRows);
            length -= loadedRows.length;
            start += loadedRows.length;

            if (length == 0)
                return result;
        }

        //add trailing missing rows
        let missing = this.childCount;
        let loadStart = start;
        if (region) {
            missing -= (region.start + region.getChildCount());
            //if the needed rows exceed the last region
            //move the start value to from the visible rows to actual indexes
            loadStart = start + region.start - region.visibleStart;
        }
        for (let i = 0; i < missing && i < length; i++) {
            result.push({ columns: [] });
        }
        if (loader != null && missing != 0) {
            loader(this,
                loadStart,
                Math.min(length, missing),
                (!region ? 0 : region.lastRow()),
                this.childCount);
        }
        return result;
    }

    clear(): void {
        this.children = [];
        this.visibleRows = this.childCount = 0;
    }

    getNodeKey(): string {
        if (!this.rowData || this.rowData.columns.length <= this.togglableColumn())
            return '';
        const columnValue = this.rowData.columns[this.togglableColumn()];
        if (typeof columnValue === 'string')
            return '';
        return columnValue.meta;
    }
}
