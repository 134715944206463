import React, { ReactChild, ReactElement, PropsWithChildren } from 'react';

type OnClickCreator = (signal?: string) => (() => void);

type Props = {
    title: ReactChild;
    buttons: ((onClickCreator: OnClickCreator) => ReactElement)[];
    onClose: (signal?: string) => void;
    className?: string;
}

export default function PopupBox({ title, buttons, children, onClose, className }: PropsWithChildren<Props>) {
    const emitClose = (signal?: string) => {
        if(onClose)
            onClose(signal);
    }

    const btns = buttons.map(b => 
        b((signal?: string) =>
        emitClose.bind(null, signal)));
    return (
    <div className="modal">
        <div className={"popup" + (className ? " " + className : "")}>
            <div className="header">
                {title}
                <div className="close button" onClick={() => emitClose()}>&times;</div>
            </div>
            <div className="body">
                {children}
            </div>
            <div className="footer">
                {btns}
            </div>
        </div>
    </div>);
}
