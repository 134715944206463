import { ShowMessageEvent, TokenOutOfDateEvent } from "../../model/events/TactinEvents";
import { tactin } from "../TactinGlobals";
import { ConnectionError, ExecutionError, Failed, ImportFailed, TokenOutOfDate } from "./TactinApi";

function showMessage(msg: string) {
    tactin()?.eventBus.notify(new ShowMessageEvent(msg));
}

export function HandleFailed(failed: ((msg: string) => void)) {
    return (error: any): false => {
        if (error instanceof Failed) {
            failed && failed(error.message);
            return false;
        }
        throw error;
    }
}

export function HandleImportFailed(failed: ((errors: string, msg: string) => void)) {
    return (error: any): false => {
        if (error instanceof ImportFailed) {
            failed && failed(error.genericErrors, error.message, );
            return false;
        }
        throw error;
    }
}

export function HandleCleanup(cleanup: () => void) {
    return (error: any) => {
        cleanup && cleanup();
        throw error;
    }
}

export function HandleErrors(quietFail: boolean = false) {
    return (error: any): false => {
        let err: string;
        if (error instanceof Failed)
            err = !quietFail ? error.message : '';
        else if (error instanceof TokenOutOfDate) {
            tactin().eventBus.notify(new TokenOutOfDateEvent());
            return false;
        } else if (error instanceof ExecutionError) {
            err = 'Błąd serwera w trakcie wykonywania zapytania. Więcej informacji znajdziesz w konsoli przeglądarki.';
            console.log(error.content);
        } else if (error instanceof ConnectionError) {
            err = 'Błąd połączenia z serwerem.';
        } else
            throw error;
        err && showMessage(err);
        return false;
    }
}
