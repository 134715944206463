import React, { useEffect, useState } from 'react';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'

export function BareDecimalControl({ value, readOnly, forPreview, className = '', onChange, configuration }: BarePropertyControlProps) {
    const validator = /^(-)?(?:(\d+)?([.,]\d*)?)?$/;
    const decimalPlaces = parseFormat(configuration?.format);
    const format = (val: number | null) => (val ?? 0).toFixed(decimalPlaces);
    const [internalValue, setInternalValue] = useState<string>(format(value));
    useEffect(() => {
        const fv = format(value);
        if (fv !== internalValue)
            setInternalValue(format(value))
    }, [value]);

    // console.log(`val: ${value}, internal: ${internalValue}`);

    const change = (val: number | null) => {
        onChange(val === null ? null : round(val, decimalPlaces));
        setInternalValue(format(val));
    }

    return (
        <div className={className}>
            {forPreview
                ? <><span className='preview'>{internalValue}</span></>
                : <>
                    <input
                        type='text'
                        value={internalValue}
                        disabled={readOnly}
                        onChange={(e) => {
                            const res = e.currentTarget.value.match(validator)
                            if (!res)
                                return;
                            setInternalValue(
                                (res[1] || '') + //minus sign
                                (res[2] || (res[3] ? '0' : '')) + //integer part
                                (res[3] ? res[3].replace(',', '.') : '')    //decimal part with dot
                            );
                        }}
                        onBlur={() => {
                            if (internalValue === format(value))
                                return;
                            if (!internalValue)
                                change(null);
                            else if (internalValue === '-')
                                change(0);
                            else if (internalValue.includes('.'))
                                change(Number(internalValue + '0'));
                            else
                                change(Number(internalValue));
                        }}
                        onKeyDown={e => ['Escape', 'Enter'].includes(e.key) && e.currentTarget.blur()}
                    />
                </>
            }
        </div>);
}

export const DecimalControl = WithLabel(BareDecimalControl);

function parseFormat(format?: string): number {
    const precision = Number(format ?? '');
    if (isNaN(precision))
        return 4;
    if (precision < 0)
        return 0;
    if (precision > 4)
        return 4;
    return precision;
}

function round(val: number, format: number): number {
    const mul = Math.pow(10, format);
    return Math.round(val * mul) / mul;
}
