import ValidateGenericParameters from "./GenericParametersValidator";

export default function ValidatePercentyleParameter(higher: number | undefined, lower: number | undefined): boolean {
    let validationResult = true;

    validationResult = validationResult && ValidateGenericParameters(lower);
    validationResult = validationResult && ValidateGenericParameters(higher);

    if (!validationResult)
        return false;

    return validationResult && higher && lower 
        ? higher > lower && higher <= 100 && lower > 0
        : false;
}