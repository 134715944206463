import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'

type ValueItemType = {
    value: number;
    text: string;
    ordering?: number;
}

export function BareValueListControl({ value, className = '', configuration, onChange, readOnly, forPreview }: BarePropertyControlProps) {
    const [values, setValues] = useState<{ all: ValueItemType[], available: ValueItemType[] }>({ all: [], available: [] });
    const popupRef = useRef<HTMLDivElement>(null);
    const [isPopupOpen, setPopupState] = useState(false);

    useEffect(() => {
        if (configuration?.values) {
            const allValues: ValueItemType[] =
                (configuration.values as string[]).map((s, i) => ({ value: i + 1, text: s, ordering: getOrderin(s) }));
            // filtering
            let newValues: ValueItemType[];
            if (configuration.shown)
                newValues = allValues.filter(valueItem => (configuration.shown as number[]).includes(valueItem.value))
            else if (configuration.hidden?.length)
                newValues = allValues.filter(valueItem => !(configuration.hidden as number[]).includes(valueItem.value));
            else
                newValues = [...allValues];
            // sorting
            newValues = newValues.sort((a, b) => {
                if (a.ordering !== undefined && b.ordering !== undefined)
                    return a.ordering - b.ordering;
                else if (a.ordering !== undefined)
                    return -1;
                else if (b.ordering !== undefined)
                    return 1;
                else
                    return a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase());
            })
            setValues({
                all: allValues,
                available: newValues
            })
        }
    }, [configuration]);

    useLayoutEffect(() => {
        if (!popupRef.current)
            return;
        const rect = popupRef.current.getBoundingClientRect();
        const parentRect = popupRef.current.parentElement?.getBoundingClientRect();
        const windowHeight = document.getElementsByTagName('html').item(0)?.clientHeight;
        if (parentRect && (parentRect.top - rect.height < 0))
            popupRef.current.className = 'comboBoxPanel lower';
        else if (rect.top + rect.height > (windowHeight || Infinity))
            popupRef.current.className = 'comboBoxPanel upper';
        else
            popupRef.current.className = 'comboBoxPanel lower';
    });

    useEffect(() => {
        if (isPopupOpen) {
            const handler = (e: MouseEvent) => {
                if (!popupRef.current?.contains((e.target as HTMLElement)))
                    setPopupState(false);
            }
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    }, [isPopupOpen])

    function setValue(v: number, s: string) {
        if (v === value) {
            setPopupState(false);
        } else {
            onChange(v || null, s);
            setPopupState(false);
        }
    }
    function additionalClasses() {
        if (value) {
            if (!values.all.find(a => a.value === value))
                return 'notExistValue';
            if (!values.available.find(a => a.value === value))
                return 'notAvailableValue';
        }
    }

    const getCurrentText = () =>
        !value ? '' : values.all.find(a => a.value === value)?.text || `Missing ID: ${value}`;

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => ['Escape', 'Enter'].includes(e.key) && e.currentTarget.blur();

    return (<div className={className}>
        {forPreview
            ? <><span className='preview'>{getCurrentText()}</span></>
            : <>
                <input type='text'
                    readOnly
                    disabled={readOnly}
                    value={getCurrentText()}
                    className={`itemComboBoxTextArea ${additionalClasses()}`}
                    onClick={() => !readOnly && setPopupState(true)}
                    onKeyDown={onKeyDown} />
                <button type='button'
                    className='itemComboBoxExpandButton'
                    disabled={readOnly}
                    onClick={() => setPopupState(true)}
                    onKeyDown={onKeyDown}>V</button>
                {isPopupOpen &&
                    <div ref={popupRef}>
                        <select className='comboBoxListArea'
                            size={Math.min(values.available.length + 1, 6)}
                            onKeyDown={onKeyDown} >
                            <option value='0' onClick={() => setValue(0, '')}></option>
                            {values.available.map(item =>
                                <option key={item.value}
                                    value={item.value}
                                    onClick={() => setValue(item.value, item.text)}>{item.text}</option>)}
                        </select>
                    </div>}
            </>}
    </div>);
}

export const ValueListControl = WithLabel(BareValueListControl);

function getOrderin(text: string) {
    const match = text.match(/^\d+/);
    if (match)
        return Number(match[0]);
}
