import React, { useEffect, useState } from 'react';
import { LogoutEvent, TactinEvent, TokenOutOfDateEvent } from '../../model/events/TactinEvents';
import { tactin } from '../../utils/TactinGlobals';
import MessageBox from '../basic/MessageBox';

export default function TokenOutdatedHandler() {
    const [catched, setCatched] = useState<{} | null>(null);

    useEffect(() => {
        return tactin().eventBus.register(messageHandler);
    }, []);

    const messageHandler = (event: TactinEvent) => {
        if (event instanceof TokenOutOfDateEvent)
            setCatched({});
    }

    if (catched)
        return <MessageBox
            title='Token out of date'
            message='Token logowania wygasł. Musisz zalogować się ponownie.'
            onClose={() => {
                tactin().eventBus.notify(new LogoutEvent());
            }}
        />
    else
        return null;
}
