import { ROW_KEY_DELIMITER } from '../../../../model/list/cache/DataCache';
import { RowCacheNodeBlock } from '../../../../model/list/cache/GroupCache';
import { Region } from '../../../../model/list/cache/Region';
import { RowData, RowParserFn } from '../../../../model/list/cache/RowParser';
import { ColumnFilter } from '../../../../model/list/ColumnFilter';
import { ListProperties } from '../../../../model/list/HelperTypes';
import { api } from '../../../../utils/api/ApiProvider';
import { ListDataResult } from '../../../../utils/api/Results';
import { CacheNode } from './CacheNode';
import { CacheNodeBlock } from './CacheNodeBlock';
import DataCache from './DataCache';

export class BasicCacheNode extends CacheNode {
  constructor(parentRegion: BasicCacheNodeBlock | null, index: number, rowData: RowData, childCount: number, open: boolean, source?: DataCache) {
    super(parentRegion, index, rowData, childCount, open, source);
  }

  togglableColumn(): number {
    return this.level - 1;
  }
}

export class BasicCacheNodeBlock extends CacheNodeBlock {
  constructor(parent: CacheNode, start: number, rows: string[][], visibleStart: number, visibleRows: number, source: BasicDataCache, rowParser?: RowParserFn) {
    super(parent, start, rows, visibleStart, visibleRows, rowParser, source);
  }

  getNew(row: string[], rowParser?: RowParserFn, source?: DataCache): CacheNode {
    const rowData = rowParser ? rowParser(row) : { columns: [...row] };
    return new BasicCacheNode(this, this.start + this.rows.length, rowData, this.getChildCount2(row), source ? source.isOpen(rowData, this.parent) : false, source);
  }
}

export class BasicDataCache extends DataCache {
    allData?: Promise<ListDataResult>;

  constructor(rowCount: number, filters: ColumnFilter[], defaultCacheSegmentSize: number, rowParser?: RowParserFn) {
    super(new BasicCacheNode(null, 0, { columns: [] }, rowCount, true), defaultCacheSegmentSize, new Map(), rowParser);

    this.allData = new Promise((resove, reject) => {

    });

    this.filters = [...filters];
  }

  getNewRegion(fold: CacheNode, start: number, rows: string[][], visibleStart: number): Region {
    return new RowCacheNodeBlock(fold, start, rows, visibleStart, rows.length, this.rowParser);
  }

  getRowKey(row: RowData, parent?: CacheNode | undefined): string {
    let parentKey: string | null = null;
    let thisLevel = 1;
    if (parent && parent.rowData.columns.length) {
      thisLevel = parent.level + 1;
      const columnValue = parent.rowData.columns[parent.level - 1];
      if (typeof columnValue === 'object') parentKey = columnValue.meta;
      else parentKey = null;
    }

    let thisRow = '';
    if (row.columns.length >= thisLevel) {
      const colValue = row.columns[thisLevel - 1];
      thisRow = typeof colValue === 'string' ? colValue : colValue.showAs;
    }
    if (parentKey === null) return thisRow;
    else return parentKey + ROW_KEY_DELIMITER + thisRow;
  }

  getListData(parent: CacheNode, start: number, length: number): Promise<ListDataResult> {

    return api().List.getList({
      dataProviderId: this._dataProvider,
      wildcards: this._wildcards,
      filters: this._filters,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection,
      start,
      limit: 0,
    });
    
  }

  getListProperties(): Promise<ListProperties> {
    return api().List.getProperties({
      dataProviderId: this._dataProvider,
      wildcards: this._wildcards,
      filters: this.filters,
      skipColumns: false,
    });
  }
}
