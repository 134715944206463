import { TPreLoginUserApi, TUserApi } from './ApiTypes';
import { toBooleanResult, toLoginResult } from './Results';
import * as Api from './TactinApi';

const MODULE = 'UserModule';

export const UserApi = (token: string): TUserApi => ({
    changePassword: (userId: number, password: string) =>
        Api.sendRequest(MODULE, 'changePassword', { userId, password }, token).then(toBooleanResult),
    logout: () =>
        Api.sendRequest(MODULE, 'logout', {}, token).then(sar => sar.resultCode === 'OK')
});

export const PreLoginUserApi: TPreLoginUserApi = {
    login: (user: string, password: string, asAdmin: boolean) =>
        Api.sendRequest(MODULE, 'loginServer', { userName: user, password, asAdmin }).then(r => toLoginResult(r)),
    validateToken: (token: string) =>
        Api.sendRequest(MODULE, 'validateToken', { token }).then(sar => sar.resultCode === 'OK'),
};
