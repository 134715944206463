import { SessionHeartbeatEvent } from "../../model/events/TactinEvents";
import { tactin } from "../TactinGlobals";
import { api } from './ApiProvider';
import { ImportResult, ProcessingProgressResult } from './Results';

type ErrorCode = 'ERROR' | 'IMPORT_ERROR' | 'INVALID_CREDENTIALS' | 'TOKEN_OUT_OF_DATE';

export class Failed {
    message: string;
    errorCode: ErrorCode;

    constructor(msg: string, errorCode: ErrorCode = 'ERROR') {
        this.message = msg;
        this.errorCode = errorCode;
    }
}

export class ImportFailed {
    genericErrors: string;
    message: string;
    errorCode: ErrorCode;

    constructor(errors: string, msg: string, errorCode: ErrorCode = 'IMPORT_ERROR') {
        this.genericErrors = errors;
        this.message = msg;
        this.errorCode = errorCode;
    }
}

export class TokenOutOfDate {
}

export class ExecutionError {
    content: any;

    constructor(content: any) {
        this.content = content;
    }
}

export class ConnectionError {
}

export interface ServerActionResult {
    resultType: string;
    message: string;
    resultCode: 'OK' | ErrorCode;
}

function getHeader(token?: string): Headers {
    let result: Headers = new Headers();
    result.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    if (token)
        result.append('Authorization', token);
    return result;
}

function getParameters(module: string, serviceName: string, parameters: {}): string {
    return "module=" + encodeURIComponent(module)
        + "&service=" + encodeURIComponent(serviceName)
        + "&json=" + encodeURIComponent(JSON.stringify(parameters))
}

export function sendRequest(module: string,
    serviceName: string,
    parameters: {},
    token?: string
): Promise<ServerActionResult> {
    const url: string = 'tactin/api';
    const options = {
        method: 'POST',
        headers: getHeader(token),
        body: getParameters(module, serviceName, parameters)
    };

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => {
                const status: number = response.status;
                if (status === 0)
                    reject(new ConnectionError());
                else if (status < 200 || status > 299)
                    reject(new ExecutionError(new Error("Błąd serwera!")))
                else
                    response.json()
                        .then(json => {
                            tactin()?.eventBus.notify(new SessionHeartbeatEvent());
                            let result: ServerActionResult = json;
                            if (result.resultCode === 'OK')
                                resolve(result);
                            else if (result.resultCode === 'TOKEN_OUT_OF_DATE')
                                reject(new TokenOutOfDate());
                            else if (result.resultCode === 'IMPORT_ERROR')
                                reject(new ImportFailed((result as ImportResult).genericErrors, result.message, result.resultCode));
                            else
                                reject(new Failed(result.message, result.resultCode));
                        })
                        .catch(error => {
                            console.log(error);
                            reject(new ExecutionError(error));
                        });
            })
            .catch(error => {
                console.log(error);
                reject(new ConnectionError());
            });
    });
}

export function uploadFile(module: string,
    serviceName: string,
    parameters: {},
    data: File,
    token: string,
    showProgress?: (progress: number) => void,
    showProcessingProgress?: (progress: number) => void,
    getAbort?: (fn: () => void) => void,
): Promise<ServerActionResult> {

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'tactin/api', true);
        xhr.responseType = 'json';
        xhr.timeout = 6000000;
        xhr.setRequestHeader('Authorization', token);

        const formData = new FormData();
        formData.append('fileUploadFormElement', data);
        formData.append('module', encodeURIComponent(module));
        formData.append('service', encodeURIComponent(serviceName));
        formData.append('json', JSON.stringify(parameters));

        let fileUploadedIsProcessing = false;
        let fileFinishedProcessing = false;

        if (showProgress) {
            xhr.upload.onprogress = async function (e) {
                const progress = Math.round((e.loaded * 100) / e.total);
                showProgress(progress);

                if (progress >= 100 && showProcessingProgress) {
                    await startUpdatingProgress();
                }
            };
        }

        const startUpdatingProgress = async () => {
            let progressResult: ServerActionResult | null = null;

            while (!fileFinishedProcessing) {
                await timeout(10000);
                progressResult = await updateProcessingProgress();
            }

            if (progressResult) {
                resolve(progressResult);
            }

        }

        const timeout = (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        const updateProcessingProgress = async (): Promise<ServerActionResult | null> => {
            return await getProcessingProgress().then((progressResult: ProcessingProgressResult) => {
                if (progressResult.processingStateName === 'inProgress') {
                    fileUploadedIsProcessing = true;
                    
                    if (showProcessingProgress) {
                        showProcessingProgress(progressResult.processed);
                    }
                } else if (progressResult.processingStateName === 'processed') {
                    fileFinishedProcessing = true;
                }

                return progressResult;
            });
        }

        const getProcessingProgress = async () => {
            return api().Cngr.getProcessingProgress({ name: data.name });
        }

        xhr.onloadend = () => {
            const status: number = xhr.status;
            if (status === 0) {
                if (showProcessingProgress && !fileUploadedIsProcessing && !fileFinishedProcessing) {
                    reject(new ConnectionError());
                } else if (!showProcessingProgress) {
                    reject(new ConnectionError());
                }
            }
            else if (status < 200 || status > 299)
                reject(new ExecutionError(new Error("Błąd serwera!")))
            else { 
                tactin()?.eventBus.notify(new SessionHeartbeatEvent());
                let result: ServerActionResult = xhr.response;
                
                if (result.message !== "Plik już jest w trakcie importu") {
                    if (result.resultCode === 'OK')
                        resolve(result);
                    else if (module === 'CngrModule') {
                        const importResult: ImportResult = xhr.response;

                        reject(new ImportFailed(importResult.genericErrors, importResult.message, result.resultCode));
                    } else {
                        reject(new Failed(result.message, result.resultCode));
                    }
                }
            }
        }
        if (getAbort)
            getAbort(() => xhr.abort());
        xhr.send(formData);
    });
}
