import React, { useEffect, useState } from 'react'
import { Named } from '../../../components/basic/NamedCombo';
import { ToolbarOption } from '../../../components/cards/ItemToolbar';
import { TextControl } from '../../../components/propertycontrols/TextControl';
import { OpenItemEvent } from '../../../model/events/TactinEvents';
import { ItemBuiltInFields } from '../../../model/item/Item';
import { ClientItem, Item } from '../../../model/item/ItemTypes';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { api } from '../../../utils/api/ApiProvider';
import { GetArticleLinkConfigurationResult } from '../../../utils/api/Results';
import { tactin } from '../../../utils/TactinGlobals'
import { ArticleLink } from '../../model/ArticleLink';
import ArticleLinkButton from '../ui/ArticleLinkButton';
import { LabelledCombo } from '../ui/LabelledCombo';

import './MainAnalysis.css'
import MainAnalysisDetails from './MainAnalysisDetails';

type MainAnalysisProps = {
    clientItem: ClientItem,
    onChange: (item: Item) => void;
    itemSaveAction: (action: ToolbarOption) => Promise<void>
    closeAnalysisWindow?: () => void;
}

export default function MainAnalysis(props: MainAnalysisProps) {
    const translate = tactin().configuration.translate;
    const [categories, setCategories] = useState<Named[]>([]);
    const [articleLinks, setArticleLinks] = useState<ArticleLink[]>([]);

    useEffect(() => {
        loadInitData();
    }, []);

    const change = (name: string, value: any, showAs?: string) => {
        if (ItemBuiltInFields.has(name)) {
            let field = name;
            if (name === 'category')
                field = 'categoryID';
            props.onChange({ ...props.clientItem.item, [field]: value });
        } else {
            const prpId = +(/^pv_(\d+)$/.exec(name)?.[1] || 0);
            if (prpId > 0)
                props.onChange({
                    ...props.clientItem.item,
                    properties: {
                        ...props.clientItem.item.properties,
                        [prpId]: {
                            ...(props.clientItem.item.properties?.[prpId] || {
                                type: props.clientItem.item.category?.properties.find(p => p.id === prpId)?.dataType || 'TEXT'
                            }),
                            value,
                            showAs
                        }
                    }
                });
        }
    }

    const loadInitData = () => {
        (async () => {
            try {
                const itemType = (await api().Config.ItemType.list()).find(t => t.id === props.clientItem.item.type);

                if (itemType) {
                    const allCategories = (await api().Config.Category.list()).filter(c => c.type === itemType.id);
                    setCategories(allCategories.map(c => ({
                        id: c.id,
                        name: c.name
                    } as Named)));
                }

                getArticleLinks();
            } catch (e) {
                HandleErrors()(e);
            }
        })();
    }

    const saveNewAnalysis = () => {
        props.itemSaveAction && props.itemSaveAction('save' as ToolbarOption);
    }

    const getArticleLinks = () => {
        api().Cngr.getArticleLinksConfiguration()
            .then((result: GetArticleLinkConfigurationResult) => {
                setArticleLinks(result.articleLinks);
            });
    }

    const showArticle = (name: string) => {
        const articleLink = articleLinks.find(f => f.name === name.replaceAll("\"", ""));

        if (articleLink) {
            tactin().eventBus?.notify(new OpenItemEvent().byItemId(articleLink.itemId));
        }
    }

    return <div className='cngr-analysis'>
        {props.clientItem.item.itemID < 0
            ? <div className='new-analysis'>
                <section className='content'>
                    <div className='form-controls'>
                        <TextControl
                            label={translate('AssignAnalysisName')}
                            onChange={value => change('name', value)}
                            value={props.clientItem.item.name} />

                        <div className='analysis-type'>
                            <LabelledCombo
                                label={translate('ChooseAnalysisType')}
                                children={categories}
                                selected={props.clientItem.item.categoryID}
                                disabled={props.clientItem && props.clientItem.item.itemID > 0}
                                onChange={value => change('category', value)} />

                            <ArticleLinkButton showArticle={() => showArticle(translate('Analysis type'))} />
                        </div>

                    </div>

                    <button type='button' onClick={saveNewAnalysis}>{translate('Next')}</button>
                </section>
            </div>
            : <MainAnalysisDetails
                clientItem={props.clientItem}
                onChange={props.onChange}
                itemSaveAction={props.itemSaveAction}
                closeAnalysisWindow={props.closeAnalysisWindow}
                showArticle={showArticle} />}
    </div>
}