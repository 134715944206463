import React, { useEffect, useRef, useState } from 'react';
import { ToolbarOption } from '../../../components/cards/ItemToolbar';
import { TextControl } from '../../../components/propertycontrols/TextControl';
import { DownloadFileEvent, OpenItemEvent, ShowMessageEvent } from '../../../model/events/TactinEvents';
import { createNewItem } from '../../../model/item/Item';
import { ClientItem, Item, ItemCategory } from '../../../model/item/ItemTypes';
import { ColumnFilter, ComboColumnFilter, FilterManager, newFilterManager } from '../../../model/list/ColumnFilter';
import { HandleErrors } from '../../../utils/api/ApiErrorHandler';
import { api } from '../../../utils/api/ApiProvider';
import { CopyAnalysisParams, DownloadAnalysisParams } from '../../../utils/api/ApiTypes';
import { tactin } from '../../../utils/TactinGlobals';
import { StatisticFunction } from '../../model/StatisticFunction';
import { ValidationResult } from '../../model/validators/ValidationResult';
import AnalysisDetailsToolbar, { AnalysisDetailsToolbarOption } from '../ui/AnalysisDetailsToolbar';
import { InstrumentSelection } from '../ui/BasicDataTable';
import CustomFilterBox from '../ui/CustomFilterBox';
import { InstrumentsList } from './InstrumentsList';

import './MainAnalysisDetails.css'
import StatisticalAnalysisResults from './StatisticalAnalysisResults';
import { WithWaitNotify } from '../../../utils/WaitNotification';
import { Failed } from '../../../utils/api/TactinApi';

type MainAnalysisDetailsProps = {
    clientItem: ClientItem;
    onChange: (item: Item) => void;
    // TODO: Different types...rethink this
    itemSaveAction: (action: ToolbarOption) => Promise<void>
    closeAnalysisWindow?: () => void;
    showArticle?: (name: string) => void;
}

export default function MainAnalysisDetails(props: MainAnalysisDetailsProps) {
    const dataProvider = "31";
    const baseInstrumentListDataProvider = "40";
    const selectedInstrumentListDataProvider = "42";

    const instrumentSelectionPropertyId = 154;
    const instrumentItemPropertyId = 155;
    const analysisClosedPropertyId = 156;
    const selectionStatisticalFunctionsId = 161;
    const predefinedColumnNamesToSetIfNotUserSelected: string[] = [
        "\"Podmiot\"",
        "\"Rodzaj oprocentowania\"",
        "\"Oprocentowanie\"",
        "\"Marża\"",
        "\"Kwota finansowania\"",
        "\"Waluta finansowania\"",
        "\"Zabezpieczenie\"",
        "\"Rodzaj instrumentu finansowego\""
    ];

    const translate = tactin().configuration.translate;

    const getInstrumentIdsFromElements = (item: Item): string => {
        let itemIds: string[];

        const elements = item.elements[0].elements?.filter(e => e.properties[instrumentItemPropertyId]);

        if (elements && elements.length > 0) {
            if (item.properties[analysisClosedPropertyId].value) {
                itemIds = elements.filter(e => e.properties[instrumentSelectionPropertyId].value)
                    .map(e => e.properties[instrumentItemPropertyId].value)
            } else {
                itemIds = elements.map(e => e.properties[instrumentItemPropertyId].value);
            }

            return itemIds.join(',');
        }

        return '-1';
    }

    const getWildcards = (item: Item): string[] => {
        const wildcards = getInstrumentIdsFromElements(item);
        return wildcards.length > 0 ? ['ids:' + wildcards, 'analysisId:' + props.clientItem.item.itemID] : [];
    }

    const getSavedFilters = () => {
        const filters = props.clientItem.item.description;

        if (filters)
            return JSON.parse(filters);

        return null;
    }

    const isAnalysisClosed = (): boolean => {
        return props.clientItem.item.properties[analysisClosedPropertyId].value;
    }

    let initialFilters = getSavedFilters();
    let filterManager = newFilterManager();

    if (initialFilters) {
        filterManager.update(initialFilters);
    }

    const [instrumentSelectionElementCategory, setInstrumentSelectionElementCategory] = useState<ItemCategory>();
    const [columnsSelectionElementCategory, setColumnsSelectionElementCategory] = useState<ItemCategory>();

    const [wildcards, setWildcards] = useState<string[]>(getWildcards(props.clientItem.item));
    const [filtersAlreadyModified, setFiltersAlreadyModified] = useState<boolean>(false);

    const baseFilters = useRef<ColumnFilter[]>();
    const [savedFilters, setSavedFilters] = useState<ColumnFilter[]>(getSavedFilters());
    const [sorting, setSorting] = useState<{ name: string, direction: 'ASC' | 'DESC' }>();

    const [hasCheckedInstruments, setHasCheckedInstruments] = useState<boolean>();

    const [activeFilters, setActiveFilters] = useState<ColumnFilter[] | FilterManager>(filterManager);
    const [savedFiltersShouldBeApplied, setSavedFiltersShouldBeApplied] = useState<boolean>(false);
    const [filtersShouldBeReset, setFiltersShouldBeReset] = useState<boolean>(false);

    const [currentItemsResult, setCurrentItemsResult] = useState<InstrumentSelection[]>([]);
    const [selectedItemsResult, setSelectedItemsResult] = useState<InstrumentSelection[]>([]);

    const [totalInstrumentsCount, setTotalInstrumentsCount] = useState<number>(0);
    const [instrumentsToDownload, setInstrumentsToDownload] = useState<InstrumentSelection[]>([]);
    const [canDownload, setCanDownload] = useState<boolean>(false);
    const [availableCreditsCount, setAvailableCreditsCount] = useState<number>(0);
    const [filePreparedToDownload, setFilePreparedToDownload] = useState(true);
    const [analysisCopyResultReceived, setAnalysisCopyResultReceived] = useState(true);

    const [currentlySelectedStatisticFuntions, setCurrentlySelectedStatisticFuntions] = useState<StatisticFunction[]>([]);
    const [activeListDataProvider, setActiveListDataProvider] = useState<string>(
        isAnalysisClosed()
            ? selectedInstrumentListDataProvider
            : savedFilters && savedFilters.length > 0
                ? baseInstrumentListDataProvider : dataProvider);

    const [allowRefreshFilters, setAllowRefreshFilters] = useState<boolean>(true);

    const [analysisValidationStatus, setAnalysisValidationStatus] = useState<ValidationResult[]>([]);

    const setFilterModified = (wereModified: boolean) => {
        setFiltersAlreadyModified(_ => wereModified);
    }

    useEffect(() => {
        getInstrumentSelectionElementCategory();
        getColumnsSelectionElementCategory();
        getCreditsCount();
    }, []);

    useEffect(() => {
        if (savedFilters) {
            filterManager.update([...savedFilters]);
        }

        if (filtersAlreadyModified) {
            setActiveFilters({ ...filterManager })
            setActiveListDataProvider(_ => dataProvider);
            setWildcards(_ => []);
            setAllowRefreshFilters(_ => true);
        } else {
            if (activeListDataProvider === baseInstrumentListDataProvider) {
                const wildcards = getInstrumentIdsFromElements(props.clientItem.item);

                if (wildcards.length > 0) {
                    setWildcards(['ids:' + wildcards, 'analysisId:' + props.clientItem.item.itemID]);
                } else {
                    setActiveListDataProvider(_ => dataProvider);
                    setWildcards([]);
                }
            }
        }
    }, [filtersAlreadyModified]);

    useEffect(() => {
        if (!filtersAlreadyModified) {
            setAllowRefreshFilters(false);
            setSavedFiltersShouldBeApplied(true);
        } else {
            setSavedFiltersShouldBeApplied(false);
            setFiltersShouldBeReset(false);
        }

        checkHasCheckedInstruments();
    }, [currentItemsResult]);

    useEffect(() => {
        getAvailableFilters();
    }, [activeListDataProvider]);

    const onToolbarAction = (action: AnalysisDetailsToolbarOption): Promise<void> => {
        switch (action) {
            case 'save':
                return canSave()
                    .then(() => updateSelectedItems()
                        .then(() => props.itemSaveAction && props.itemSaveAction('save' as ToolbarOption)))
                    .catch(() => tactin().eventBus.notify(new ShowMessageEvent(tactin().configuration.translate("CannotSaveAnalysisErrorsExist"))));
            case 'saveAndClose':
                return canSave()
                    .then(() => setAnalysisClosedStatus(true)
                        .then(() => updateSelectedItems().then(() => props.itemSaveAction && props.itemSaveAction('save' as ToolbarOption))))
                    .catch(() => tactin().eventBus.notify(new ShowMessageEvent(tactin().configuration.translate("CannotSaveAnalysisErrorsExist"))));
            case 'reopen':
                return setAnalysisClosedStatus(false).then(() => props.itemSaveAction && props.itemSaveAction('save' as ToolbarOption));
            case 'resetFilters':
                return resetSavedAnalysis();
            case 'backToList':
                return backToList();
            case 'download':
                return downloadAnalysis();
            case 'copy':
                return copyAnalysis();
        }
    }

    const getInstrumentSelectionElementCategory = () => {
        const categoryId = props.clientItem.item.elements[0]?.categoryId;

        if (categoryId) {
            api().Item.getCategory(props.clientItem.item.elements[0].categoryId)
                .then((categoryId) => {
                    setInstrumentSelectionElementCategory(categoryId);
                });
        }
    }

    const getAvailableFilters = () => {
        api().List.getFiltersForDataProvider(activeListDataProvider, false).then(v => {
            if (props.clientItem.item.categoryID === 30) {
                const interestTypeFilter = (v.find(f => f.columnAlias === 'Rodzaj oprocentowania') as ComboColumnFilter);

                if (interestTypeFilter) {
                    interestTypeFilter.selected = 2;
                }
            }
            baseFilters.current = [...v];
        }).catch(HandleErrors());
    }

    useEffect(() => {
        if (instrumentSelectionElementCategory) {
            setSelectionsForSavedItems(instrumentSelectionElementCategory.id);
        }

    }, [instrumentSelectionElementCategory]);

    const getColumnsSelectionElementCategory = () => {
        const categoryId = props.clientItem.item.elements[1]?.categoryId;

        if (categoryId) {
            api().Item.getCategory(props.clientItem.item.elements[1].categoryId)
                .then((categoryId) => {
                    setColumnsSelectionElementCategory(categoryId);
                });
        }
    }

    const setItems = (items: InstrumentSelection[]) => {
        if (instrumentSelectionElementCategory && instrumentSelectionElementCategory.id > 0) {
            setSelections(instrumentSelectionElementCategory.id, items);
        }
    }

    const setSelections = (categoryId: number, selections: InstrumentSelection[]) => {
        const elements = props.clientItem.item.elements.filter(e => e.categoryId === categoryId)[0].elements;

        selections.forEach((selection: InstrumentSelection) => {
            const existingSelection = elements.find(e => selection.id === e.properties[instrumentItemPropertyId].value);

            if (existingSelection) {
                selection.isChecked = existingSelection.properties[instrumentSelectionPropertyId].value;
            }
        });

        setCurrentItemsResult(selections);
        setSelectedItemsResult(selections && selections.filter(s => s.isChecked));
    };

    const setSelectionsForSavedItems = (categoryId: number) => {
        const elements = props.clientItem.item.elements.filter(e => e.categoryId === categoryId)[0].elements;

        let selections: InstrumentSelection[] = [];

        elements.forEach((element: Item) => {
            selections.push({
                id: element.properties[instrumentItemPropertyId].value,
                isChecked: element.properties[instrumentSelectionPropertyId].value
            });
        });

        if (selections.length > 0) {
            setCurrentItemsResult(selections);
            setInstrumentsToDownload(selections);
            setSelectedItemsResult(selections && selections.filter(s => s.isChecked));
        }
    };

    const setAnalysisClosedStatus = (isClosed: boolean) => new Promise<void>((resolve, rejest) => {
        const currentItem = props.clientItem;
        currentItem.item.properties[analysisClosedPropertyId] = { type: 'BIT', value: isClosed, showAs: '' };
        props.onChange(currentItem.item);

        resolve();
    });

    const updateitemSelection = (id: number, isChecked: boolean) => {
        if (isAnalysisClosed()) {
            updateInstrumentsToDownload(id, isChecked);
        } else {
            updateMainInstrumentSelection(id, isChecked);
        }
    }

    const updateInstrumentsToDownload = (id: number, isChecked: boolean) => {
        const newInstrumentsToDownload = [...instrumentsToDownload];
        const result = newInstrumentsToDownload?.find(s => s.id === id);

        if (result) {
            result.isChecked = isChecked;
        } else {
            const newInstrumentsToDownload = [...instrumentsToDownload];
            newInstrumentsToDownload.push({ id: id, isChecked: isChecked });
        }

        setInstrumentsToDownload(newInstrumentsToDownload);
    }

    useEffect(() => {
        let canDownloadInstruments = false;

        if (instrumentsToDownload && instrumentsToDownload.length > 0) {
            canDownloadInstruments = availableCreditsCount >= instrumentsToDownload.filter(id => id.isChecked).length;
        }

        setCanDownload(canDownloadInstruments);
    }, [instrumentsToDownload, availableCreditsCount])

    const updateMainInstrumentSelection = (id: number, isChecked: boolean) => {
        const result = currentItemsResult?.find(s => s.id === id);

        if (result) {
            result.isChecked = isChecked;
        }

        checkHasCheckedInstruments();
    }

    const updateSelectedItems = (): Promise<void> => new Promise<void>((resolve, reject) => {
        const currentItem = props.clientItem.item;

        updateColumnsSelection(currentItem);
        updateSelectedStatisticFunctionResults();

        if (savedFilters) {
            updateFilters(currentItem, savedFilters);
        }

        const cleanedElementItems = currentItem.elements[0].elements.map(element => {
            element.deleted = currentItemsResult.find(selection => selection.id === element.properties[instrumentItemPropertyId].value) == null;

            return element;
        });

        for (const result of currentItemsResult) {
            let elementItem = getInstrumentSelectionElementItem(cleanedElementItems, result.id);

            if (elementItem) {
                elementItem.properties[instrumentSelectionPropertyId].value = result.isChecked;

                props.onChange(props.clientItem.item);
            } else {
                if (instrumentSelectionElementCategory) {
                    elementItem = createNewItem(instrumentSelectionElementCategory, props.clientItem.item.itemID).item;

                    elementItem.properties = {
                        [instrumentItemPropertyId]: {
                            "type": "ITEM2ITEM",
                            "value": result.id,
                            "showAs": ""
                        },
                        [instrumentSelectionPropertyId]: {
                            "type": "BIT",
                            "value": result.isChecked,
                            "showAs": ""
                        },
                    };

                    props.clientItem.item.elements[0].elements.push(elementItem);

                    props.onChange(props.clientItem.item);
                } else {
                    reject();
                }
            }
        }

        resolve();
    });

    const updateFilters = (item: Item, filters: ColumnFilter[]) => {
        item.description = JSON.stringify(filters);
        props.onChange(item);
    }

    const setFilters = (filters: ColumnFilter[]) => {
        setSavedFilters([...filters]);
        updateFilters({ ...props.clientItem.item }, filters);
    }

    const isElementItemChecked = (id?: number): boolean => {
        if (id) {
            const selection = isAnalysisClosed()
                ? instrumentsToDownload?.find(s => s.id === id)
                : currentItemsResult?.find(s => s.id === id);

            if (selection) {
                return selection.isChecked;
            }
        }

        return true;
    }

    const checkHasCheckedInstruments = () =>
        setHasCheckedInstruments(currentItemsResult && currentItemsResult.find(s => s.isChecked === true) != null);

    const getSelectedStatisticalFunctions = (): StatisticFunction[] => {
        const functions = props.clientItem.item.properties[selectionStatisticalFunctionsId]?.value;

        if (functions) {
            return JSON.parse(functions) as StatisticFunction[];
        }

        return [];
    }

    const getInstrumentSelectionElementItem = (items: Item[], id?: number): Item | undefined | null => {
        if (id) {
            return items?.find(e => e.properties[instrumentItemPropertyId].value === id);
        }

        return null;
    }

    const backToList = (): Promise<void> => new Promise<void>((resolve, rejest) => {
        if (props.closeAnalysisWindow)
            props.closeAnalysisWindow();

        resolve();
    });

    const canSave = (): Promise<void> => new Promise<void>((resolve, reject) => {
        if (analysisValidationStatus.length === 0 || analysisValidationStatus.map(s => s.isValid).reduce((prev, curr) => prev && curr)) {
            resolve();
        } else {
            reject();
        }
    });

    const downloadAnalysis = (): Promise<void> => new Promise<void>((resolve, reject) => {
        if (isAnalysisClosed()) {
            setFilePreparedToDownload(false);

            const params: DownloadAnalysisParams = {
                analysisItemId: props.clientItem.item.itemID,
                dataProviderId: activeListDataProvider,
                filters: getSavedFilters() as ColumnFilter[],
                wildcards: ["ids:" + instrumentsToDownload.filter(i => i.isChecked).map(i => i.id.toFixed()).join(','),
                "analysisId:" + props.clientItem.item.itemID],
                sortColumn: sorting?.name ?? '',
                sortDirection: sorting?.direction ?? 'ASC'
            };

            api().Cngr.downloadAnalysis(params)
                .then(token => {
                    tactin().eventBus?.notify(new DownloadFileEvent(token));
                    setTimeout(() => getCreditsCount(), 4000);
                    setFilePreparedToDownload(true);

                    api().Cngr.decreaseSecurityCredits(params);
                });

            resolve();
        } else {
            reject();
        }
    });

    const copyAnalysis = (): Promise<void> => new Promise<void>((resolve, reject) => {
        if (props.clientItem.item.itemID > 0) {
            const params: CopyAnalysisParams = {
                analysisId: props.clientItem.item.itemID
            };

            setAnalysisCopyResultReceived(false);

            WithWaitNotify(doCopyAnalysis(params), translate('Copying analysis in progress...'))
                .then(r => resolve(r))
                .catch(e => {
                    if (e instanceof Failed) {
                        tactin()?.eventBus.notify(new ShowMessageEvent(e.message));
                    }
                    reject(e);
                });

            resolve();
        } else {
            reject();
        }
    });

    const doCopyAnalysis = (params: CopyAnalysisParams): Promise<void> => {
        return api().Cngr.copyAnalysis(params)
            .then(result => {
                setAnalysisCopyResultReceived(true);
                tactin().eventBus.notify(new ShowMessageEvent(result.message));
            });
    }

    const columnsSelectionPropertyId = 157;
    const selectionUserPropertyId = 158;

    const getUserSelectedColumns = (item: Item): string[] => {
        const element = item.elements[1]?.elements.find(e => e.properties[selectionUserPropertyId].value === item.modifiedBy);

        if (element) {
            const columns: string = element.properties[columnsSelectionPropertyId].value;

            if (columns) {
                return columns.split(",");
            }
        }

        return predefinedColumnNamesToSetIfNotUserSelected;
    }

    const [userSelectedColumnNames, setUserSelectedColumnNames] = useState<string[]>(getUserSelectedColumns(props.clientItem.item));
    const [hasColumnNamesSpecifiedByUser, setHasColumnNamesSpecifiedByUser] = useState<boolean>(false);

    const setColumns = (columns: string[]) => {
        setHasColumnNamesSpecifiedByUser(columns && columns.length > 0);
        setUserSelectedColumnNames(columns);
    }

    const updateColumnsSelection = (item: Item) => {
        if (item) {
            let elementItem = item.elements[1].elements?.find(e => e.properties[selectionUserPropertyId].value === item.modifiedBy);

            if (elementItem) {
                elementItem.properties[columnsSelectionPropertyId].value = userSelectedColumnNames.join(",");

                props.onChange(item);
            } else {
                if (columnsSelectionElementCategory) {
                    elementItem = createNewItem(columnsSelectionElementCategory, item.itemID).item;

                    elementItem.properties = {
                        [selectionUserPropertyId]: {
                            "type": "ITEM2ITEM",
                            "value": item.modifiedBy,
                            "showAs": ""
                        },
                        [columnsSelectionPropertyId]: {
                            "type": "TEXT",
                            "value": userSelectedColumnNames.join(','),
                            "showAs": ""
                        },
                    };

                    item.elements[1].elements.push(elementItem);

                    props.onChange(item);
                }
            }
        }
    }

    const resetSavedAnalysis = (): Promise<void> => new Promise<void>((resolve, rejest) => {
        resetItem();
        resetFilters();

        resolve();
    });

    const resetItem = () => {
        const analysIsItem = props.clientItem.item;
        analysIsItem.elements.forEach(e => {
            e.elements.map(element => {
                element.deleted = true;
            });
        });

        analysIsItem.description = null;

        props.onChange(analysIsItem);
    }

    const resetFilters = () => {
        if (baseFilters) {
            setFiltersShouldBeReset(true);

            initialFilters = null;

            if (!(activeFilters instanceof Array) && baseFilters.current) {
                activeFilters.update(copyFilters(baseFilters.current));
            }

            //setActiveFilters(filterManager);

            setSavedFilters(JSON.parse(JSON.stringify(baseFilters.current)));
            setAllowRefreshFilters(true);
            setFilterModified(true);
            setWildcards([]);
            setActiveListDataProvider(dataProvider);
        }
    };

    const updateSelectedStatisticFunctionResults = () => {
        const item = props.clientItem.item;

        item.properties[selectionStatisticalFunctionsId].value = JSON.stringify(currentlySelectedStatisticFuntions);
    }

    const getCreditsCount = () => {
        if (isAnalysisClosed()) {
            api().Cngr.getAvailableCreditsCount()
                .then(r => {
                    setAvailableCreditsCount(parseInt(r.message))
                });
        }
    }

    const updateAnalysisValidationStatus = (validationResult: ValidationResult) => {
        const status = [...analysisValidationStatus];
        const statuForCurrentSource = status.find(s => s.source);

        if (statuForCurrentSource) {
            if (statuForCurrentSource.isValid !== validationResult.isValid) {
                statuForCurrentSource.isValid = validationResult.isValid;
                setAnalysisValidationStatus(status);
            }
        } else {
            status.push(validationResult);
            setAnalysisValidationStatus(status);
        }
    }

    if (props.clientItem.item.itemID > 0) {
        return <div className='cngr-analysis-details'>
            {!isAnalysisClosed() && <section className='cngr-filters'>
                <div className='general-data'>
                    <TextControl
                        label={translate('AssignAnalysisName')}
                        onChange={(value: string) => props.onChange({ ...props.clientItem.item, ['name']: value })}
                        value={props.clientItem.item.name} />
                </div>
                {activeFilters && <CustomFilterBox
                    filters={activeFilters}
                    filtersAlreadyModified={filtersAlreadyModified}
                    setFiltersModified={setFilterModified}
                    applySavedFilters={savedFiltersShouldBeApplied}
                    resetFilters={filtersShouldBeReset}
                    savedFilters={savedFilters}
                    setCurrentFilters={setFilters}
                    isForMarginRatesAnalysis={props.clientItem.item.categoryID === 30} />}
            </section>}
            <div>
                <section className='cngr-summary'>
                    <StatisticalAnalysisResults
                        totalInstrumentsCount={totalInstrumentsCount}
                        selectedInstruments={selectedItemsResult}
                        analysisType={props.clientItem.item.categoryID}
                        currentlySelectedFunctions={getSelectedStatisticalFunctions()}
                        isAnalysisClosed={isAnalysisClosed()}
                        saveSelectedFunctions={setCurrentlySelectedStatisticFuntions}
                        updateValidationStatus={updateAnalysisValidationStatus} />
                    <AnalysisDetailsToolbar
                        analysisClosed={props.clientItem.item.properties[analysisClosedPropertyId].value}
                        analysisClosingDisabled={!hasCheckedInstruments}
                        analysisSavingDisabled={!hasCheckedInstruments && !savedFilters && !hasColumnNamesSpecifiedByUser}
                        availableCreditsCount={availableCreditsCount}
                        onClick={onToolbarAction}
                        filePreparedToDownload={filePreparedToDownload}
                        analysisCopyResultReceived={analysisCopyResultReceived}
                        canDownload={canDownload}
                        canCopyAnalysis={props.clientItem.item.itemID > 0} />
                </section>
                <section className='cngr-instruments cngr-max-height'>
                    {instrumentSelectionElementCategory && <InstrumentsList
                        filters={activeFilters}
                        dataProvider={activeListDataProvider}
                        wildcards={wildcards}
                        allowCheckRow={true}
                        analysisType={props.clientItem.item.categoryID}
                        setItems={setItems}
                        updateRowCount={setTotalInstrumentsCount}
                        currentSelections={currentItemsResult}
                        allowRefreshFilters={allowRefreshFilters}
                        getRowCheckedState={isElementItemChecked}
                        setRowCheckedState={updateitemSelection}
                        userSelectedColumnNames={userSelectedColumnNames}
                        onSelectedColumnsChanged={setColumns}
                        onSortingChanged={setSorting}
                        showArticle={props.showArticle} />}
                </section>
            </div>
        </div>
    } else {
        return <div className='empty-item'></div>
    }
}

function copyFilters(filters: ColumnFilter[]): ColumnFilter[] {
    return JSON.parse(JSON.stringify(filters));
}