import React, { useEffect, useState } from 'react';
import { ShowCustomUI, ShowMessageEvent, TactinEvent } from '../../model/events/TactinEvents';
import { tactin } from '../../utils/TactinGlobals';
import PopupBox from '../basic/PopupBox';
import UIFactory, { PopupUI } from './UIFactory';

type PopupState = {
    parentOnClose?: (signal: string, state: any) => Promise<void>;
} & PopupUI;

export default function PopupCustomUI() {
    useEffect(() => {
        return tactin()?.eventBus.register(messageHandler);
    }, []);

    const [innerState, setInnerState] = useState<any>(null);
    const [popupUI, setPopupUI] = useState<PopupState | null>(null);
    const [processing, setProcessing] = useState(false);

    const messageHandler = (event: TactinEvent) => {
        if(event instanceof ShowCustomUI) {
            UIFactory.getPopupUI(event).then(c => {
                if(!c)
                    tactin()?.eventBus.notify(new ShowMessageEvent('Nie udało się pokazać dodatkowego interfejsu.'));
                else
                    setPopupUI({...c, parentOnClose: event.onClose});
            });
        }
    }

    const clearState = () => {
        setInnerState(null);
        setPopupUI(null);
        setProcessing(false);
    }

    if(popupUI === null)
        return null;

    const close = (signal: string) => {
        popupUI.onClose(signal, innerState).then(b => {
            if(b) {
                popupUI.parentOnClose && popupUI.parentOnClose(signal, innerState);
                clearState()
            }
        }).catch(e => setProcessing(false));
        setProcessing(true);
    }

    return <PopupBox
        className={processing ? 'waiting' : ''}
        title={popupUI.title}
        buttons={popupUI.buttons.map(([label, key]: [string, string]) =>
            (onClickCreator) => (<div key={key} className="button" onClick={!processing ? onClickCreator(key) : undefined}>{label}</div>))}
        onClose={s => close(s ?? '')}>
            <popupUI.control state={innerState || {}} setState={setInnerState} processing={processing} />
        </PopupBox>;
}
