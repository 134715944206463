import React from 'react';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'
import { tactin } from '../../utils/TactinGlobals';

export function BareBitControl({ value, readOnly, forPreview, className = '', onChange }: BarePropertyControlProps) {
    return (
        <div className={className}>
            {forPreview
                ? <><span className='preview'>{tactin().configuration.translate(value ? 'Yes' : 'No')}</span></>
                :
                <input
                    type='checkbox'
                    checked={value ?? false}
                    disabled={readOnly}
                    onChange={() => { onChange(!value) }}
                    onKeyDown={e => ['Escape', 'Enter'].includes(e.key) && e.currentTarget.blur()}
                />}
        </div>);
}

export const BitControl = WithLabel(BareBitControl);
