import React, { useEffect, useState } from 'react';
import { ValueListControl } from '../../../../components/propertycontrols/ValueListControl';
import { ColumnFilter, ComboColumnFilter, FilterRange, getFilterValue, MultiselectComboColumnFilter, RangeColumnFilter } from '../../../../model/list/ColumnFilter';
import { MinMaxRange } from '../../../model/MinMaxResults';
import { MultiSelectValueListControl } from '../controls/MultiselectValueListControl';
import { NumberRangeControl } from '../controls/NumberRangeControl';
import { CustomFilterPropertyControlProps } from './CustomFilterPropertyControlProps';

export default function InterestFinancingFilters(props: CustomFilterPropertyControlProps) {
    const chosenCurrencyFilter = props.filters.find(f => f.columnAlias === 'Wybrana waluta finansowania') as ComboColumnFilter;
    const valueFilter = props.filters.find(f => f.columnAlias === 'Kwota finansowania') as RangeColumnFilter;
    const currencyFilter = props.filters.find(f => f.columnAlias === 'Waluta') as MultiselectComboColumnFilter;

    const [valueRanges, setValueRanges] = useState<MinMaxRange | undefined>();

    const [selectedFinancingCurrencyFilter, setSelectedFinancingCurrencyFilter] = useState<RangeColumnFilter>(valueFilter);
    const [valueFilterValue, setValueFilterValue] = useState<FilterRange>();
    const [currentRange, setCurrentRange] = useState<FilterRange>({ min: "100000.0", max: "10000000000.0" });

    const [chosenCurrencyFilterValue, setChosenCurrencyFilterValue] = useState<number>(getFilterValue(chosenCurrencyFilter));
    const [currencyFilterValue, setCurrencyFilterValue] = useState<number[]>([]);

    const applyInitialFilters = (filters: ColumnFilter[]) => {
        if (filters) {
            if (!props.resetFilters) {
                chosenCurrencyFilter.selected = (filters.find(f => f.columnAlias === 'Wybrana waluta finansowania') as ComboColumnFilter).selected;
                setChosenCurrencyFilterValue(_ => getFilterValue(chosenCurrencyFilter));
            }

            const selectedFinancingFilter = filters.find(f => f.showAs ===
                chosenCurrencyFilter.values[chosenCurrencyFilter.selected ? chosenCurrencyFilter.selected - 1 : 2]) as RangeColumnFilter;
            setSelectedFinancingCurrencyFilter(selectedFinancingFilter ? selectedFinancingFilter : valueFilter);

            const value = (filters.find(f => f.columnAlias === 'Kwota finansowania') as RangeColumnFilter).range;

            if (value) {
                valueFilter.range = value;
                setValueFilterValue(value);
            } else {
                const chosenCurrencyValue = (filters.find(f => f.columnAlias !== 'Kwota finansowania'
                    && f.filterType === 'rangeFilter'
                    && f.showAs === 'Kwota finansowania') as RangeColumnFilter);

                if (chosenCurrencyValue && chosenCurrencyValue.range) {
                    setValueFilterValue(chosenCurrencyValue.range);
                } else {
                    setValueFilterValue(valueRanges);
                }
            }

            currencyFilter.selected = (filters.find(f => f.columnAlias === 'Waluta') as MultiselectComboColumnFilter).selected;
            setCurrencyFilterValue(getFilterValue(currencyFilter));
        }
    }

    useEffect(() => {
        if (props.savedFilters && props.applySavedFilters) {
            applyInitialFilters(props.savedFilters);
        } else if (props.filters && props.resetFilters) {
            applyInitialFilters(props.filters);
        }
    }, [props.filters]);

    useEffect(() => {
        if (props.ranges && props.ranges.length > 0) {
            setValueRanges(props.ranges.find(r => r.propertyId === 40)?.range);
        }
    }, [props.ranges]);

    useEffect(() => {
        if (valueRanges && !valueFilter.range) {
            valueFilter.range = valueRanges as FilterRange;
            setValueFilterValue(valueRanges);
        }

    }, [valueRanges]);

    const selectedFinancingCurrencyUpdated = (value: any, showAs?: string) => {
        props.filters
            .filter(f => parseInt(f.valueColumn.split('_')[1]) >= 120 && parseInt(f.valueColumn.split('_')[1]) <= 135 && f.filterType === 'rangeFilter')
            .map(f => {
                (f as RangeColumnFilter).range = null
            });

        setChosenCurrencyFilterValue(value);
        let filter = props.filters.find(f => f.showAs === showAs) as RangeColumnFilter;

        if (filter) {
            filter.filterType = "rangeFilter";
            filter.range = currentRange;
            filter.useColumnName = false;
            chosenCurrencyFilter.selected = value;
            chosenCurrencyFilter.includeInResults = false;

            setSelectedFinancingCurrencyFilter(filter);

            props.filterUpdated(filter, filter.range, filter.showAs);
            props.filterUpdated(chosenCurrencyFilter, value, showAs);
        }
        else {
            filter = props.filters.find(f => f.showAs === selectedFinancingCurrencyFilter.showAs) as RangeColumnFilter;

            chosenCurrencyFilter.selected = null;
            chosenCurrencyFilter.includeInResults = false;

            filter.range = null;
            valueFilter.range = currentRange ? currentRange : null;

            setSelectedFinancingCurrencyFilter(valueFilter);

            props.filterUpdated(valueFilter, currentRange, valueFilter.showAs);
            props.filterUpdated(chosenCurrencyFilter, null, showAs);
        }
    }

    if (valueFilter) {
        valueFilter.filterType = 'rangeFilter';
        valueFilter.useColumnName = false;
    }

    if (currencyFilter) {
        currencyFilter.filterType = 'multiselectComboFilter';
    }

    useEffect(() => {
        if (chosenCurrencyFilter) {
            chosenCurrencyFilter.includeInResults = false;

            if (chosenCurrencyFilter.selected === null || chosenCurrencyFilter.selected === undefined) {
                chosenCurrencyFilter.selected = chosenCurrencyFilter.values.findIndex(f => f === "PLN") + 1;
                setChosenCurrencyFilterValue(chosenCurrencyFilter.selected)
            }
        }
    }, [props.resetFilters]);


    const currencySelected = (value: any, showAs?: string) => {
        setCurrencyFilterValue(value);
        props.filterUpdated(currencyFilter, value, showAs)
    }

    const setRangeAndUpdateFilters = (v: FilterRange, s: any) => {
        setCurrentRange({ ...v });
        setValueFilterValue(v);

        if (selectedFinancingCurrencyFilter.showAs !== "Kwota" && selectedFinancingCurrencyFilter.showAs !== "PLN") {
            valueFilter.range = null;
        } else {
            valueFilter.range = v;
        }

        props.filterUpdated(props.filters.find(f => f.valueColumn === selectedFinancingCurrencyFilter.valueColumn) as RangeColumnFilter, v, s)
    }

    return <div className='filter-financing'>
        {valueFilter && valueRanges && <>
            <div className='choose-currency-value'>
                <ValueListControl
                    value={chosenCurrencyFilterValue}
                    showAs={chosenCurrencyFilter.valueShowAs}
                    label={chosenCurrencyFilter.showAs}
                    className="filter"
                    configuration={{ values: chosenCurrencyFilter.values }}
                    onChange={(value: any, showAs?: string) => selectedFinancingCurrencyUpdated(value, showAs)} />

                {valueFilterValue && <NumberRangeControl
                    key="value"
                    label={valueFilter.showAs}
                    unit={selectedFinancingCurrencyFilter.showAs === "Kwota finansowania" ? "PLN" : selectedFinancingCurrencyFilter.showAs}
                    className="filter range"
                    onChange={(range: FilterRange, s: string) => setRangeAndUpdateFilters(range, s)}
                    range={valueFilterValue}
                    applyThousendSeparator={true}
                    startMin={parseInt(valueRanges.min)}
                    startMax={parseInt(valueRanges.max)}
                    updatesDisabledOnInit={true} />}
            </div>
            <MultiSelectValueListControl
                value={currencyFilterValue}
                showAs={currencyFilter.valueShowAs}
                label={currencyFilter.showAs}
                className="filter"
                configuration={{ values: currencyFilter.values }}
                onChange={(value: any, showAs?: string) => currencySelected(value, showAs)} />
        </>}
    </div>
}
