import React, { useEffect, useState } from 'react';
import { ColumnFilter, FilterRange, RangeColumnFilter } from '../../../../model/list/ColumnFilter';
import { MinMaxRange } from '../../../model/MinMaxResults';
import { NumberRangeControl } from '../controls/NumberRangeControl';
import { CustomFilterPropertyControlProps } from './CustomFilterPropertyControlProps';

export default function SubjectFinancialDetailsFilters(props: CustomFilterPropertyControlProps) {
    let salesRevenueFilter = props.filters.find(f => f.columnAlias === 'Przychody ze sprzedaży') as RangeColumnFilter;
    let operationProfitFilter = props.filters.find(f => f.columnAlias === 'Zysk operacyjny') as RangeColumnFilter;
    let netResultFilter = props.filters.find(f => f.columnAlias === 'Wynik netto') as RangeColumnFilter;
    let assetsValueFilter = props.filters.find(f => f.columnAlias === 'Suma aktywów') as RangeColumnFilter;

    const [salesRevenueRanges, setSalesRevenueRanges] = useState<MinMaxRange | undefined>();
    const [operationProfitRanges, setOperationProfitRanges] = useState<MinMaxRange | undefined>();
    const [netResultRanges, setNetResultRanges] = useState<MinMaxRange | undefined>();
    const [assetsValueRanges, setAssetsValueRanges] = useState<MinMaxRange | undefined>();

    const [salesRevenueValue, setSalesRevenueValue] = useState<FilterRange>();
    const [operationProfitValue, setOperationProfitValue] = useState<FilterRange>();
    const [netResultValue, setNetResultValue] = useState<FilterRange>();
    const [assetsValue, setAssetsValue] = useState<FilterRange>();

    const applyInitialFilters = (filters: ColumnFilter[]) => {
        if (filters) {
            const salesRevenue = (filters.find(f => f.columnAlias === 'Przychody ze sprzedaży') as RangeColumnFilter).range;

            if (salesRevenue) {
                salesRevenueFilter.range = salesRevenue;; 
            }

            setSalesRevenueValue(salesRevenue || salesRevenueRanges);

            const operationProfit = (filters.find(f => f.columnAlias === 'Zysk operacyjny') as RangeColumnFilter).range;

            if (operationProfit) {
                operationProfitFilter.range = operationProfit;
            }

            setOperationProfitValue(operationProfit || operationProfitRanges);

            const netResult = (filters.find(f => f.columnAlias === 'Wynik netto') as RangeColumnFilter).range;

            if (netResult) {
                netResultFilter.range = netResult;
                
            }

            setNetResultValue(netResult || netResultRanges);

            const assets = (filters.find(f => f.columnAlias === 'Suma aktywów') as RangeColumnFilter).range;

            if (assets) {
                assetsValueFilter.range = assets;
            }

            setAssetsValue(assets || assetsValueRanges);
        }
    };

    useEffect(() => {
        if (props.savedFilters && props.applySavedFilters) {
            applyInitialFilters(props.savedFilters);
        } else if (props.filters && props.resetFilters) {
            applyInitialFilters(props.filters);
        }
    }, [props.filters]);

    useEffect(() => {
        if (props.ranges && props.ranges.length > 0) {
            setSalesRevenueRanges(props.ranges.find(r => r.propertyId === 53)?.range);
            setOperationProfitRanges(props.ranges.find(r => r.propertyId === 54)?.range);
            setNetResultRanges(props.ranges.find(r => r.propertyId === 55)?.range);
            setAssetsValueRanges(props.ranges.find(r => r.propertyId === 56)?.range);
        }
    }, [props.ranges]);

    useEffect(() => {
        if (salesRevenueRanges) {
            salesRevenueFilter.range = salesRevenueRanges as FilterRange;

            if (!salesRevenueValue) {
                setSalesRevenueValue(salesRevenueRanges);
            }
        }

        if (operationProfitRanges) {
            operationProfitFilter.range = operationProfitRanges as FilterRange;
            
            if (!operationProfitValue) {
                setOperationProfitValue(operationProfitRanges);
            }
        }

        if (netResultRanges) {
            netResultFilter.range = netResultRanges as FilterRange;
            
            if (!netResultValue) {
                setNetResultValue(netResultRanges);
            }
        }

        if (assetsValueRanges) {
            assetsValueFilter.range = assetsValueRanges as FilterRange;
            
            if (!assetsValue) {
                setAssetsValue(assetsValueRanges);
            }
        }
        
    }, [salesRevenueRanges, operationProfitRanges, netResultRanges, assetsValueRanges]);

    if (salesRevenueFilter) {
        salesRevenueFilter.filterType = 'rangeFilter';
        salesRevenueFilter.useColumnName = false;
    }

    if (operationProfitFilter) {
        operationProfitFilter.filterType = 'rangeFilter';
        operationProfitFilter.useColumnName = false;
    }

    if (netResultFilter) {
        netResultFilter.filterType = 'rangeFilter';
        netResultFilter.useColumnName = false;
    }

    if (assetsValueFilter) {
        assetsValueFilter.filterType = 'rangeFilter';
        assetsValueFilter.useColumnName = false;
    }

    const salesRevenueChanged = (value: any, showAs?: string) => {
        setSalesRevenueValue(value);
        props.filterUpdated(salesRevenueFilter, value, showAs)
    }

    const operationProfitChanged = (value: any, showAs?: string) => {
        setOperationProfitValue(value);
        props.filterUpdated(operationProfitFilter, value, showAs)
    }

    const netResultChanged = (value: any, showAs?: string) => {
        setNetResultValue(value);
        props.filterUpdated(netResultFilter, value, showAs)
    }

    const assetsValueChanged = (value: any, showAs?: string) => {
        setAssetsValue(value);
        props.filterUpdated(assetsValueFilter, value, showAs)
    }

    return <div className='filter-subject-financing'>
        <>
            {salesRevenueValue && salesRevenueRanges && <NumberRangeControl
                label={salesRevenueFilter.showAs}
                unit="zł"
                className="filter range"
                onChange={salesRevenueChanged}
                range={salesRevenueValue}
                applyThousendSeparator={true}
                startMin={parseFloat(salesRevenueRanges.min)}
                startMax={parseFloat(salesRevenueRanges.max)}
                updatesDisabledOnInit={true} /> }

            {operationProfitValue && operationProfitRanges && <NumberRangeControl
                label={operationProfitFilter.showAs}
                unit="zł"
                className="filter range"
                onChange={operationProfitChanged}
                range={operationProfitValue}
                applyThousendSeparator={true}
                startMin={parseFloat(operationProfitRanges.min)}
                startMax={parseFloat(operationProfitRanges.max)}
                updatesDisabledOnInit={true} /> }

            {netResultValue && netResultRanges && <NumberRangeControl
                label={netResultFilter.showAs}
                range={netResultValue}
                unit="zł"
                className="filter range"
                onChange={netResultChanged}
                applyThousendSeparator={true}
                startMin={parseFloat(netResultRanges.min)}
                startMax={parseFloat(netResultRanges.max)}
                updatesDisabledOnInit={true} />}

            {assetsValue && assetsValueRanges && <NumberRangeControl
                label={assetsValueFilter.showAs}
                unit="zł"
                className="filter range"
                onChange={assetsValueChanged}
                range={assetsValue}
                applyThousendSeparator={true}
                startMin={parseFloat(assetsValueRanges.min)}
                startMax={parseFloat(assetsValueRanges.max)}
                updatesDisabledOnInit={true} /> }
        </>
    </div>
}
