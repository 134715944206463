import React, { PropsWithChildren } from 'react';
import { ActionContextProvider } from '../../model/systemactions/ActionContext';
import UIFactory from '../core/UIFactory';
import { Notification } from '../systemactions/SystemAction';
import { ListToolbar } from '../toolbars/ListToolbar';
import CardRibbon from './CardRibbon';

type CardSubPanelWrapperProps = {
    icon?: string;
    headline?: string;
    configuration: any;
    toolbarDefaults?: (string | [string, any])[]
    contextProvider?: ActionContextProvider;
    notificationHandler?: (message: Notification) => void;
    hideEmptyToolbar?: boolean;
}
export function CardSubPanelWrapper(props: PropsWithChildren<CardSubPanelWrapperProps>) {
    const toolbarUsagePlace = props.configuration?.toolbarUsagePlace || -1;
    const toolbarDefaults = UIFactory.getStandardOptionList([...(props.toolbarDefaults || []), ...(props.configuration?.toolbar || [])]);

    const icon = props.icon ?? props.configuration.icon ?? '';
    const headline = props.headline ?? props.configuration.headline ?? '';

    return (<>
        <CardRibbon icon={icon} headline={headline} />
        <ListToolbar defaultControls={toolbarDefaults}
            usagePlace={toolbarUsagePlace}
            contextProvider={props.contextProvider}
            notificationHandler={props.notificationHandler}
            hideEmpty={props.hideEmptyToolbar} />
        {props.children}
    </>);
}
