import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { WithLabel } from '../../../../components/propertycontrols/LabeledControl';
import { BarePropertyControlProps } from '../../../../components/propertycontrols/PropertyControl';
import { api } from '../../../../utils/api/ApiProvider';
import { tactin } from '../../../../utils/TactinGlobals';
import './MultiSelectValueListControl.css';

type ValueItemType = {
    value: number;
    text: string;
    ordering?: number;
}

type Item = {
    value: number;
    info: string;
    selected: boolean;
}

export function BareMultiselectValueListControl({ value, className = '', configuration, onChange, readOnly }: BarePropertyControlProps) {
    const [values, setValues] = useState<{ all: ValueItemType[], available: ValueItemType[] }>({ all: [], available: [] });
    const popupRef = useRef<HTMLDivElement>(null);
    const [isPopupOpen, setPopupState] = useState(false);
    const toolbarRef = useRef<HTMLDivElement>(null);
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);
    const currentlySelectedItem = useRef<number>(0);

    useEffect(() => {
        if (configuration?.values) {
            const allValues: ValueItemType[] =
                (configuration.values as string[]).map((s, i) => ({ value: i + 1, text: s, ordering: getOrderin(s) }));
            // filtering
            let newValues: ValueItemType[];
            if (configuration.shown)
                newValues = allValues.filter(valueItem => (configuration.shown as number[]).includes(valueItem.value))
            else if (configuration.hidden?.length)
                newValues = allValues.filter(valueItem => !(configuration.hidden as number[]).includes(valueItem.value));
            else
                newValues = [...allValues];
            // sorting
            newValues = newValues.sort((a, b) => {
                if (a.ordering !== undefined && b.ordering !== undefined)
                    return a.ordering - b.ordering;
                else if (a.ordering !== undefined)
                    return -1;
                else if (b.ordering !== undefined)
                    return 1;
                else
                    return a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase());
            })
            setValues({
                all: allValues,
                available: newValues
            })
        }
    }, [configuration]);

    useLayoutEffect(() => {
        if (!popupRef.current)
            return;
        const rect = popupRef.current.getBoundingClientRect();
        const parentRect = popupRef.current.parentElement?.getBoundingClientRect();
        const windowHeight = document.getElementsByTagName('html').item(0)?.clientHeight;
        if (parentRect && (parentRect.top - rect.height < 0))
            popupRef.current.className = 'comboBoxPanel lower';
        else if (rect.top + rect.height > (windowHeight || Infinity))
            popupRef.current.className = 'comboBoxPanel upper';
        else
            popupRef.current.className = 'comboBoxPanel lower';
    });

    useEffect(() => {
        if (isPopupOpen) {
            const handler = (e: MouseEvent) => {
                if (!popupRef.current?.contains((e.target as HTMLElement)))
                    setPopupState(false);
            }
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    }, [isPopupOpen])

    function setValue(v: number[]) {
        if (v === value) {
            setPopupState(false);
        } else {
            onChange(v || null);
            setPopupState(false);
        }
    }

    const getCurrentText = () =>
        !value ? '' : tactin().configuration.translate('SelectableOptions');

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => ['Escape', 'Enter'].includes(e.key) && e.currentTarget.blur();

    useEffect(() => {
        if (value && values && values.available && values.available.length > 0) {
            const items = (value as number[]).map(v => {
                const item = values.available.find(p => p.value === v);
                
                if (item) {
                    const newItem = {} as Item;
                    newItem.info = item.text;
                    newItem.selected = false;
                    newItem.value = item.value;

                    return newItem;
                }
            });
            
            if (items) {
                setSelectedItems(items as Item[]);
            } else {
                setSelectedItems([]);
            }
        } else {
            setSelectedItems([]);
        }

        currentlySelectedItem.current = 0;
    }, [value, values])

    function selectListItem(v: string) {
        const newItemsList = selectedItems.map(item => {
            if (item.value === Number(v)) {
                item.selected = true;
                currentlySelectedItem.current = item.value;
            } else
                item.selected = false;
            return item;
        })
        setSelectedItems(newItemsList);
    }

    function deleteItem() {
        if (currentlySelectedItem.current) {
            const newValue = value.filter((i: number) => i !== currentlySelectedItem.current)
            onChange(newValue);
            currentlySelectedItem.current = 0;
        }
    }

    const getItemsList = () => {
        if (selectedItems.length) {
            return selectedItems.map(item => {
                return <div key={item.value}
                    id={item.value.toString()}
                    className={item.selected ? 'i2miControlChosenItem' : 'i2miControlItem'}
                    onClick={(e) => selectListItem(e.currentTarget.id)}>{item.info}</div>
                });
        } else
            return null;
    }

    function onItemClick(id: number) {
        setPopupState(false)
        let isContainId = false;

        if (!value) {
            setValue([]);
        } else {
            value.forEach((item: number) => {
                if (item === id)
                    isContainId = true;
            })
            if (!isContainId) {
                const newValue = [...value];
                newValue.push(id);
                setValue(newValue);
            }
        }
    }

    return (<>
        <div className='i2miControl multiselect-toolbar'>
            <div className={`i2miToolbar ${readOnly ? 'disabledControl' : ''}`} ref={toolbarRef}>
                <div className='i2miToolbarLabel'>{tactin().configuration.translate("ChooseItem")}</div>
                <div className='i2miToolbarButtonsBlock'>
                    <button type='button' className='i2miRemoveButton' onClick={deleteItem}>-</button>
                </div>
            </div>
            {selectedItems.length > 0
                ? <div className={`controlList ${readOnly ? 'disabledControl' : ''}`}>{getItemsList()} </div>
                : <div className='controlPseudoList'></div>
            }
        </div>
        <div className={className}>
            <input type='text'
                readOnly
                disabled={readOnly}
                value={getCurrentText()}
                className={`itemComboBoxTextArea`}
                onClick={() => !readOnly && setPopupState(true)}
                onKeyDown={onKeyDown} />
            <button type='button'
                className='itemComboBoxExpandButton'
                disabled={readOnly}
                onClick={() => setPopupState(true)}
                onKeyDown={onKeyDown}>V</button>
            {isPopupOpen &&
                <div ref={popupRef}>
                    <select className='comboBoxListArea'
                        size={Math.min(values.available.length + 1, 6)}
                        onKeyDown={onKeyDown} >
                        <option value='0' onClick={() => setValue([])}></option>
                        {values.available.map(item =>
                            <option key={item.value}
                                value={item.value}
                                onClick={() => onItemClick(item.value)}>{item.text}</option>)}
                    </select>
                </div>}
        </div>
        </>);
}

export const MultiSelectValueListControl = WithLabel(BareMultiselectValueListControl);

function getOrderin(text: string) {
    const match = text.match(/^\d+/);
    if (match)
        return Number(match[0]);
}
