import React, { ChangeEvent, useEffect, useState } from 'react';
import { api } from '../../../utils/api/ApiProvider';
import { StatisticsCalculationResult } from '../../../utils/api/Results';
import { tactin } from '../../../utils/TactinGlobals';
import { AnalysisResult } from '../../model/AnalysisResult';
import { FunctionParameters, StatisticFunction } from '../../model/StatisticFunction';
import ValidatePercentyleParameter from '../../model/validators/PercentyleParametersValidator';
import { ValidationResult } from '../../model/validators/ValidationResult';
import { InstrumentSelection } from '../ui/BasicDataTable';
import ChooseValuesPopup from '../ui/ChooseValuesPopup';

import './StatisticalAnalysisResults.css';

type StatisticalAnalysisResultsProps = {
    selectedInstruments: InstrumentSelection[];
    totalInstrumentsCount: number;
    analysisType: number;
    currentlySelectedFunctions: StatisticFunction[];
    isAnalysisClosed: boolean;
    saveSelectedFunctions: (functions: StatisticFunction[]) => void;
    updateValidationStatus: (status: ValidationResult) => void;
};

export default function StatisticalAnalysisResults(props: StatisticalAnalysisResultsProps) {

    const availableFunctions: StatisticFunction[] = [
        { name: "MinMax", translatedName: "Przedziały minimum - maksimum", parameters: [] },
        {
            name: "PercentileRanges", translatedName: "Przedziały percentylowe", parameters:
                [{ name: "HigherPercentile", translatedName: tactin().configuration.translate('HigherPercentile'), value: 99 },
                { name: 'LowerPercentile', translatedName: tactin().configuration.translate('LowerPercentile'), value: 1 }]
        },
        { name: "InterquartileRanges", translatedName: "Przedziały międzykwartylowe", parameters: [] },
        { name: "Median", translatedName: "Mediana", parameters: [] },
        { name: "Average", translatedName: "Średnia", parameters: [] },
        {
            name: "PercentyleAverage", translatedName: "Średnia arytmetyczna przedziału percentylowego", parameters:
                [{ name: 'HigherPercentile', translatedName: tactin().configuration.translate('HigherPercentile'), value: 99 },
                { name: 'LowerPercentile', translatedName: tactin().configuration.translate('LowerPercentile'), value: 1 }]
        },
        { name: "InterquartileAverage", translatedName: "Średnia arytmetyczna przedziału międzykwartylowego", parameters: [] },
        { name: "StandardDeviation", translatedName: "Odchylenie standardowe od mediany", parameters: [] }
    ];

    const [selectedFunctions, setSelectedFunction] = useState<StatisticFunction[]>(props.currentlySelectedFunctions);
    const [statisticalFunctionChoosePopupVisible, setStatisticalFunctionChoosePopupVisible] = useState<boolean>(false);

    const [analysisResults, setAnalysisResults] = useState<AnalysisResult>();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        props.updateValidationStatus({ isValid: true, source: 'statisticFuntions' });
    }, []);

    const validateParameters = () => {
        let validationResult = true;

        // TODO: dobrze by było przerobić walidację parametrów, by uwzględniała warunki dla indywiduanych funkcji
        if (selectedFunctions && selectedFunctions.length > 0) {
            const functionsWithParameters = [...selectedFunctions.filter(f => f.parameters.length > 0)];

            for (const f of functionsWithParameters) {
                validationResult = ValidatePercentyleParameter(f.parameters[0].value, f.parameters[1].value);
            }
        }

        return validationResult;
    }

    const getTitle = () => {
        let title = props.analysisType === 30
            ? 'MarginAnalysis'
            : 'InterestAnalysis';

        return tactin().configuration.translate(title);
    }

    const getFunctionLabels = () => {
        return availableFunctions.map(f => f.translatedName);
    }

    const toggleStatisticalFunctionChoosePopup = () => {
        setStatisticalFunctionChoosePopupVisible(!statisticalFunctionChoosePopupVisible);
    }

    const changeFunctions = (newFunctions: string[]) => {
        let tempSelected: StatisticFunction[] = [];

        newFunctions.forEach(nf => {
            const existingIndex = selectedFunctions.findIndex(f => f.translatedName === nf);

            if (existingIndex === -1) {
                const existing = availableFunctions.find(af => af.translatedName === nf);

                if (existing) {
                    tempSelected.push(existing);
                }
            } else {
                tempSelected.push(selectedFunctions[existingIndex]);
            }
        });

        setSelectedFunction(tempSelected);

        toggleStatisticalFunctionChoosePopup();
    }

    useEffect(() => {
        runCalculations();
        props.saveSelectedFunctions(selectedFunctions);
    }, [selectedFunctions, props.selectedInstruments])

    const updateParameter = (f: StatisticFunction, parameterName: string, e: ChangeEvent<HTMLInputElement>) => {
        const param = f.parameters.find(p => p.name === parameterName);

        if (param) {
            param.value = Number(e.target.value) || 0;
        }

        setSelectedFunction([...selectedFunctions]);
    }

    const getClassNameBasedOnParameterContent = (parameters: FunctionParameters[] | null) => {
        return parameters
            ? 'single-function'
            : 'single-function-no-params';
    }

    const runCalculations = () => {
        const validationResult = validateParameters();
        props.updateValidationStatus({ isValid: validationResult, source: 'statisticFuntions' });

        if (validationResult) {
            setError(null);
            const instrumentsToSet = props.selectedInstruments.filter(i => i.isChecked)?.map(i => i.id);

            if (instrumentsToSet && instrumentsToSet.length > 0) {
                api().Cngr.recalculateStatistics({
                    selectedFunctions: selectedFunctions,
                    selectedInstrumentIds: instrumentsToSet,
                    isMarginAnalysis: props.analysisType === 30
                })
                    .then((results: StatisticsCalculationResult) => {
                        setAnalysisResults(results.analysisResult)
                    }
                    );
            }
        } else {
            setError("Wprowadzone parametry funkcji są niepoprawne");
        }
    }

    const showErrors = () => {
        return selectedFunctions
            && selectedFunctions.length > 0
            && selectedFunctions.find(f => f.parameters && f.parameters.length > 0)
            && error;
    }

    return <div className='cngr-statistical-analysis'>
        <div className='header-section'>
            <span className='analysis-title'>{getTitle()}</span>
            {!props.isAnalysisClosed && <div className='toolbar-button' onClick={toggleStatisticalFunctionChoosePopup}>
                <span className="label">{tactin().configuration.translate('ChooseStatisticalFunction')}</span>
            </div>}
        </div>
        <div className='analysis-instrument-count'>
            <span>{tactin().configuration.translate('InstrumentsCount')}: </span>
            <span>{props.totalInstrumentsCount}</span>
        </div>
        <div className='analysis-results'>
            {selectedFunctions && selectedFunctions.map(f => {
                return <div className={getClassNameBasedOnParameterContent(f.parameters)}>
                    <span>{f.translatedName}</span>
                    <div>
                        {analysisResults && analysisResults.functionEntries.map(fe => {
                            if (fe.name === f.name) {
                                return <div>
                                    {fe.value.map(fv => {
                                        return <div className='single-function-result'>
                                            <span className='result-label'>{tactin().configuration.translate(fv.valueName)}</span>
                                            <span className='result-value'>{fv.value}</span>
                                        </div>
                                    })}
                                </div>
                            }
                        })}
                    </div>
                    <div className='parameters'>
                        {f.parameters && f.parameters.map(p => {
                            return <>
                                <label htmlFor={'parameter' + f.name + p.name}>{tactin().configuration.translate(p.translatedName)}</label>
                                <input id={'parameter' + f.name + p.name} type='number' min={1} max={100} onBlur={e => {
                                    updateParameter(f, p.name, e);
                                }} defaultValue={p.value} disabled={props.isAnalysisClosed} />
                            </>
                        })}
                    </div>
                </div>
            })}
        </div>
        {showErrors() && <div className='parameter-errors'>
            <div className='error'>{error}</div>
        </div>}
        {statisticalFunctionChoosePopupVisible &&
            <ChooseValuesPopup
                idPrefix='StatisticalFunctions'
                baseValues={getFunctionLabels()}
                selectedValues={selectedFunctions.map(f => f.translatedName)}
                defaultListSize={8}
                description={tactin().configuration.translate("Choose statistical functions (multiple choice allowed by clicking with CTRL pressed)")}
                onValuesChanged={changeFunctions}
                cancel={toggleStatisticalFunctionChoosePopup} />}
    </div>
};