import React, { useEffect, useRef, useState } from 'react';
import { ListTable, MockRow, SortingHandler } from './ListTable';
import { RowData } from '../../../model/list/cache/RowParser';
import { ListProperties } from '../../../model/list/HelperTypes';
import DataCache from './cache/DataCache';
import { RowCacheNodeBlock } from '../../../model/list/cache/GroupCache';
import { tactin } from '../../../utils/TactinGlobals';
import { OpenItemEvent } from '../../../model/events/TactinEvents';

// TODO: Find better place for that
export type InstrumentSelection = {
    id: number,
    isChecked: boolean;
}

type BasicDataTableProps = {
    listProperties: ListProperties;
    cacheCreator: (prp: ListProperties) => DataCache;
    sortable?: SortingHandler;
    onSelect?: (id: number, showAs: string, highlight?: boolean) => void;
    allowCheckRow: boolean;
    refreshData: (data: RowData[] | null | undefined) => void;
    onInstrumentCheckedChanged: (id: number, checked: boolean) => void;
    getRowCheckedState: (id: number | undefined) => boolean;
    showArticle?: (name: string) => void
};

export function BasicDataTable(props: BasicDataTableProps) {
    const cache = useRef<DataCache>();

    const cacheUpdater = useRef<number>();
    const CACHE_UPDATE_INTERVAL = 30 * 1000;
    const cacheLoader = useRef<number>();
    const CACHE_LOADER_INTERVAL = 300;

    const [reload, setReload] = useState({});

    const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
    const [rowHeight, setRowHeight] = useState(0);

    const scheduleCacheUpdater = () => {
        if (cacheUpdater.current !== undefined)
            clearTimeout(cacheUpdater.current);
        cacheUpdater.current = window.setTimeout(() => {   //without the window, TS takes the setTimeout from node and has a type mismatch
            cache.current?.needsUpdateAsync();
        }, CACHE_UPDATE_INTERVAL);
    }

    const scheduleDataLoad = () => {
        if (cacheLoader.current !== undefined)
            clearTimeout(cacheLoader.current);
        cacheLoader.current = window.setTimeout(() => {
            cache.current && cache.current.loadMissingData();
        }, CACHE_LOADER_INTERVAL);
    }

    const cleanup = () => {
        if (cacheUpdater.current !== undefined)
            clearTimeout(cacheUpdater.current);
    }

    useEffect(() => {
        cache.current = props.cacheCreator(props.listProperties);
        cache.current.onDataChanged = () => {
            getFreshData();
            setReload({})
        };
        cache.current.cacheUpdatedCallback = {
            error: () => scheduleCacheUpdater(),
            cachedReloaded: () => {
                setReload({});
                scheduleCacheUpdater();
            },
            cacheUpToDate: () => scheduleCacheUpdater(),
            cacheOutdated: () => cache.current?.reloadCache(true)
        };

        if (props.listProperties.columnNames.length > 0)
            setVisibleColumns(props.listProperties.columnNames.filter(c => c.startsWith('"')));

        scheduleCacheUpdater();

        return cleanup;
    }, [props.listProperties]);

    const getData = (start: number, length: number) => {
        const output: RowData[] = (cache.current && length) ? cache.current.getData(start, length) : [];
        scheduleDataLoad();
        if (output.length > 0) {
            output.map(r => {
                const isChecked = props.getRowCheckedState(r.id);
                r.isRowChecked = isChecked;
            });
        }

        return output;
    }

    const getFreshData = () => {
        const nodes = cache.current?.dataList.children[0] && cache.current?.dataList.children[0] as RowCacheNodeBlock;
        let rowData: RowData[];

        if (nodes) {
            rowData = [];

            nodes?.rows.map(row => {
                if (row) {
                    row.isRowChecked = props.getRowCheckedState(row.id);
                    rowData.push(row)
                }
            });

            props.refreshData(rowData);
        } else {
            props.refreshData(null);
        }
    }

    const open = (r: RowData) => {
        if (r.ownerId || r.id)
            tactin()?.eventBus.notify(new OpenItemEvent().byItemId(r.ownerId || r.id || 0))
    }

    if (rowHeight > 0) {
        return (<div className='item-list'>
            <ListTable
                rowCount={cache.current?.getVisibleRowCount() || 0}
                rowHeight={rowHeight}
                columns={visibleColumns}
                widths={props.listProperties.columnWidths || []}
                getData={getData}
                sortable={props.sortable}
                aggregations={[]}
                showDescription={false}
                decorate={false}
                onHighLight={r => r.id && props.onSelect && props.onSelect(r.id, r.showAs ?? '', true)}
                onClick={r => r.id && props.onSelect && props.onSelect(r.id, r.showAs ?? '')}
                onDblClick={open}
                finishedLoading={getFreshData}
                allowCheckRow={props.allowCheckRow}
                getRowCheckedState={props.getRowCheckedState}
                showArticle={props.showArticle}
                onInstrumentCheckedChanged={props.onInstrumentCheckedChanged} />
        </div>);
    } else {
        return <MockRow onSizeSet={setRowHeight} />;
    }
}
