import React, { useEffect } from 'react';

export type Named = {
    id: number;
    name: string;
}

type Props = {
    selected: number;
    allowEmpty?: boolean;
    onChange: (v: number) => void;
    children: Named[];
    disabled?: boolean;
}
const emptyNamed = () : Named => ({id: 0, name: ''});

export default function NamedCombo(props: Props) {
    const change = (key: number) => {
        if(!props.disabled && props.onChange) {
            let selectedId: number = props.children.find(n => n.id === key)?.id || 0;
            if(selectedId !== props.selected)
                props.onChange(selectedId);
        }
    }

    if(props.children.length < 1)
        return <span>Nothing to select from...</span>

    let opts = props.children as Named[];

    if(props.allowEmpty)
        opts = [emptyNamed(), ...opts];
    let toSelect: number;
    if(!opts.find(n => n.id === props.selected)) {
        if(props.allowEmpty)
            toSelect = 0;
        else
            toSelect = opts[0]?.id || 0;
    }
    else
        toSelect = props.selected;

    useEffect(() => {
        change(toSelect);
    }, [])

    return (
        <select className='edit-field'
                onChange={e => change(+e.target.value)}
                value={toSelect}
                disabled={props.disabled} >
            {opts.map(n => (<option value={n.id} key={n.id}>{n.name}</option>))}
        </select>);
}
