import { CacheNode } from '../../../../model/list/cache/CacheNode';
import { Region } from '../../../../model/list/cache/Region';
import { RowParserFn, RowData } from '../../../../model/list/cache/RowParser';
import { ColumnFilter } from '../../../../model/list/ColumnFilter';
import { ChangeList, getRowCount, ListProperties } from '../../../../model/list/HelperTypes';
import { CacheLoaderStack } from '../../../../model/list/loader/CacheLoaderStack';
import { HandleCleanup, HandleErrors } from '../../../../utils/api/ApiErrorHandler';
import { api } from '../../../../utils/api/ApiProvider';
import { ListDataResult } from '../../../../utils/api/Results';
import MutableRange from '../../../../utils/MutableRange';


/**
 * How often is the start value of the cache preserved. If the index of a
 * fold subtree list mod the interval is 0 then, that row will have a
 * special start value, which tells how many visible rows are before it in
 * the subtree.
 *
 * For long lists this allows to jump by the interval to look pinpoint the
 * start of the requested range.
 */
export const START_CACHE_INTERVAL = 100;
export function nextStartCacheIndex(i: number): number {
    return (Math.floor(i / START_CACHE_INTERVAL) + 1) * START_CACHE_INTERVAL;
}

export const ROW_KEY_DELIMITER = '»';

export type FoldOpenedCallback = (newRowCount: number) => void;

export type CacheReloadHandler = {
    cacheUpToDate: () => void;
    cacheOutdated: () => void;
    cachedReloaded: () => void;
    error: () => void;
}

class ReloadState {
    opened: Map<string, number>;
    toLoad: Map<string, number>;
    parents: Map<string, CacheNode>;
    level: number;

    constructor(opened: Map<string, number>) {
        this.opened = opened;
        this.toLoad = new Map();
        this.parents = new Map();
        this.level = 0;
    }
}

export default abstract class DataCache {

    _dataProvider: string = '';
    _sortColumn: string = '';
    _sortDirection: ('ASC' | 'DESC') = 'ASC';
    _wildcards: string[] = [];
    _filters: ColumnFilter[] = [];

    dataList: CacheNode;

    cacheUpdatedCallback?: CacheReloadHandler;
    dataLoadedCallback?: (data: RowData[]) => void;

    defaultCacheSegmentSize: number = 0;
    oldesCachedValue: number = 0;

    foldOpened?: FoldOpenedCallback;

    loader: CacheLoaderStack;
    cacheReloader: CacheLoaderStack;
    
    reloadState: ReloadState | null = null;

    openedSet: Map<string, number>;
    
    rowParser?: RowParserFn;

    constructor(dataList: CacheNode, defaultCacheSegmentSize: number, openedSet: Map<string, number>, rowParser?: RowParserFn ) {
        this.dataList = dataList;
        this.openedSet = openedSet;
        this.rowParser = rowParser;

        this.defaultCacheSegmentSize = defaultCacheSegmentSize;
        this.loader = this.getCacheLoader(defaultCacheSegmentSize);
        this.cacheReloader = this.createCacheReloader();
    }

    getCacheLoader(defaultCacheSegmentSize: number): CacheLoaderStack {
        const l = new CacheLoaderStack(defaultCacheSegmentSize);
        l.loader = this.getListData.bind(this);
        l.loadedCallback = (result, parent, start, length, timestamp) => {
            this.addData(parent, start, result, timestamp);
            // TactinEvents.getTactinEventBus().fireEventFromSource(
            //     new DataLoadedEvent(), DataSource.this);
        }
        l.allDataLoaded = (loadCount: number) => {
            return loadCount && this.onDataChanged && this.onDataChanged()
        };

        return l;
    }

    setRowCount(rowCount: number): void {
        this.dataList.childCount = rowCount;
        this.dataList.setVisibleRows(rowCount);
    }

    getVisibleRowCount(): number {
        return this.dataList.getVisibleRows();
    }

    set dataProvider(p: string) {
        this._dataProvider = p;
        this.clear();
    }
    get dataProvider() {
        return this._dataProvider;
    }

    setSort(column: string, direction: 'ASC' | 'DESC') {
        this._sortColumn = column;
        this._sortDirection = direction;
        this.clear();
    }
    get sortColumn() {
        return this._sortColumn;
    }

    get sortDirection() {
        return this._sortDirection;
    }

    set wildcards(selectWildcards: string[]) {
        this.clear();
        this._wildcards = [...selectWildcards];
    }
    get wildcards() {
        return [...this._wildcards];
    }

    set filters(filters: ColumnFilter[]) {
        this.clear();
        this._filters = [...filters];
    }
    get filters() {
        return [...this._filters];
    }

    inUpdateCheck: boolean = false;
    needsUpdateAsync() {
        if (this.inUpdateCheck)
            return;
        this.inUpdateCheck = true;

        api().List.getChangedFromDate(this._dataProvider, this.oldesCachedValue)
            .then(idList => {
                const outdated = this.validateCache(idList);
                if (!outdated)
                    this.oldesCachedValue = idList.timestamp;

                if (this.cacheUpdatedCallback) {
                    if (outdated)
                        this.cacheUpdatedCallback.cacheOutdated();
                    else
                        this.cacheUpdatedCallback.cacheUpToDate();
                }
                this.inUpdateCheck = false;
            }).catch(HandleCleanup(() => {
                if (this.cacheUpdatedCallback != null)
                    this.cacheUpdatedCallback.error();
                this.inUpdateCheck = false;
            })).catch(HandleErrors(true));
    }

    validateCache(idList: ChangeList): boolean {
        return idList.modifiedItems.length + idList.createdItems.length > 0;
    }

    isOpen(row: RowData, parent?: CacheNode): boolean {
        return this.openedSet.has(this.getRowKey(row, parent));
    }

    clear(): void {
        this.dataList.clear();
        this.oldesCachedValue = 0;
    }

    resetState() {
        this.clear();
        this.loader.clear();
        this.openedSet.clear();
    }

    onDataChanged?: () => void;

    //--------------------------------------------------------------------------

    getData(start: number, length: number): RowData[] {
        const data = this.dataList.getData(start, length, this.loader.pushLoad.bind(this.loader));
        
        this.dataLoadedCallback && this.dataLoadedCallback(data);
        
        return data;
    }

    addData(group: CacheNode | null, start: number, rows: string[][], timestamp: number): boolean {

        group = group || this.dataList;

        //find visible start of the new region
        let visibleStart = start;
        let before_index = -1;
        for (let i = 0; i < group.children.length; i++) {
            if (group.children[i].start < start)
                before_index = i;
            else
                break;
        }
        if (before_index > -1) {
            const br: Region = group.children[before_index];
            visibleStart = br.visibleStart + br.visibleRows + (start - br.start - br.getChildCount());
        }

        const cr: Region = this.getNewRegion(group, start, rows, visibleStart);

        let before_merged = false;
        let after_merged = false;
        if (before_index > -1)
            before_merged = group.children[before_index].merge(cr);
        if (!before_merged && before_index + 1 < group.children.length)
            after_merged = cr.merge(group.children[before_index + 1]);
        if (after_merged)
            group.children[before_index + 1] = cr;
        if (!before_merged && !after_merged)
            group.children.splice(before_index + 1, 0, cr);
        else if ((before_merged && before_index + 1 < group.children.length) || (after_merged && before_index > -1))
            if (group.children[before_index].merge(group.children[before_index + 1]))
                group.children.splice(before_index + 1, 1);

        if (!this.oldesCachedValue)
            this.oldesCachedValue = timestamp;

        return true;
    }

    abstract getNewRegion(fold: CacheNode, start: number, rows: string[][], visibleStart: number): Region;

    loadMissingData() {
        this.loader.loadMissing();
    }

    toggleFold(node: string | CacheNode | null): void {
        if (typeof node === 'string') {
            const cf = this.findFold(node);
            this.toggleFold(cf);
            if (this.foldOpened)
                this.foldOpened(this.dataList.getVisibleRows());
        } else {
            if (node == null)
                return;
            node.toggleFold();
            if (this.openedSet.has(node.getNodeKey()))
                this.openedSet.delete(node.getNodeKey());
            else
                this.openedSet.set(node.getNodeKey(), node.getRowPositionInBlock());
        }
    }

    findFold(key: string): CacheNode | null {
        const values = key.split(ROW_KEY_DELIMITER, -1);
        let currentKey = '';
        let result: CacheNode | null = this.dataList;
        for (const v of values) {
            currentKey += v;
            result = this.findFoldRec(result, currentKey);
            if (!result)
                return null;
            currentKey += ROW_KEY_DELIMITER;
        }

        return result;
    }

    findFoldRec(parent: CacheNode, value: string): CacheNode | null {
        if (parent.children)
            for (const region of parent.children) {
                for (const node of region.getChildren()) {
                    if (node.getNodeKey() === value)
                        return node;
                }
            }

        return null;
    }

    getFoldingKey(fold: CacheNode | null): string {
        if (!fold)
            return '';

        if (fold.parentRegion !== null)
            return this.getRowKey(fold.rowData, fold.parentRegion.getParentGroup());
        else
            return this.getRowKey(fold.rowData);
    }

    abstract getRowKey(row: RowData, parent?: CacheNode): string;

    //==========================================================================
    // CACHE RELOADER
    //==========================================================================

    createCacheReloader(): CacheLoaderStack {
        const l = new CacheLoaderStack(this.defaultCacheSegmentSize);
        l.loader = this.getListData.bind(this);
        l.loadedCallback = (result, parent, start, length, timestamp) =>
            this.addData(parent, start, result, timestamp);
        l.allDataLoaded = () => this.continueReload();
        return l;
    }

    abstract getListData(parent: CacheNode, start: number, length: number): Promise<ListDataResult>;

    reloadCache(preserveOpened: boolean) {

        this.clear();
        this.reloadState = new ReloadState(preserveOpened ? this.openedSet : new Map());
        this.openedSet = new Map();
        this.getListProperties()
            .then(properties => {
                if (!this.reloadState)
                    return;

                this.setRowCount(getRowCount(properties));

                // this.reloadState.parents.set('', this.dataList);
                this.reloadState.level = 1;
                this.loadNLevel();
            }).catch(HandleCleanup(() => this.cacheUpdatedCallback && this.cacheUpdatedCallback.error()))
            .catch(HandleErrors(true));
    }

    abstract getListProperties(): Promise<ListProperties>;

    loadNLevel() {
        if (!this.reloadState)
            return;

        this.reloadState.toLoad = new Map();

        for (const [key, value] of this.reloadState.opened.entries()) {
            if (key.split(ROW_KEY_DELIMITER, -1).length !== this.reloadState.level)
                continue;
            this.reloadState.toLoad.set(key, value);
        }
        for (const k of this.reloadState.toLoad.keys())
            this.reloadState.opened.delete(k);

        if (this.reloadState.toLoad.size === 0) {
            this.continueReload();
            return;
        }

        const rangesMap: Map<string | null, MutableRange[]> = new Map();
        loaded: for (const key of this.reloadState.toLoad.keys()) {
            const parentKey = this.getParentKey(key);
            const parentNode = parentKey !== null ? this.reloadState.parents.get(parentKey) : this.dataList;
            if (!parentNode)
                continue;

            if (parentNode.childCount > this.defaultCacheSegmentSize) {
                let ranges = rangesMap.get(parentKey);
                if (!ranges)
                    rangesMap.set(parentKey, (ranges = []))
                const pos = this.reloadState.toLoad.get(key);
                if (pos === undefined)
                    continue;
                for (let i = 0; i < ranges.length; i++)//const r of ranges)
                    if (ranges[i].contains(pos) || ranges[i].include(pos, this.defaultCacheSegmentSize))
                        continue loaded;
                ranges.push(MutableRange.around(pos, 3).normalize());

                let prev: MutableRange | null = null;
                for (const r of MutableRange.normalizeList(ranges, parentNode.childCount)) {
                    this.cacheReloader.pushLoad(parentNode, r.start, r.length,
                        (prev === null ? 0 : prev.end + 1), parentNode.childCount);
                    prev = r;
                }
            } else {
                this.cacheReloader.pushLoad(parentNode, 0, parentNode.childCount, 0, parentNode.childCount);
            }
        }
        this.cacheReloader.loadMissing();
    }

    getParentKey(key: string) {
        const index = key.lastIndexOf(ROW_KEY_DELIMITER);
        if (index == -1)
            return null;
        else
            return key.substring(0, index);
    }

    continueReload() {
        if (!this.reloadState)
            return;

        for (const key of this.reloadState.toLoad.keys()) {
            const parentKey = this.getParentKey(key);
            const parent = parentKey !== null ? this.reloadState.parents.get(parentKey) : this.dataList;
            let child: CacheNode | null = null;
            if (parent)
                child = this.findFoldRec(parent, key);
            if (child) {
                this.reloadState.parents.set(key, child);
                this.toggleFold(child);
            }
        }
        if (this.reloadState.toLoad.size && this.reloadState.opened.size) {
            ++this.reloadState.level;
            this.loadNLevel();
        } else {
            this.reloadState = null;
            this.cacheUpdatedCallback && this.cacheUpdatedCallback.cachedReloaded();
        }
    }
}
