import { CacheNode, MissingDataCallback } from '../../../../model/list/cache/CacheNode';
import { Region } from '../../../../model/list/cache/Region';
import { RowParserFn, RowData } from '../../../../model/list/cache/RowParser';
import DataCache, { nextStartCacheIndex, START_CACHE_INTERVAL } from './DataCache';

export abstract class CacheNodeBlock implements Region {
    parent: CacheNode;
    rows: CacheNode[];
    start: number;
    visibleStart: number;
    visibleRows: number;

    constructor(parent: CacheNode,
            start: number,
            rows: string[][],
            visibleStart: number,
            visibleRows: number,
            rowParser?: RowParserFn,
            source?: DataCache)
    {
        this.start = start;
        this.parent = parent;
        this.visibleStart = visibleStart;
        this.visibleRows = visibleRows;
        this.rows = [];
        if(rows)
            for(const row of rows)
                this.rows.push(this.getNew(row, rowParser, source));
    }

    abstract getNew(row: string[], rowParser?: RowParserFn, source?: DataCache): CacheNode;

    getParentGroup() {
        return this.parent;
    }

    getChildren() {
        return this.rows;
    }

    getChildCount() {
        return this.rows.length;
    }

    modVisibleStart(mod: number) {
        this.visibleStart += mod;
    }

    getChildCount2(row: string[] | null)
    {
        if(row != null && row.length > 1)
        {
            const count = Number(row[row.length - 1]);
            if(!Number.isNaN(count))
                return count;
        }
        return 0;
    }

    /*
     * Assumes that this region is followed by the given region.
     */
    merge(region: Region): boolean
    {
        const overlap_length = this.start + this.rows.length - region.start;
        let result = false;
        if(overlap_length === 0)
        {
            this.rows.push(...region.getChildren());
            this.visibleRows += region.visibleRows;
            result = true;
        }
        else if(region.start >= this.start && overlap_length > 0)
        {
            if(overlap_length < region.getChildren().length)
            {
                this.rows.push(...region.getChildren().slice(overlap_length, region.getChildren().length));
                if(overlap_length > region.getChildren().length / 2)
                {
                    //add the last visible elements
                    for(let i = overlap_length; i < region.getChildren().length; i++)
                        this.visibleRows += region.getChildren()[i].getVisibleRows() + 1;
                }
                else
                {
                    //substract the first visible elements
                    this.visibleRows += region.visibleRows;
                    for(let i = 0; i < overlap_length; i++)
                        this.visibleRows -= region.getChildren()[i].getVisibleRows() + 1;
                }
            }
            result = true;
        }
        if(result)
            this.rows.forEach(n => n.parentRegion = this);
        return result;
    }

    setVisibleRows(mod: number, index: number): void {
        this.visibleRows += mod;
        if(index > -1) {
            for(let i = nextStartCacheIndex(index);
                    i < this.rows.length;
                    i += START_CACHE_INTERVAL)
                this.rows[i].modVisiblePosition(mod);
        }
        if(parent != null)
        {
            this.parent.setVisibleRows(mod, this);
        }
    }

    getData(start: number, length: number, loader: MissingDataCallback): RowData[] {

        let result: RowData[] = [];

        let startIndex = 0;
        for(; startIndex < this.getChildren().length;
                startIndex = nextStartCacheIndex(startIndex + this.start) - this.start)
            if(this.getChildren()[startIndex].visiblePosition > start)
                break;
        startIndex =
                Math.max(startIndex - START_CACHE_INTERVAL, 0);

        if(this.getChildren()[startIndex].visiblePosition > 0)
            start -= this.getChildren()[startIndex].visiblePosition;
        else
            start -= this.visibleStart;
        for(let i = startIndex; i < this.getChildren().length && length > 0; i++)
        {
            const fold = this.getChildren()[i];
            if(fold.getVisibleRows() < start)
                start -= (fold.getVisibleRows() + 1);
            else
            {
                if(start == 0)
                {
                    result.push({...fold.rowData, columns: [...fold.rowData.columns]});
                    length--;
                }
                else
                    start--;
                if(fold.open)
                {
                    const loadedRows = fold.getData(start, length, loader);
                    result.push(...loadedRows);
                    length -= loadedRows.length;
                    if(loadedRows.length > 0)
                        start = 0;
                }
            }
        }
        return result;
    }

    lastRow(): number {
        return this.start + this.rows.length;
    }
}
