import React, { useEffect, useState } from 'react';
import { ValueListControl } from '../../../../components/propertycontrols/ValueListControl';
import { ColumnFilter, ComboColumnFilter, FilterRange, FilterType, getFilterValue, MultiselectComboColumnFilter, RangeColumnFilter } from '../../../../model/list/ColumnFilter';
import { MinMaxRange } from '../../../model/MinMaxResults';
import { MultiSelectValueListControl } from '../controls/MultiselectValueListControl';
import { NumberRangeControl } from '../controls/NumberRangeControl';
import { CustomFilterPropertyControlProps } from './CustomFilterPropertyControlProps';

export default function SubjectIndicatorsFilters(props: CustomFilterPropertyControlProps) {
    let altmanEmScoreFilter = props.filters.find(f => f.columnAlias === 'Altman EM-Score') as RangeColumnFilter;
    let altmanEmScoreInterpretationFilter = props.filters.find(f => f.valueColumn === 'pv_58') as MultiselectComboColumnFilter;
    let emaczynskaValueFilter = props.filters.find(f => f.columnAlias === 'Model E. Mączyńskiej') as RangeColumnFilter;
    let emaczynskaInterpretationFilter = props.filters.find(f => f.valueColumn === 'pv_60') as MultiselectComboColumnFilter;
    let debtHandlingIndicatorFilter = props.filters.find(f => f.columnAlias === 'Wskaźnik obsługi długu') as RangeColumnFilter;
    let debtHandlingRatingFilter = props.filters.find(f => f.valueColumn === 'pv_62') as MultiselectComboColumnFilter;
    let returnOnSalesIndicatorFilter = props.filters.find(f => f.columnAlias === 'ROS') as RangeColumnFilter;
    let returnOnAssetsIndicatorFilter = props.filters.find(f => f.columnAlias === 'ROA') as RangeColumnFilter;
    let returnOnEquityIndicatorFilter = props.filters.find(f => f.columnAlias === 'ROE') as RangeColumnFilter;
    let generalDebtIndicatorFilter = props.filters.find(f => f.columnAlias === 'Wskaźnik ogólnego zadłużenia') as RangeColumnFilter;
    let longTermDebtIndicatorFilter = props.filters.find(f => f.columnAlias === 'Wskaźnik zadłużenia długoterminowego') as RangeColumnFilter;
    let interestPaymentIndicatorFilter = props.filters.find(f => f.columnAlias === 'Wskaźnik zdolności do spłaty odsetek') as RangeColumnFilter;
    let mainRatingFilter = props.filters.find(f => f.valueColumn === 'pv_69') as MultiselectComboColumnFilter;

    const [altmanEmScoreRanges, setAltmanEmScoreRanges] = useState<MinMaxRange | undefined>();
    const [emaczynskaValueRanges, setEmaczynskaValueRanges] = useState<MinMaxRange | undefined>();
    const [debtHandlingIndicatorRanges, setDebtHandlingIndicatorRanges] = useState<MinMaxRange | undefined>();
    const [returnOnSalesIndicatorRanges, setReturnOnSalesIndicatorRanges] = useState<MinMaxRange | undefined>();
    const [returnOnAssetsIndicatorRanges, setReturnOnAssetsIndicatorRanges] = useState<MinMaxRange | undefined>();
    const [returnOnEquityIndicatorRanges, setReturnOnEquityIndicatorRanges] = useState<MinMaxRange | undefined>();
    const [generalDebtIndicatorRanges, setGeneralDebtIndicatorRanges] = useState<MinMaxRange | undefined>();
    const [longTermDebtIndicatorRanges, setLongTermDebtIndicatorRanges] = useState<MinMaxRange | undefined>();
    const [interestPaymentIndicatorRanges, setInterestPaymentIndicatorRanges] = useState<MinMaxRange | undefined>();

    const [altmanEmScoreValue, setAltmanEmScoreValue] = useState<FilterRange>();
    const [altmanEmScoreInterpretationValue, setAltmanEmScoreInterpretationValue] = useState<number[]>([]);
    const [emaczynskaValue, setEmaczynskaValue] = useState<FilterRange>();
    const [emaczynskaInterpretationValue, setEmaczynskaInterpretationValue] = useState<number[]>([]);
    const [debtHandlingIndicatorValue, setDebtHandlingIndicatorValue] = useState<FilterRange>();
    const [debtHandlingRatingValue, setDebtHandlingRatingValue] = useState<number[]>([]);
    const [returnOnSalesIndicatorValue, setReturnOnSalesIndicatorValue] = useState<FilterRange>();
    const [returnOnAssetsIndicatorValue, setReturnOnAssetsIndicatorValue] = useState<FilterRange>();
    const [returnOnEquityIndicatorValue, setReturnOnEquityIndicatorValue] = useState<FilterRange>();
    const [generalDebtIndicatorValue, setGeneralDebtIndicatorValue] = useState<FilterRange>();
    const [longTermDebtIndicatorValue, setLongTermDebtIndicatorValue] = useState<FilterRange>();
    const [interestPaymentIndicatorValue, setInterestPaymentIndicatorValue] = useState<FilterRange>();
    const [mainRatingValue, setMainRatingValue] = useState<number[]>([]);

    const applyInitialFilters = (filters: ColumnFilter[]) => {
        if (filters) {
            const altmanEmScore = (filters.find(f => f.columnAlias === 'Altman EM-Score') as RangeColumnFilter).range;

            if (altmanEmScore) {
                altmanEmScoreFilter.range = altmanEmScore ? altmanEmScore : altmanEmScoreRanges as FilterRange;
            } 

            setAltmanEmScoreValue(altmanEmScore || altmanEmScoreRanges);

            altmanEmScoreInterpretationFilter.selected = (filters.find(f => f.valueColumn === 'pv_58') as MultiselectComboColumnFilter).selected;
            setAltmanEmScoreInterpretationValue(getFilterValue(altmanEmScoreInterpretationFilter));

            const emaczynska = (filters.find(f => f.columnAlias === 'Model E. Mączyńskiej') as RangeColumnFilter).range;

            if (emaczynska) {
                emaczynskaValueFilter.range = emaczynska ? emaczynska : emaczynskaValueRanges as FilterRange;
            }

            setEmaczynskaValue(emaczynska || emaczynskaValueRanges);

            emaczynskaInterpretationFilter.selected = (filters.find(f => f.valueColumn === 'pv_60') as MultiselectComboColumnFilter).selected;
            setEmaczynskaInterpretationValue(getFilterValue(emaczynskaInterpretationFilter));

            const debtHandlingIndicator = (filters.find(f => f.columnAlias === 'Wskaźnik obsługi długu') as RangeColumnFilter).range;

            if (debtHandlingIndicator) {
                debtHandlingIndicatorFilter.range = debtHandlingIndicator ? debtHandlingIndicator : debtHandlingIndicatorRanges as FilterRange;
            }

            setDebtHandlingIndicatorValue(debtHandlingIndicator || debtHandlingIndicatorRanges);

            debtHandlingRatingFilter.selected = (filters.find(f => f.valueColumn === 'pv_62') as MultiselectComboColumnFilter).selected;
            setDebtHandlingRatingValue(getFilterValue(debtHandlingRatingFilter));

            const returnOnSalesIndicator = (filters.find(f => f.columnAlias === 'ROS') as RangeColumnFilter).range;

            if (returnOnSalesIndicator) {
                returnOnSalesIndicatorFilter.range = returnOnSalesIndicator ? returnOnSalesIndicator : returnOnSalesIndicatorRanges as FilterRange;
            }

            setReturnOnSalesIndicatorValue(returnOnSalesIndicator || returnOnSalesIndicatorRanges);

            const returnOnAssetsIndicator = (filters.find(f => f.columnAlias === 'ROA') as RangeColumnFilter).range;

            if (returnOnAssetsIndicator) {
                returnOnAssetsIndicatorFilter.range = returnOnAssetsIndicator ? returnOnAssetsIndicator : returnOnAssetsIndicatorRanges as FilterRange;
            }

            setReturnOnAssetsIndicatorValue(returnOnAssetsIndicator || returnOnAssetsIndicatorRanges);

            const returnOnEquityIndicator = (filters.find(f => f.columnAlias === 'ROE') as RangeColumnFilter).range;

            if (returnOnEquityIndicator) {
                returnOnEquityIndicatorFilter.range = returnOnEquityIndicator ? returnOnEquityIndicator : returnOnEquityIndicatorRanges as FilterRange;
            }

            setReturnOnEquityIndicatorValue(returnOnEquityIndicator || returnOnEquityIndicatorRanges);

            const generalDebtIndicator = (filters.find(f => f.columnAlias === 'Wskaźnik ogólnego zadłużenia') as RangeColumnFilter).range;

            if (generalDebtIndicator) {
                generalDebtIndicatorFilter.range = generalDebtIndicator ? generalDebtIndicator : generalDebtIndicatorRanges as FilterRange;
            }

            setGeneralDebtIndicatorValue(generalDebtIndicator || generalDebtIndicatorRanges);

            const longTermDebtIndicator = (filters.find(f => f.columnAlias === 'Wskaźnik zadłużenia długoterminowego') as RangeColumnFilter).range;

            if (longTermDebtIndicator) {
                longTermDebtIndicatorFilter.range = longTermDebtIndicator ? longTermDebtIndicator : longTermDebtIndicatorRanges as FilterRange;
            }

            setLongTermDebtIndicatorValue(longTermDebtIndicator || longTermDebtIndicatorRanges);

            const interestPaymentIndicator = (filters.find(f => f.columnAlias === 'Wskaźnik zdolności do spłaty odsetek') as RangeColumnFilter).range;

            if (interestPaymentIndicator) {
                interestPaymentIndicatorFilter.range = interestPaymentIndicator ? interestPaymentIndicator : interestPaymentIndicatorRanges as FilterRange;
            }

            setInterestPaymentIndicatorValue(interestPaymentIndicator || interestPaymentIndicatorRanges);

            mainRatingFilter.selected = (filters.find(f => f.valueColumn === 'pv_69') as MultiselectComboColumnFilter).selected;
            setMainRatingValue(getFilterValue(mainRatingFilter));
        }
    };

    useEffect(() => {
        if (props.savedFilters && props.applySavedFilters) {
            applyInitialFilters(props.savedFilters);
        } else if (props.filters && props.resetFilters) {
            applyInitialFilters(props.filters);
        }
    }, [props.filters]);

    useEffect(() => {
        if (props.ranges && props.ranges.length > 0) {
            setAltmanEmScoreRanges(props.ranges.find(r => r.propertyId === 57)?.range);
            setEmaczynskaValueRanges(props.ranges.find(r => r.propertyId === 59)?.range);
            setDebtHandlingIndicatorRanges(props.ranges.find(r => r.propertyId === 61)?.range);
            setReturnOnSalesIndicatorRanges(props.ranges.find(r => r.propertyId === 63)?.range);
            setReturnOnAssetsIndicatorRanges(props.ranges.find(r => r.propertyId === 64)?.range);
            setReturnOnEquityIndicatorRanges(props.ranges.find(r => r.propertyId === 65)?.range);
            setGeneralDebtIndicatorRanges(props.ranges.find(r => r.propertyId === 66)?.range);
            setLongTermDebtIndicatorRanges(props.ranges.find(r => r.propertyId === 67)?.range);
            setInterestPaymentIndicatorRanges(props.ranges.find(r => r.propertyId === 68)?.range);
        }
    }, [props.ranges]);

    useEffect(() => {
        if (altmanEmScoreRanges) {
            altmanEmScoreFilter.range = altmanEmScoreRanges as FilterRange;
        
            if (!altmanEmScoreValue) {
                setAltmanEmScoreValue(altmanEmScoreRanges);
            }
        }

        if (emaczynskaValueRanges) {
            emaczynskaValueFilter.range = emaczynskaValueRanges as FilterRange;
            
            if (!emaczynskaValue) {
                setEmaczynskaValue(emaczynskaValueRanges);
            }
        }

        if (debtHandlingIndicatorRanges) {
            debtHandlingIndicatorFilter.range = debtHandlingIndicatorRanges as FilterRange;
            
            if (!debtHandlingIndicatorValue) {
                setDebtHandlingIndicatorValue(debtHandlingIndicatorRanges);
            }
        }

        if (returnOnSalesIndicatorRanges) {
            returnOnSalesIndicatorFilter.range = returnOnSalesIndicatorRanges as FilterRange;
            
            if (!returnOnSalesIndicatorValue) {
                setReturnOnSalesIndicatorValue(returnOnSalesIndicatorRanges); 
            }
        }

        if (returnOnAssetsIndicatorRanges) {
            returnOnAssetsIndicatorFilter.range = returnOnAssetsIndicatorRanges as FilterRange;
            
            if (!returnOnAssetsIndicatorValue) {
                setReturnOnAssetsIndicatorValue(returnOnAssetsIndicatorRanges);
            }
        }

        if (returnOnEquityIndicatorRanges) {
            returnOnEquityIndicatorFilter.range = returnOnEquityIndicatorRanges as FilterRange;
            
            if (!returnOnEquityIndicatorValue) {
                setReturnOnEquityIndicatorValue(returnOnEquityIndicatorRanges);
            }
        }

        if (generalDebtIndicatorRanges) {
            generalDebtIndicatorFilter.range = generalDebtIndicatorRanges as FilterRange;
            
            if (!generalDebtIndicatorValue) {
                setGeneralDebtIndicatorValue(generalDebtIndicatorRanges);
            }
        }

        if (longTermDebtIndicatorRanges) {
            longTermDebtIndicatorFilter.range = longTermDebtIndicatorRanges as FilterRange;
            
            if (!longTermDebtIndicatorValue) {
                setLongTermDebtIndicatorValue(longTermDebtIndicatorRanges);
            }
        }

        if (interestPaymentIndicatorRanges) {
            interestPaymentIndicatorFilter.range = interestPaymentIndicatorRanges as FilterRange;
            
            if (!interestPaymentIndicatorValue) {
                setInterestPaymentIndicatorValue(interestPaymentIndicatorRanges);
            }
        }
    }, [altmanEmScoreRanges, emaczynskaValueRanges, debtHandlingIndicatorRanges, returnOnSalesIndicatorRanges,
        returnOnAssetsIndicatorRanges, returnOnEquityIndicatorRanges, generalDebtIndicatorRanges,
        longTermDebtIndicatorRanges, interestPaymentIndicatorRanges]);

    if (altmanEmScoreFilter) {
        altmanEmScoreFilter.filterType = 'rangeFilter';
        altmanEmScoreFilter.useColumnName = false;
    }

    if (emaczynskaValueFilter) {
        emaczynskaValueFilter.filterType = 'rangeFilter';
        emaczynskaValueFilter.useColumnName = false;
    }

    if (debtHandlingIndicatorFilter) {
        debtHandlingIndicatorFilter.filterType = 'rangeFilter';
        debtHandlingIndicatorFilter.useColumnName = false;
    }

    if (returnOnSalesIndicatorFilter) {
        returnOnSalesIndicatorFilter.filterType = 'rangeFilter';
        returnOnSalesIndicatorFilter.useColumnName = false;
    }

    if (returnOnAssetsIndicatorFilter) {
        returnOnAssetsIndicatorFilter.filterType = 'rangeFilter';
        returnOnAssetsIndicatorFilter.useColumnName = false;
    }

    if (returnOnEquityIndicatorFilter) {
        returnOnEquityIndicatorFilter.filterType = 'rangeFilter';
        returnOnEquityIndicatorFilter.useColumnName = false;
    }

    if (generalDebtIndicatorFilter) {
        generalDebtIndicatorFilter.filterType = 'rangeFilter';
        generalDebtIndicatorFilter.useColumnName = false;
    }

    if (longTermDebtIndicatorFilter) {
        longTermDebtIndicatorFilter.filterType = 'rangeFilter';
        longTermDebtIndicatorFilter.useColumnName = false;
    }

    if (interestPaymentIndicatorFilter) {
        interestPaymentIndicatorFilter.filterType = 'rangeFilter';
        interestPaymentIndicatorFilter.useColumnName = false;
    }

    const altmanEmScoreChanged = (value: any, showAs?: string) => {
        setAltmanEmScoreValue(value);
        props.filterUpdated(altmanEmScoreFilter, value, showAs)
    }

    const altmanEmScoreInterpretationChanged = (value: any, showAs?: string) => {
        setAltmanEmScoreInterpretationValue(value);
        props.filterUpdated(altmanEmScoreInterpretationFilter, value, showAs)
    }

    const emaczynskaChanged = (value: any, showAs?: string) => {
        setEmaczynskaValue(value);
        props.filterUpdated(emaczynskaValueFilter, value, showAs)
    }

    const emaczynskaInterpretationChanged = (value: any, showAs?: string) => {
        setEmaczynskaInterpretationValue(value);
        props.filterUpdated(emaczynskaInterpretationFilter, value, showAs)
    }

    const debtHandlingIndicatorChanged = (value: any, showAs?: string) => {
        setDebtHandlingIndicatorValue(value);
        props.filterUpdated(debtHandlingIndicatorFilter, value, showAs)
    }

    const debtHandlingRatingChanged = (value: any, showAs?: string) => {
        setDebtHandlingRatingValue(value);
        props.filterUpdated(debtHandlingRatingFilter, value, showAs)
    }

    const returnOnSalesChanged = (value: any, showAs?: string) => {
        setReturnOnSalesIndicatorValue(value);
        props.filterUpdated(returnOnSalesIndicatorFilter, value, showAs)
    }

    const returnOnAssetsChanged = (value: any, showAs?: string) => {
        setReturnOnAssetsIndicatorValue(value);
        props.filterUpdated(returnOnAssetsIndicatorFilter, value, showAs)
    }

    const returnOnEquityChanged = (value: any, showAs?: string) => {
        setReturnOnEquityIndicatorValue(value);
        props.filterUpdated(returnOnEquityIndicatorFilter, value, showAs)
    }

    const generalDebtIndicatorChanged = (value: any, showAs?: string) => {
        setGeneralDebtIndicatorValue(value);
        props.filterUpdated(generalDebtIndicatorFilter, value, showAs)
    }

    const longTermDebtIndicatorChanged = (value: any, showAs?: string) => {
        setLongTermDebtIndicatorValue(value);
        props.filterUpdated(longTermDebtIndicatorFilter, value, showAs)
    }

    const interestPaymentIndicatorChanged = (value: any, showAs?: string) => {
        setInterestPaymentIndicatorValue(value);
        props.filterUpdated(interestPaymentIndicatorFilter, value, showAs)
    }

    const mainRatingChanged = (value: any, showAs?: string) => {
        setMainRatingValue(value);
        props.filterUpdated(mainRatingFilter, value, showAs)
    }

    const updateFilterTypeToFilterGroup = (filterType: FilterType, filters: ColumnFilter[]) => {
        filters.forEach(filter => {
            filter.filterType = filterType;
        });
    }

    updateFilterTypeToFilterGroup('multiselectComboFilter', 
        [altmanEmScoreInterpretationFilter, emaczynskaInterpretationFilter, debtHandlingRatingFilter, mainRatingFilter]);

    return <div className='filter-indicators'>
        <>
            {altmanEmScoreValue && altmanEmScoreRanges && <NumberRangeControl
                label={altmanEmScoreFilter.showAs}
                unit=""
                className="filter range"
                onChange={altmanEmScoreChanged}
                range={altmanEmScoreValue}
                startMin={parseFloat(altmanEmScoreRanges.min)}
                startMax={parseFloat(altmanEmScoreRanges.max)}
                updatesDisabledOnInit={true} /> }

            <MultiSelectValueListControl
                value={altmanEmScoreInterpretationValue}
                showAs={altmanEmScoreInterpretationFilter.valueShowAs}
                label={altmanEmScoreInterpretationFilter.showAs}
                className="filter"
                configuration={{ values: altmanEmScoreInterpretationFilter.values }}
                onChange={altmanEmScoreInterpretationChanged} />

            {emaczynskaValue && emaczynskaValueRanges && <NumberRangeControl
                label={emaczynskaValueFilter.showAs}
                unit=""
                className="filter range"
                onChange={emaczynskaChanged}
                range={emaczynskaValue}
                startMin={parseFloat(emaczynskaValueRanges.min)}
                startMax={parseFloat(emaczynskaValueRanges.max)}
                updatesDisabledOnInit={true} /> }

            <MultiSelectValueListControl
                value={emaczynskaInterpretationValue}
                showAs={emaczynskaInterpretationFilter.valueShowAs}
                label={emaczynskaInterpretationFilter.showAs}
                className="filter"
                configuration={{ values: emaczynskaInterpretationFilter.values }}
                onChange={emaczynskaInterpretationChanged} />

            {debtHandlingIndicatorValue && debtHandlingIndicatorRanges && <NumberRangeControl
                label={debtHandlingIndicatorFilter.showAs}
                unit=""
                className="filter range"
                onChange={debtHandlingIndicatorChanged}
                range={debtHandlingIndicatorValue}
                startMin={parseFloat(debtHandlingIndicatorRanges.min)}
                startMax={parseFloat(debtHandlingIndicatorRanges.max)}
                updatesDisabledOnInit={true} />}

            <MultiSelectValueListControl
                value={debtHandlingRatingValue}
                showAs={debtHandlingRatingFilter.valueShowAs}
                label={debtHandlingRatingFilter.showAs}
                className="filter"
                configuration={{ values: debtHandlingRatingFilter.values }}
                onChange={debtHandlingRatingChanged} />

            {returnOnSalesIndicatorValue && returnOnSalesIndicatorRanges && <NumberRangeControl
                label={returnOnSalesIndicatorFilter.showAs}
                unit="%"
                className="filter range"
                onChange={returnOnSalesChanged}
                range={returnOnSalesIndicatorValue}
                startMin={parseFloat(returnOnSalesIndicatorRanges.min)}
                startMax={parseFloat(returnOnSalesIndicatorRanges.max)}
                updatesDisabledOnInit={true} />}

            {returnOnAssetsIndicatorValue && returnOnAssetsIndicatorRanges && <NumberRangeControl
                label={returnOnAssetsIndicatorFilter.showAs}
                unit="%"
                className="filter range"
                onChange={returnOnAssetsChanged}
                range={returnOnAssetsIndicatorValue}
                startMin={parseFloat(returnOnAssetsIndicatorRanges.min)}
                startMax={parseFloat(returnOnAssetsIndicatorRanges.max)}
                updatesDisabledOnInit={true} />}

            {returnOnEquityIndicatorValue && returnOnEquityIndicatorRanges && <NumberRangeControl
                label={returnOnEquityIndicatorFilter.showAs}
                unit="%"
                className="filter range"
                onChange={returnOnEquityChanged}
                range={returnOnEquityIndicatorValue}
                startMin={parseFloat(returnOnEquityIndicatorRanges.min)}
                startMax={parseFloat(returnOnEquityIndicatorRanges.max)}
                updatesDisabledOnInit={true} />}

            {generalDebtIndicatorValue && generalDebtIndicatorRanges && <NumberRangeControl
                label={generalDebtIndicatorFilter.showAs}
                unit="%"
                className="filter range"
                onChange={generalDebtIndicatorChanged}
                range={generalDebtIndicatorValue}
                startMin={parseFloat(generalDebtIndicatorRanges.min)}
                startMax={parseFloat(generalDebtIndicatorRanges.max)}
                updatesDisabledOnInit={true} />}

            {longTermDebtIndicatorValue && longTermDebtIndicatorRanges && <NumberRangeControl
                label={longTermDebtIndicatorFilter.showAs}
                unit=""
                className="filter range"
                onChange={longTermDebtIndicatorChanged}
                range={longTermDebtIndicatorValue}
                startMin={parseFloat(longTermDebtIndicatorRanges.min)}
                startMax={parseFloat(longTermDebtIndicatorRanges.max)}
                updatesDisabledOnInit={true} />}

            {interestPaymentIndicatorValue && interestPaymentIndicatorRanges && <NumberRangeControl
                label={interestPaymentIndicatorFilter.showAs}
                unit=""
                className="filter range"
                onChange={interestPaymentIndicatorChanged}
                range={interestPaymentIndicatorValue}
                startMin={parseFloat(interestPaymentIndicatorRanges.min)}
                startMax={parseFloat(interestPaymentIndicatorRanges.max)}
                updatesDisabledOnInit={true} />}

            <MultiSelectValueListControl
                value={mainRatingValue}
                showAs={mainRatingFilter.valueShowAs}
                label={mainRatingFilter.showAs}
                className="filter"
                configuration={{ values: mainRatingFilter.values }}
                onChange={mainRatingChanged} />
        </>
    </div>
}
