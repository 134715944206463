import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BitColumnFilter, ColumnFilter, FilterManager, setFilterValue } from '../../../model/list/ColumnFilter';
import { api } from '../../../utils/api/ApiProvider';
import { MinMaxPropertyRange } from '../../model/MinMaxResults';
import InterestDetailsFilters from './filters/InterestDetailsFilters';
import InterestFinancingFilters from './filters/InterestFinancingFilters';
import InterestRateFilters from './filters/InterestRateFilters';
import SubjectDetailsFilters from './filters/SubjectDetailsFilters';
import SubjectFinancialDetailsFilters from './filters/SubjectFinancialDetailsFilters';
import SubjectIndicatorsFilters from './filters/SubjectIndicatorsFilters';

import './CustomFilterBox.css';

type CustomFilterBoxProps = {
    filters: ColumnFilter[] | FilterManager;
    filtersAlreadyModified: boolean;
    savedFilters: ColumnFilter[] | null | undefined;
    applySavedFilters: boolean;
    resetFilters: boolean;
    setCurrentFilters: (filters: ColumnFilter[]) => void;
    setFiltersModified: (wereModified: boolean) => void;
    isForMarginRatesAnalysis: boolean;
}
export default function CustomFilterBox(props: CustomFilterBoxProps) {
    const [filters, _setFilters] = useState<ColumnFilter[]>([]);
    const [ranges, setRanges] = useState<MinMaxPropertyRange[]>([]);

    const mainFiltersButton = useRef<HTMLButtonElement>(null);
    const mainFiltersPanel = useRef<HTMLDivElement>(null);

    const interestFiltersButton = useRef<HTMLButtonElement>(null);
    const interestFiltersPanel = useRef<HTMLDivElement>(null);

    const subjectFiltersButton = useRef<HTMLButtonElement>(null);
    const subjectFiltersPanel = useRef<HTMLDivElement>(null);

    const setFilters = (fs: ColumnFilter[]) => {
        _setFilters(fs);
    }

    useEffect(() => {
        if (!ranges || ranges.length == 0) {
            const propertyIds = filters.map(f => {
                if (f.filterType === 'decimalFilter' || f.filterType === 'integerFilter' || f.filterType === 'rangeFilter') {
                    const id = +(/^"ins_p_(\d+)"$/.exec(f.valueColumn)?.[1] || +(/^p_(\d+)$/.exec(f.valueColumn)?.[1] || 0));
    
                    if (id && id > 0)
                        return id;
                }
            }).filter(f => f !== undefined);
    
            if (propertyIds && propertyIds.length > 0) {
                api().Cngr.getMinMaxPropertyValueRange({ propertyIds: propertyIds as number[] })
                    .then(r => { 
                        setRanges(r.results.ranges)});
            }
        }
    }, [filters]);

    useEffect(() => {
        if (props.filters instanceof Array) {
            setFilters([...props.filters]);
            return () => { };
        } else {
            const filterManager = props.filters;
            return filterManager.addFilterHandler(setFilters);
        }
    }, [props.filters]);

    useLayoutEffect(() => {
        if (filters && mainFiltersButton.current?.classList.contains('show-filters'))
            hideFilters(mainFiltersButton, mainFiltersPanel);
    }, [filters]);

    const updateFilters = (updatingFilter: ColumnFilter, v: any, showAs?: string) => {
        let newFilters = filters?.map(fc => fc.columnAlias !== updatingFilter.columnAlias || fc.showAs !== updatingFilter.showAs ? fc : setFilterValue({ ...fc }, v, showAs));

        if (newFilters) {
            newFilters = newFilters.map(f => {
                if (f.filterType === 'bitFilter') {
                    if (!v) {
                        const filter = {
                            columnAlias: f.columnAlias,
                            filterType: f.filterType,
                            format: f.format,
                            showAs: f.showAs,
                            valueColumn: f.valueColumn,
                            valueShowAs: f.valueShowAs
                        } as BitColumnFilter;

                        return filter;
                    }
                }

                return f;
            });

            props.setCurrentFilters([...newFilters]);

            if (!(props.filters instanceof Array)) {
                props.filters.update([...newFilters]);
            }
        }
    }

    const filterUpdated = (filter: ColumnFilter, value: any, showAs?: string) => {
        if (props.filtersAlreadyModified) {
            updateFilters(filter, value, showAs);
        } else {
            const newFilters = filters?.map(fc => fc.columnAlias !== filter.columnAlias || fc.showAs !== filter.showAs ? fc : setFilterValue({ ...fc }, value, showAs));
            if (newFilters) {
                props.setCurrentFilters([...newFilters]);
            }

            if (!(props.filters instanceof Array)) {
                props.filters.update([...newFilters]);
            }

            props.setFiltersModified(true);
        }
    }

    const click = () => mainFiltersButton.current?.classList.contains('show-filters')
        ? hideFilters(mainFiltersButton, mainFiltersPanel)
        : showFilters(mainFiltersButton, mainFiltersPanel);

    const interestSectionClick = () => interestFiltersButton.current?.classList.contains('show-filters')
        ? hideFilters(interestFiltersButton, interestFiltersPanel)
        : showFilters(interestFiltersButton, interestFiltersPanel);

    const subjectSectionClick = () => subjectFiltersButton.current?.classList.contains('show-filters')
        ? hideFilters(subjectFiltersButton, subjectFiltersPanel)
        : showFilters(subjectFiltersButton, subjectFiltersPanel);

    const showFilters = (button: React.RefObject<HTMLButtonElement>, panel: React.RefObject<HTMLDivElement>) => {
        button.current?.classList.add('show-filters');
        panel.current?.classList.add('visible');
    }

    const hideFilters = (button: React.RefObject<HTMLButtonElement>, panel: React.RefObject<HTMLDivElement>) => {
        button.current?.classList.remove('show-filters');
        panel.current?.classList.remove('visible');
    }

    return <div className='filter-box'>
        {filters && filters.length > 0 && <>
            <div ref={mainFiltersPanel} className='filter-panel visible'>
                <div className='group-header'>
                    <button type='button'
                        ref={interestFiltersButton}
                        className='filter-button'
                        onClick={interestSectionClick}
                    >Filtry dotyczące instrumentu:</button>
                </div>
                <div ref={interestFiltersPanel} className='filter-group'>
                    <InterestRateFilters
                        filters={filters}
                        ranges={ranges}
                        applySavedFilters={props.applySavedFilters}
                        resetFilters={props.resetFilters}
                        savedFilters={props.savedFilters}
                        filterUpdated={(f: ColumnFilter, v: any, s: string | undefined) => filterUpdated(f, v, s)}
                        isForMarginRatesAnalysis={props.isForMarginRatesAnalysis} />
                    <InterestFinancingFilters
                        filters={filters}
                        ranges={ranges}
                        applySavedFilters={props.applySavedFilters}
                        resetFilters={props.resetFilters}
                        savedFilters={props.savedFilters}
                        filterUpdated={(f: ColumnFilter, v: any, s: string | undefined) => filterUpdated(f, v, s)} />
                    <InterestDetailsFilters
                        filters={filters}
                        ranges={ranges}
                        applySavedFilters={props.applySavedFilters}
                        resetFilters={props.resetFilters}
                        savedFilters={props.savedFilters}
                        filterUpdated={(f: ColumnFilter, v: any, s: string | undefined) => filterUpdated(f, v, s)} />
                </div>
                <div className='group-header'>
                    <button type='button'
                        ref={subjectFiltersButton}
                        className='filter-button'
                        onClick={subjectSectionClick}
                    >Filtry dotyczące podmiotu:</button>
                </div>
                <div ref={subjectFiltersPanel} className='filter-group'>
                    <SubjectDetailsFilters
                        filters={filters}
                        ranges={ranges}
                        applySavedFilters={props.applySavedFilters}
                        resetFilters={props.resetFilters}
                        savedFilters={props.savedFilters}
                        filterUpdated={(f: ColumnFilter, v: any, s: string | undefined) => filterUpdated(f, v, s)} />
                    <SubjectFinancialDetailsFilters
                        filters={filters}
                        ranges={ranges}
                        applySavedFilters={props.applySavedFilters}
                        resetFilters={props.resetFilters}
                        savedFilters={props.savedFilters}
                        filterUpdated={(f: ColumnFilter, v: any, s: string | undefined) => filterUpdated(f, v, s)} />
                    <SubjectIndicatorsFilters
                        filters={filters}
                        ranges={ranges}
                        applySavedFilters={props.applySavedFilters}
                        resetFilters={props.resetFilters}
                        savedFilters={props.savedFilters}
                        filterUpdated={(f: ColumnFilter, v: any, s: string | undefined) => filterUpdated(f, v, s)} />
                </div>
            </div>
        </>}
    </div>;
}
