import { toApi } from "../../model/list/ColumnFilter";
import { ChangeList } from "../../model/list/HelperTypes";
import { GetListExParams, GetListParams, GetPropertiesParams, TListApi } from "./ApiTypes";
import { toFilterListResult, toListDataResult, toListProperties, toSerializableObjectResult } from "./Results";
import * as Api from "./TactinApi";

const MODULE = "ListModule";

function parseAggregations(value?: Map<string, string>) {
    const result: { columnName: string, functionName: string }[] = [];
    if (value)
        for (const [columnName, functionName] of value.entries())
            result.push({ columnName, functionName });
    return result;
}

export const ListApi = (token: string): TListApi => ({
    getFiltersForDataProvider: (dataProviderId: string, forceAll: boolean) =>
        Api.sendRequest(MODULE, "getFiltersForDataProvider", { dataProviderId, forceAll }, token)
            .then(r => toFilterListResult(r)),
    getFiltersForColumn: (columnId: number, force: boolean) =>
        Api.sendRequest(MODULE, "getFiltersForColumn", { columnId, force }, token)
            .then(r => toFilterListResult(r)),
    getList: (params: GetListParams) =>
        Api.sendRequest(MODULE, "getList", {
            ...params,
            filters: toApi(params.filters)
        }, token)
            .then(r => toListDataResult(r)),
    getListEx: (params: GetListExParams) =>
        Api.sendRequest(MODULE, "getListEx", {
            ...params,
            filters: toApi(params.filters),
            aggregations: parseAggregations(params.aggregations)
        }, token)
            .then(r => toListDataResult(r)),
    getProperties: (params: GetPropertiesParams) =>
        Api.sendRequest(MODULE, "getProperties", {
            ...params,
            filters: toApi(params.filters),
            aggregations: parseAggregations(params.aggregations)
        }, token)
            .then(r => toListProperties(r)),
    getChangedFromDate: (dataProviderId: string, lastChange: number) =>
        Api.sendRequest(MODULE, "getChangedFromDate", { dataProviderId, lastChange }, token)
            .then(r => toSerializableObjectResult<ChangeList>(r, 'ServerModifiedItemsList'))
});
