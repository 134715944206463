import React, { useEffect, useState } from 'react';
import { ItemToManyItemControl } from '../../../../components/propertycontrols/ItemToManyItemControl';
import { ValueListControl } from '../../../../components/propertycontrols/ValueListControl';
import { ColumnFilter, ComboColumnFilter, FilterType, getFilterValue, MultiselectComboColumnFilter, OneToManyRelationColumnFilter } from '../../../../model/list/ColumnFilter';
import { MultiSelectValueListControl } from '../controls/MultiselectValueListControl';
import { CustomFilterPropertyControlProps } from './CustomFilterPropertyControlProps';

export default function SubjectDetailsFilters(props: CustomFilterPropertyControlProps) {
    const headCountryFilter = props.filters.find(f => f.valueColumn === 'pv_46') as MultiselectComboColumnFilter;
    const belongsToCapitalGroupFilter = props.filters.find(f => f.valueColumn === 'pv_118') as ComboColumnFilter;
    const functionalProfileFilter = props.filters.find(f => f.valueColumn === 'pv_50') as MultiselectComboColumnFilter;
    const groupRatingFilter = props.filters.find(f => f.valueColumn === 'pv_51') as MultiselectComboColumnFilter;
    const mainPkdFilter = props.filters.find(f => f.valueColumn === 'pv_47') as OneToManyRelationColumnFilter;
    const secondaryPkdFilter = props.filters.find(f => f.valueColumn === 'pv_48') as OneToManyRelationColumnFilter;
    const sectorFilter = props.filters.find(f => f.valueColumn === 'pv_49') as MultiselectComboColumnFilter;
    const functionalCurrencyFilter = props.filters.find(f => f.valueColumn === 'pv_52') as OneToManyRelationColumnFilter;

    const [selectedBelongsToCapitalGroup, setSelectedBelongsToCapitalGroup] = useState<number | null>(null)

    const [headCountryValue, setHeadCountryValue] = useState<number[]>([]);
    const [belongsToCapitalGroupValue, setBelongsToCapitalGroupValue] = useState<ComboColumnFilter>(getFilterValue(belongsToCapitalGroupFilter));
    const [functionalProfileValue, setFunctionalProfileValue] = useState<number[]>([]);
    const [groupRatingValue, setGoupRatingValue] = useState<number[]>([]);
    const [mainPkdValue, setMainPkdValue] = useState<OneToManyRelationColumnFilter>(getFilterValue(mainPkdFilter));
    const [secondaryPkdValue, setSecondaryPkdValue] = useState<OneToManyRelationColumnFilter>(getFilterValue(secondaryPkdFilter));
    const [sectorValue, setSectorValue] = useState<number[]>([]);
    const [functionalCurrencyValue, setFunctionalCurrencyValue] = useState<OneToManyRelationColumnFilter>(getFilterValue(functionalCurrencyFilter));

    const applyInitialFilters = (filters: ColumnFilter[]) => {
        if (filters) {
            headCountryFilter.selected = (filters.find(f => f.valueColumn === 'pv_46') as MultiselectComboColumnFilter).selected;
            setHeadCountryValue(getFilterValue(headCountryFilter));

            const belongsToCapitalGroup = (filters.find(f => f.valueColumn === 'pv_118') as ComboColumnFilter).selected;

            if (belongsToCapitalGroup !== null && belongsToCapitalGroup !== undefined) {
                belongsToCapitalGroupFilter.selected = belongsToCapitalGroup ? belongsToCapitalGroup : 1;
                setBelongsToCapitalGroupValue(getFilterValue(belongsToCapitalGroupFilter));
            }

            functionalProfileFilter.selected = (filters.find(f => f.valueColumn === 'pv_50') as MultiselectComboColumnFilter).selected;
            setFunctionalProfileValue(getFilterValue(functionalProfileFilter));

            groupRatingFilter.selected = (filters.find(f => f.valueColumn === 'pv_51') as MultiselectComboColumnFilter).selected;
            setGoupRatingValue(getFilterValue(groupRatingFilter));

            mainPkdFilter.selected = (filters.find(f => f.valueColumn === 'pv_47') as OneToManyRelationColumnFilter).selected;
            setMainPkdValue(getFilterValue(mainPkdFilter));

            secondaryPkdFilter.selected = (filters.find(f => f.valueColumn === 'pv_48') as OneToManyRelationColumnFilter).selected;
            setSecondaryPkdValue(getFilterValue(secondaryPkdFilter));

            sectorFilter.selected = (filters.find(f => f.valueColumn === 'pv_49') as MultiselectComboColumnFilter).selected;
            setSectorValue(getFilterValue(sectorFilter));

            functionalCurrencyFilter.selected = (filters.find(f => f.valueColumn === 'pv_52') as OneToManyRelationColumnFilter).selected;
            setFunctionalCurrencyValue(getFilterValue(functionalCurrencyFilter));

            setSelectedBelongsToCapitalGroup(belongsToCapitalGroup);
        }
    };

    useEffect(() => {
        if (props.savedFilters && props.applySavedFilters) {
            applyInitialFilters(props.savedFilters);
        } else if (props.filters && props.resetFilters) {
            applyInitialFilters(props.filters);
        }
    }, [props.filters]);

    const belongsToCapitalGroupSelected = (value: any, showAs?: string) => {
        setSelectedBelongsToCapitalGroup(value);
        setBelongsToCapitalGroupValue(value);

        if (belongsToCapitalGroupFilter && functionalProfileFilter && groupRatingFilter
            && belongsToCapitalGroupFilter.selected != 1) {
            functionalProfileFilter.selected = null;
            groupRatingFilter.selected = null;
        }

        props.filterUpdated(belongsToCapitalGroupFilter, value, showAs);
    }

    const headCountrySelected = (value: any, showAs?: string) => {
        setHeadCountryValue(value);
        props.filterUpdated(headCountryFilter, value, showAs)
    }

    const functionalProfileSelected = (value: any, showAs?: string) => {
        setFunctionalProfileValue(value);
        props.filterUpdated(functionalProfileFilter, value, showAs)
    }

    const groupRatingSelected = (value: any, showAs?: string) => {
        setGoupRatingValue(value);
        props.filterUpdated(groupRatingFilter, value, showAs)
    }

    const mainPkdSelected = (value: any, showAs?: string) => {
        setMainPkdValue(value);
        props.filterUpdated(mainPkdFilter, value, showAs)
    }

    const secondaryPkdSelected = (value: any, showAs?: string) => {
        setSecondaryPkdValue(value);
        props.filterUpdated(secondaryPkdFilter, value, showAs)
    }

    const sectorSelected = (value: any, showAs?: string) => {
        setSectorValue(value);
        props.filterUpdated(sectorFilter, value, showAs)
    }

    const functionalCurrencySelected = (value: any, showAs?: string) => {
        setFunctionalCurrencyValue(value);
        props.filterUpdated(functionalCurrencyFilter, value, showAs)
    }

    const updateFilterTypeToFilterGroup = (filterType: FilterType, filters: ColumnFilter[]) => {
        filters.forEach(filter => {
            filter.filterType = filterType;
        });
    }

    updateFilterTypeToFilterGroup('multiselectComboFilter', 
        [headCountryFilter, functionalProfileFilter, groupRatingFilter, sectorFilter]);

    return <div className='filter-subject-details'>
        <>
            <MultiSelectValueListControl
                value={headCountryValue}
                showAs={headCountryFilter.valueShowAs}
                label={headCountryFilter.showAs}
                className="filter"
                configuration={{ values: headCountryFilter.values }}
                onChange={headCountrySelected} />

            <ItemToManyItemControl
                value={mainPkdValue}
                showAs={mainPkdFilter.valueShowAs}
                label={mainPkdFilter.showAs}
                className="filter"
                configuration={mainPkdFilter.config}
                onChange={mainPkdSelected} />

            <ItemToManyItemControl
                value={secondaryPkdValue}
                showAs={secondaryPkdFilter.valueShowAs}
                label={secondaryPkdFilter.showAs}
                className="filter"
                configuration={secondaryPkdFilter.config}
                onChange={secondaryPkdSelected} />

            <MultiSelectValueListControl
                value={sectorValue}
                showAs={sectorFilter.valueShowAs}
                label={sectorFilter.showAs}
                className="filter"
                configuration={{ values: sectorFilter.values }}
                onChange={sectorSelected} />
        </>
        {belongsToCapitalGroupFilter && <>
            <ValueListControl
                value={belongsToCapitalGroupValue}
                showAs={belongsToCapitalGroupFilter.valueShowAs}
                label={belongsToCapitalGroupFilter.showAs}
                className='filter'
                configuration={{ values: belongsToCapitalGroupFilter.values }}
                onChange={belongsToCapitalGroupSelected} />

            {selectedBelongsToCapitalGroup &&
                <>
                    <MultiSelectValueListControl
                        value={functionalProfileValue}
                        showAs={functionalProfileFilter.valueShowAs}
                        label={functionalProfileFilter.showAs}
                        className="filter"
                        configuration={{ values: functionalProfileFilter.values }}
                        onChange={functionalProfileSelected} />

                    <MultiSelectValueListControl
                        value={groupRatingValue}
                        showAs={groupRatingFilter.valueShowAs}
                        label={groupRatingFilter.showAs}
                        className="filter"
                        configuration={{ values: groupRatingFilter.values }}
                        onChange={groupRatingSelected} />
                </>
            }
        </>
        }

        <ItemToManyItemControl
            value={functionalCurrencyValue}
            showAs={functionalCurrencyFilter.valueShowAs}
            label={functionalCurrencyFilter.showAs}
            className="filter"
            configuration={functionalCurrencyFilter.config}
            onChange={functionalCurrencySelected} />
    </div>
}
